import React, { useState, useEffect } from "react";
import { getDownstreamFlow } from "@/api/rain";

function WaterSupply(props) {
  const [liuliang, setLiuliang] = useState({}); // 流量
  useEffect(() => {
    getDtuFun();
    setInterval(() => {
      getDtuFun();
    }, 300000); //   5分钟接口单独请求一次
  }, []);
  const getDtuFun = () => {
    getDownstreamFlow().then((res) => {
      // 获取流量
      if (res.data.code == 0) {
        const data = res.data.results;
        if (data && data.status != 500) {
          try {
            for (let v in data) {
              if (
                v != "dateHour" &&
                typeof data[v] != "object" &&
                typeof data[v] == "number"
              ) {
                if (
                  v == "day" ||
                  v == "yestoday" ||
                  v == "year" ||
                  v == "month"
                ) {
                  data[v] = data[v];
                } else {
                  data[v] = data[v] * 3600
                }
                data[v] = data[v] != 0 ? String(data[v].toFixed(4)).split(".") : 0;
              }
            }
            setLiuliang(data);
          } catch (err) {
            console.log(err);
          }
        }
      }
    });
  };

  return (
    <div className="water-supply-wrap flex  flex-j-b flex-a-c">
      <div className="tit-water-wrap">下游流量</div>
      <div className="water-supply-main-wrap  flex flex-j-b">
        <dl>
          <dt>瞬时流量</dt>
          <dd>
            {liuliang.ssll &&
              typeof liuliang.ssll == "object" ? (
              <span>
                <i>{liuliang.ssll[0]}</i>
                .{liuliang.ssll[1]}
              </span>
            ) : (
              <span>
                <i>0</i>
              </span>
            )}
            <span className="unit">m³/h</span>
          </dd>
        </dl>
        <dl>
          <dt>今日水量</dt>
          <dd>
            {liuliang.day &&
              typeof liuliang.day == "object" ? (
              <span>
                <i>{liuliang.day[0]}</i>.{liuliang.day[1]}
              </span>
            ) : (
              <span>
                <i>0</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
        <dl>
          <dt>昨日水量</dt>
          <dd>
            {liuliang.yestoday &&
              typeof liuliang.yestoday == "object" ? (
              <span>
                <i>{liuliang.yestoday[0]}</i>.
                {liuliang.yestoday[1]}
              </span>
            ) : (
              <span>
                <i>0</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
        <dl>
          <dt>当月累计水量</dt>
          <dd>
            {liuliang.month &&
              typeof liuliang.month == "object" ? (
              <span>
                <i>{liuliang.month[0]}</i>.
                {liuliang.month[1]}
              </span>
            ) : (
              <span>
                <i>0</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
        <dl>
          <dt>全年累计水量</dt>
          <dd>
            {liuliang.year &&
              typeof liuliang.year == "object" ? (
              <span>
                <i>{liuliang.year[0]}</i>.
                {liuliang.year[1]}
              </span>
            ) : (
              <span>
                <i>0</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
      </div>
    </div>
  );
}

export default WaterSupply;
