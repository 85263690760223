import Tit from "@/components/tit";
import React, { useState, useEffect } from "react";
import "./index.less";
import { getSpillway } from "@/api/rainQc";
import { keepTwoDecimalFull } from "@/utils/common"
const _ = require("lodash");
function Power(props) {
    const [deviceDetails, setDeviceDetails] = useState([]); // 发电机组
    const [amout, setAmount] = useState([]); // 总量
    const getSpillwayFun = () => {
        getSpillway().then((res) => {
            if (res.data.code == 0) {
                const results = res.data.results
                setDeviceDetails(results)
                setAmount(_.sumBy(results, 'flow'))
            }
        });
    };
    useEffect(() => {
        getSpillwayFun();
        setInterval(() => {
            getSpillwayFun();
        }, 300000); //   5分钟接口单独请求一次
    }, []);
    return (
        <div className="qc-yh-wrap">
            <Tit tit="溢洪道流量">
                <div className="tit">
                    {deviceDetails.deviceTotalFlow ? (
                        <span>
                            <i>{keepTwoDecimalFull(amout * 3600, 2)}</i>
                        </span>
                    ) : (
                        <span>
                            <i>0</i>
                        </span>
                    )}
                    <span className="unit m-l-5">m³/h</span>
                </div>
            </Tit>
            <div className="power-main-wrap">
                <ul className="after fenche-old">
                    {deviceDetails.map((item, index) => {
                        return (
                            <li className="fenche-li fenche-li-old" key={index}>
                                <div className="fenche-li-hd fz-12 theme-bg-color">
                                    {keepTwoDecimalFull(item.flow, 1)}
                                    <span className="m-l-3">m³/s</span>
                                </div>
                                <div
                                    className={`fenche-li-bd ${item.realOpening ? "active" : ""}`}
                                >
                                    <div className={`op-6 ${item.realOpening ? "active" : ""}`}>
                                        <span className="fenche-ani"></span>
                                        <span className="fenche-desc fz-14">
                                            {item.gateNo}#弧门
                                        </span>
                                    </div>
                                    {item.realOpening ? "" : <span className="top-icon"></span>}
                                </div>
                                <div className="fenche-li-ft fz-12 theme-bg-color">
                                    {keepTwoDecimalFull(item.realOpening, 2)}
                                    <span className="m-l-3">m</span>
                                </div>
                            </li>
                        );
                    })}
                </ul>

            </div>
        </div>
    );
}

export default Power;
