import React, { useState, useEffect } from "react";
import { getPowerGenerator, getEcoFlow, getGeneratedEnergy, getSpillway, getSpillwayTunnel } from "@/api/rainTx";
import { keepTwoDecimalFull } from "@/utils/common";
import { gateTunne, nowFlow } from "@/store/raininfo";
import { useSetRecoilState } from "recoil";
import Tit from "@/components/tit";
import "./index.less";
const _ = require("lodash");
function Power(props) {
    const setGateTunneCopy = useSetRecoilState(gateTunne); // 溢洪道 + 放空洞 
    const setNowFlowCopy = useSetRecoilState(nowFlow); // 下泄总量
    const [generatorDetails, setGeneratorDetails] = useState([{ generatorNo: 1 }, { generatorNo: 2 }]); // 发电机组
    const [ecoLDetails, setEcoLDetails] = useState([]); //  生态阀
    const [amount, setAmount] = useState(0); // 总流量
    const [powerGenerationDaily, setPowerGenerationDaily] = useState([]);
    /**
     * 获取发电机信息
     */
    const getPowerGeneratorFun = async () => {
        const res1 = await getPowerGenerator() // 获取流量
        const res2 = await getEcoFlow()  //  获取生态阀
        const res3 = await getSpillway()
        const res4 = await getSpillwayTunnel()
        const res5 = await getGeneratedEnergy() // 发电机统计
        let amountCopy = 0
        if (res1.data.results && res1.data.results.length) {
            res1.data.results.map((item) => {
                amountCopy = amountCopy + item.flow
            })
            setGeneratorDetails(res1.data.results);
        }
        if (res2.data.results) {
            amountCopy += res2.data.results.flowSecond
            setEcoLDetails([res2.data.results])
            setAmount(amountCopy)
        }
        if (res3.data.results) {
            let _amountCopy = 0
            res3.data.results.map(item => {
                _amountCopy = _amountCopy + item.flow
            })
            if (res4.data.results) {
                _amountCopy = _amountCopy + res4.data.results.flow
                setGateTunneCopy({
                    gateList: res3.data.results, // 溢洪道
                    tunnelList: [res4.data.results],// 泄洪洞放空洞信息
                    amount: _amountCopy
                })
                setNowFlowCopy(`${keepTwoDecimalFull(_amountCopy + amountCopy, 1)}`.split('.'))
            }
        }
        if (res5.data.results) {
            setPowerGenerationDaily(res5.data.results)
        }
        setTimeout(() => {
            getPowerGeneratorFun();
        }, 300000); //   5分钟接口单独请求一次
    };
    useEffect(() => {
        getPowerGeneratorFun();
    }, []);
    return (
        <div className="power-wrap-bzj">
            <Tit tit="发电机组">
                <div className="tit">
                    <span>
                        <i>{keepTwoDecimalFull(amount || 0, 1)}</i>
                    </span>
                    <span className="unit m-l-5">m³/s</span>
                </div>
            </Tit>
            <div className="power-main-wrap m-t-45 m-b-45">
                <ul className="fenche after">
                    {generatorDetails.map((item, index) => {
                        return (
                            <li className="fenche-li" key={index}>
                                <div className="fenche-li-hd fz-12 theme-bg-color">
                                    {keepTwoDecimalFull(item.generatedOutput || 0, 0)}
                                    <span className="m-l-3">Kw</span>
                                </div>
                                <div
                                    className={`fenche-li-bd ${item.flow ? "active" : ""}`}
                                >
                                    <div className={`op-6 ${item.flow ? "active" : ""}`}>
                                        <span className="fenche-ani"></span>
                                        <span className="fenche-desc fz-14">
                                            {item.generatorNo}号机组
                                        </span>
                                    </div>
                                    {item.flow ? "" : <span className="top-icon"></span>}
                                </div>
                                <div className="fenche-li-ft fz-12 theme-bg-color">
                                    {keepTwoDecimalFull(item.flow || 0, 1)}
                                    <span className="m-l-3">m³/s</span>
                                </div>
                            </li>
                        );
                    })}
                    {/* {ecoLDetails.map((item, index) => {
            return (
              <li className="fenche-li" key={index}>
                <div className="fenche-li-hd fz-12 theme-bg-color" style={{ opacity: 0 }}>
                  {keepTwoDecimalFull(item.generatedOutput || 0, 0)}
                  <span className="m-l-3">Kw</span>
                </div>
                <div className="fenche-li-bd">
                  {!item.flowSecond ? (
                    <>
                      <div
                        className={`op-7 op-7-${item.type}`}
                        style={{ opacity: item.type == 1 ? 0.6 : 1 }}
                      >
                        <span className="fenche-desc fz-14">生态阀</span>
                      </div>
                      <span className="top-icon"></span>
                    </>
                  ) : (
                    <div className="h100 fenche-li-img">
                      <div className="fenche-li-img-gif"></div>
                      <div className="fenche-li-desc">生态阀</div>
                    </div>
                  )}
                </div>
                <div className="fenche-li-ft fz-12 theme-bg-color">
                  {keepTwoDecimalFull(item.flowSecond || 0, 1)}
                  <span className="m-l-3">m³/s</span>
                </div>
              </li>
            );
          })} */}
                </ul>
                {/* <div className="fenche-info-wrap flex flex-j-b m-t-5">
                    <div className="fenche-info-main-wrap flex flex-j-b">
                        <dl>
                            <dt>当日发电量</dt>
                            <dd>
                                <span>
                                    <i>
                                        {keepTwoDecimalFull(powerGenerationDaily[`day`] / 10000 || 0, 2)}
                                    </i>
                                </span>
                                <span className="unit">万kW·h</span>
                            </dd>
                        </dl>
                        <dl>
                            <dt>当月发电量</dt>
                            <dd>
                                <span>
                                    <i>
                                        {keepTwoDecimalFull(powerGenerationDaily[`month`] / 10000 || 0, 2)}
                                    </i>
                                </span>
                                <span className="unit">万kW·h</span>
                            </dd>
                        </dl>
                        <dl>
                            <dt>全年累计发电量</dt>
                            <dd>
                                <span>
                                    <i>
                                        {keepTwoDecimalFull(powerGenerationDaily[`year`] / 10000 || 0, 2)}
                                    </i>
                                </span>
                                <span className="unit">万kW·h</span>
                            </dd>
                        </dl>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default Power;
