import React, { useState, useEffect, useRef } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { showImage, imageConfig } from "@/store/raininfo";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Spin, message } from "antd";
import "./index.less";
const maxWidth = window.screen.width;
const maxHeight = window.screen.height;
// console.log("maxWidth=>", maxWidth, maxHeight);
// alert(maxWidth);
// alert(maxHeight);
function AutoResizeImage(objImg, cb) {
  var img = new Image();
  img.src = objImg;
  img.onload = function (e) {
    var hRatio;
    var wRatio;
    var Ratio = 1;
    var w = img.width;
    var h = img.height;
    wRatio = maxWidth / w;
    hRatio = maxHeight / h;
    if (maxWidth == 0 && maxHeight == 0) {
      Ratio = 1;
    } else if (maxWidth == 0) {
      //
      if (hRatio < 1) Ratio = hRatio;
    } else if (maxHeight == 0) {
      if (wRatio < 1) Ratio = wRatio;
    } else if (wRatio < 1 || hRatio < 1) {
      Ratio = wRatio <= hRatio ? wRatio : hRatio;
    }
    if (Ratio < 1) {
      w = w * Ratio;
      h = h * Ratio;
    }
    // console.log("hRatio==>", wRatio, hRatio);
    // console.log(Ratio);
    if (wRatio > hRatio) {
      // 说明 高度先到达
      w = (w * maxHeight) / h;
      h = maxHeight;
    } else if (wRatio == hRatio) {
      w = maxWidth;
      h = maxHeight;
    } else {
      h = (h * maxWidth) / w;
      w = maxWidth;
    }
    if (window.devicePixelRatio > 1) {
      h = h * window.devicePixelRatio;
      w = w * window.devicePixelRatio;
    }
    cb(h, w);
  };
}
function Video() {
  const [isModalVisible, setIsModalVisible] = useRecoilState(showImage);
  const videoConfigData = useRecoilValue(imageConfig);
  const [imageWidth, setImageWidth] = useState(500);
  const [imageHeight, setImageHeight] = useState(500);
  const [loading, setLoading] = useState(true);
  const picture = useRef();
  useEffect(() => {
    if (videoConfigData.source) {
      setLoading(true);
      // const img = new Image();
      // img.src = videoConfigData.source;
      // img.onload = function (e) {
      //   setLoading(false);
      // };
      AutoResizeImage(videoConfigData.source, (h, w) => {
        setImageWidth(w);
        setImageHeight(h);
        setLoading(false);
      });
    }
  }, [isModalVisible, videoConfigData]);
  return (
    <>
      <div
        className="screen-video-wrap screen-image-wrap"
        style={{ display: isModalVisible ? "" : "none" }}
      >
        <CloseCircleOutlined
          className="colse-btn"
          onClick={() => {
            setIsModalVisible(false);
          }}
        />
        {isModalVisible && (
          <Spin spinning={loading} tip="图片加载中...">
            <div className="loading-wrap"></div>
            <img
              ref={picture}
              src={videoConfigData.source}
              width={`${imageWidth}px`}
              height={`${imageHeight}px`}
              style={{ width: `${imageWidth}px`, height: `${imageHeight}px` }}
              className="screen-image"
              onError={() => {
                message.error("该资源获取失败");
                setLoading(false);
                setIsModalVisible(false);
              }}
            />
          </Spin>
        )}
      </div>
    </>
  );
}

export default Video;
