import React, { useState, useEffect } from "react";
import { keepTwoDecimalFull } from "@/utils/common";
import { gateTunne } from "@/store/raininfo";
import { useRecoilValue } from "recoil";
import Tit from "@/components/tit";
import "./index.less";

function FloodWaterConfig(props) {
  const gateTunneCopy = useRecoilValue(gateTunne); // 溢洪道 + 放空洞
  const [amount, setAmount] = useState(0); // 总流量
  useEffect(() => {
    if (!!gateTunneCopy.ecoLDetails) {
      setAmount(keepTwoDecimalFull(gateTunneCopy.ecoLDetails.flowSecond * 3600, 1))
    }
  }, [gateTunneCopy])
  return (
    <div className="floodWater-config-wrap m-t-10">
      <Tit tit="溢洪道流量">
        <div className="tit">
          <span>
            <i>{amount}</i>
          </span>
          <span className="unit m-l-5">m³/h</span>
        </div>
      </Tit>
      <div className="m-t-10 flex flex-j-b">
        <ul className="flex flex-j-b floodWater-wrap-main-l">
          {new Array(8).fill(8).map((item, index) => {
            return (
              <li key={index}>
                <dt>{index + 1}#弧门</dt>
                <dd>
                  <span>
                    <i>0</i>
                  </span>
                  <span className="unit">m³/s</span>
                </dd>
                <dd>
                  <span>
                    <i>0</i>
                  </span>
                  <span className="unit">m</span>
                </dd>
              </li>
            );
          })}
        </ul>
        <div className="floodWater-wrap-main-r">
          <div className="floodWater-wrap-main-r-c">
            <dt>泄洪洞</dt>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd>
          </div>
          <div className="floodWater-wrap-main-r-c">
            <dt>生态阀</dt>
            <dd style={{ marginTop: '30px' }}>
              <span>
                <i>{gateTunneCopy.ecoLDetails ? keepTwoDecimalFull(gateTunneCopy.ecoLDetails.flowSecond || 0, 1) : 0}</i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            {/* <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default FloodWaterConfig;
