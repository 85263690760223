import React, { useState, useEffect } from "react";
import { getRealTimeInfo } from "@/api/union";
import { keepTwoDecimalFull } from "@/utils/common";
import Style from "./index.module.less";
const _ = require("lodash");
function Downstream(props) {
    const [zjtInfo, setZjtInfo] = useState({}); // 钟家坛
    const [height, setHeight] = useState(74); // 高度
    /**
     * 钟家坛断面信息 
     */
    const getRealTimeInfoFun = () => {
        getRealTimeInfo().then(res => {
            const results = res.data.results
            setZjtInfo(results)
            if (results.water) {
                setHeight(116 - 19.538 * (8 - results.water))
            }
        }).catch(err => {
            console.log(err)
        })
        setTimeout(() => {
            getRealTimeInfoFun();
        }, 300000); //   5分钟接口单独请求一次
    }
    useEffect(() => {
        getRealTimeInfoFun();
    }, []);
    return (
        <div className={`${Style[`downstream-wrap`]}`}>
            <div className={`${Style['tag-wrapper']}`}>
                <div className={`${Style['tag-bg']}`}></div>
                <div className={`${Style['tag-main']}`}>钟家潭</div>
            </div>
            <div className={`${Style['down-flow']}`}>
                流量：{keepTwoDecimalFull(zjtInfo.flow || 0, 2)} m³/s
            </div>
            <div className={`${Style['down-img']}`}>
                <div className={`${Style['down-line']}`}></div>
                <div className={`${Style['down-wave']}`} style={{ height: height + 'px' }}>
                    <div className={`${Style['down-wave-txt']}`}>
                        <span className={`${Style[`arrow`]}`}></span> {keepTwoDecimalFull(zjtInfo.water || 0, 2)}m
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Downstream;
