import React, { useState, useEffect } from "react";
import { getSpillway } from "@/api/rain";
import Tit from "@/components/tit";
import { keepTwoDecimalFull } from "@/utils/common"
import "./index.less";
const _ = require('lodash')
function FloodWater(props) {
  const [liuliang, setLiuliang] = useState(0); // 流量
  const [dataSoure, setDataSource] = useState([{
    flow: 0,
    realOpening: 0
  }, {
    flow: 0,
    realOpening: 0
  }, {
    flow: 0,
    realOpening: 0
  }, {
    flow: 0,
    realOpening: 0
  }, {
    flow: 0,
    realOpening: 0
  }]);
  useEffect(() => {
    getDtuFun();
    setInterval(() => {
      getDtuFun();
    }, 300000); //   5分钟接口单独请求一次
  }, []);
  const getDtuFun = () => {
    let liuliangCopy = 0
    getSpillway().then((res) => {
      // 获取流量
      if (!_.isEmpty(res.data.results)) {
        res.data.results.map(item => {
          liuliangCopy = liuliangCopy + item[`flow`]
          item[`flow`] = keepTwoDecimalFull(item[`flow`], 1)
          item[`realOpening`] = keepTwoDecimalFull(item[`realOpening`], 2)
        })
        setLiuliang(keepTwoDecimalFull(liuliangCopy, 1))
        setDataSource(res.data.results)
      }
    }).catch(err => {

    });
  };
  return (
    <div className="m-t-10 lsFloodWater-wrap">
      <Tit tit="溢洪道流量">
        <div className="tit">
          <span>
            <i>{liuliang}</i>
          </span>
          <span className="unit m-l-5">m³/s</span>
        </div>
      </Tit>
      <div className="floodWater-wrap-main m-t-10">
        <ul className="flex flex-j-b">
          {dataSoure.map((item, index) => (<li>
            <dt>{item.gateNo}#弧门</dt>
            <dd>
              <span>
                <i>{item.flow}</i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            <dd>
              <span>
                <i>{item.realOpening}</i>
              </span>
              <span className="unit">m</span>
            </dd>
          </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
export default FloodWater;
