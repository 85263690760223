import React, { useEffect, useState } from "react";
import moment from "moment";
import { keepTwoDecimalFull } from "@/utils/common";
import "./index.less";
const _ = require('lodash');
const formatNum = (num) => {
    if (num === null) {
        return 0
    }
    let value = num + '';
    value = value.replace(/,/g, "");
    if (/^[1-9]\d*$/.test(value)) {
        value = value.replace(/(?=(?!\b)(?:\d{3})+(?!\d))/g, ",");
    }

    if (value.includes('.')) {
        let tempArr = value.split('.')
        if (Array.isArray(tempArr) && tempArr.length === 2) {
            const tempStr = tempArr[0].replace(/(?=(?!\b)(?:\d{3})+(?!\d))/g, ",");
            value = tempStr + '.' + tempArr[1]
        }
    }

    return value;
}
function padding(num, length) {
    num = num || 0
    for (var len = (num + "").length; len < length; len = num.length) {
        num = "0" + num;
    }
    return String(num);
}
function DayNum(props) {
    const [diffTime, setDiffTime] = useState([]);
    const [stime, setStime] = useState('');
    const [data, setData] = useState({});
    useEffect(() => {
        const powerGenerationInfo = props.powerGenerationInfo
        if (!_.isEmpty(powerGenerationInfo)) {
            const dataCopy = {}
            const stime = moment().subtract(powerGenerationInfo.operationDay, 'days').format('YYYY年MM月DD日')
            for (let i in powerGenerationInfo) {
                if (i != "runTime" && i != "operationDay") {
                    if (powerGenerationInfo[i] < 0) {
                        powerGenerationInfo[i] = 0
                    }
                    dataCopy[i] = keepTwoDecimalFull(powerGenerationInfo[i], 0)
                } else {
                    dataCopy[i] = powerGenerationInfo[i]
                }
            }
            setStime(stime)
            setData(dataCopy)
            setDiffTime(padding(powerGenerationInfo.operationDay, 4).split(''))
        }
    }, [props])
    return !props.loading && <div className="qc-DayNum-wrap m-l-20 flex flex-colum">
        <div className="DayNum-stime">
            起始日期<span className="m-l-5 m-r-5">:</span> {stime}
        </div>
        <div className="DayNum-title">
            安全运行
        </div>
        <div className="DayNum-countDown flex flex-j-c m-b-20">
            <div className="countDown-main flex flex-a-c">
                <div className="countDown-txt m-r-20">第</div>
                <dl className="countDown-num-list flex">
                    {diffTime.map((item, index) => {
                        return <dd key={index}>{item}</dd>
                    })}
                </dl>
                <div className="countDown-txt m-l-20">天</div>
            </div>
        </div>
        <div className="DayNum-list flex flex-j-c">
            <dl className="countDown-info-list flex">
                <dd>
                    <div className="info-num fz-32">
                        <span>
                            <i>{keepTwoDecimalFull(data.runTime, 0)}</i>
                        </span>
                        <span className="unit fz-16 m-l-10">小时</span></div>
                    <div className="info-text m-t-12">年利用小时数(折算)</div>
                </dd>
                <dd>
                    <div className="info-num fz-16">
                        {data && data[`co2`] ? (
                            <span>
                                <i className="fz-32">{data[`co2`]}</i>
                            </span>
                        ) : (
                            <span>
                                <i className="fz-32">0</i>
                            </span>
                        )}<span className="unit fz-16 m-l-10">吨</span></div>
                    <div className="info-text m-t-12">总累计二氧化碳减排量</div>
                </dd>
                <dd>
                    <div className="info-num fz-16">
                        {data && data[`coal`] ? (
                            <span>
                                <i className="fz-32">{data[`coal`]}</i>
                            </span>
                        ) : (
                            <span>
                                <i className="fz-32">0</i>
                            </span>
                        )}<span className="unit fz-16 m-l-10">吨</span></div>
                    <div className="info-text m-t-12">总累计节约标准煤量</div>
                </dd>
            </dl>
        </div>
    </div>
}
export default DayNum