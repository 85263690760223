import React, { useState, useEffect } from "react";
import { getDownstreamFlow } from "@/api/rainHs";
import { nowFlow } from "@/store/raininfo";
import { useRecoilValue } from "recoil";
import { keepTwoDecimalFull } from "@/utils/common";
function WaterSupply(props) {
  const nowFlowCopy = useRecoilValue(nowFlow);// 横山特供下泄总流量
  const [streamFlow, setStreamFlow] = useState({});
  const getFlowByNameFun = () => {
    getDownstreamFlow().then((res) => {
      const results = res.data.results;
      for (let v in results) {
        if (v == 'day' || v == 'yestoday') {
          if (results[v] < 1) {
            results[`${v}Arr`] = `${results[v] * 10000}`.split('.')
          } else {
            results[`${v}Arr`] = `${keepTwoDecimalFull(results[v], 4)}`.split('.')
          }
        } else {
          results[`${v}Arr`] = `${keepTwoDecimalFull(results[v], 4)}`.split('.')
        }
      }
      setStreamFlow(results);
    }).catch(err => {
      console.log(err)
    })
  };
  useEffect(() => {
    getFlowByNameFun();
  }, []);
  return (
    <div className="water-supply-wrap flex  flex-j-b flex-a-c water-supply_xiayou-wrap">
      <div className="tit-water-wrap">下泄信息</div>
      <div className="water-supply-main-wrap flex flex-j-b water-supply_xiayou-main-wrap">
        <div className="w100 p-t-25">
          <table className="w100" style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th>当前流量</th>
                <th>今日水量</th>
                <th>昨日水量</th>
                <th>当月累计水量</th>
                <th>全年累计水量</th>
                {/* <th>总供水量</th> */}
              </tr>
            </thead>
            <tbody>
              <tr >
                <td>
                  <span className='num'>
                    {nowFlowCopy.length ? (<><i>{nowFlowCopy[0]}</i>.{nowFlowCopy[1]}</>) : <i>0</i>}
                  </span>
                  <span className="unit">m³/s</span>
                </td>
                <td>
                  <span className='num'>
                    {streamFlow.day ? (<><i>{streamFlow.dayArr[0]}</i>{streamFlow.day > 1 && `.${streamFlow.dayArr[1]}`}</>) : <i>0</i>}
                  </span>
                  <span className="unit">{streamFlow.day > 1 ? '万m³' : 'm³'}</span>
                </td>
                <td>
                  <span className='num'>
                    {streamFlow.yestoday ? (<><i>{streamFlow.yestodayArr[0]}</i>{streamFlow.yestoday > 1 && `.${streamFlow.yestodayArr[1]}`}</>) : <i>0</i>}
                  </span>
                  <span className="unit">{streamFlow.yestoday > 1 ? '万m³' : 'm³'}</span>
                </td>
                <td>
                  <span className='num'>
                    {streamFlow.month ? (<><i>{streamFlow.monthArr[0]}</i>.{streamFlow.monthArr[1]}</>) : <i>0</i>}
                  </span>
                  <span className="unit">万m³</span>
                </td>
                <td>
                  <span className='num'>
                    {streamFlow.year ? (<><i>{streamFlow.yearArr[0]}</i>.{streamFlow.yearArr[1]}</>) : <i>0</i>}
                  </span>
                  <span className="unit">万m³</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
}

export default WaterSupply;
