import React, { useState, useEffect } from "react";
import { keepTwoDecimalFull } from "@/utils/common";
import { getSpillway } from "@/api/rainTx";
import Tit from "@/components/tit";
import "./index.less";
const _ = require("lodash");

function FloodWater(props) {
    const [amount, setAmount] = useState(0);
    const [gateTunneCopy, setGateTunneCopy] = useState([]); // 溢洪道 + 放空洞
    const getSpillwayFun = () => {
        getSpillway().then(res => {
            let _amountCopy = 0
            res.data.results.map(item => {
                _amountCopy = _amountCopy + item.flow
            })
            setAmount(_amountCopy)
            setGateTunneCopy(res.data.results)
            setTimeout(() => {
                getSpillwayFun();
            }, 300000); //   5分钟接口单独请求一次
        }).catch(err => {
            setTimeout(() => {
                getSpillwayFun();
            }, 300000); //   5分钟接口单独请求一次
        })
    }
    useEffect(() => {
        getSpillwayFun()
    }, [])
    return (
        <div className="bzj-floodWater-wrap m-t-10">
            <Tit tit="溢洪道">
                <div className="tit">
                    <span>
                        <i>{keepTwoDecimalFull(amount || 0, 1)}</i>
                    </span>
                    <span className="unit m-l-5">m³/s</span>
                </div>
            </Tit>
            <div className="bzj-floodWater-wrap-main flex flex-j-b flex-a-c m-t-30">
                <div className="bzj-floodWater-wrap-main-l">
                    <ul className="flex flex-j-b">
                        {gateTunneCopy.map((item, index) =>
                            <li key={index}>
                                <dt>{item.gateNo}#弧门</dt>
                                <dd>
                                    <span>
                                        <i>{keepTwoDecimalFull(item.flow || 0, 1)}</i>
                                    </span>
                                    <span className="unit">m³/s</span>
                                </dd>
                                <dd className="ft-18">
                                    <span>
                                        <i>{keepTwoDecimalFull(item.open || 0, 2)}</i>
                                    </span>
                                    <span className="unit">m</span>
                                </dd>
                            </li>
                        )}
                    </ul>
                </div>
                {/* <div className="bzj-floodWater-wrap-main-r">
          <div className="bzj-floodWater-wrap-main-r-c">
            <dt>放空洞1111111</dt>
            {gateTunneCopy.tunnelList.map((item, index) =>
              <>
                <dd key={index}>
                  <span>
                    <i>
                      {keepTwoDecimalFull(item.flow || 0, 1)}
                    </i>
                  </span>
                  <span className="unit">m³/s</span>
                </dd>
                <dd>
                  <span>
                    <i>{keepTwoDecimalFull(item.open || 0, 2)}</i>
                  </span>
                  <span className="unit">m</span>
                </dd>
              </>)}
          </div>
        </div> */}
            </div>
        </div>
    );
}
export default FloodWater;
