import React from "react";
import { Drawer } from "antd";
import { showMenuVisible, configData } from "@/store/raininfo";
import { useRecoilState, useRecoilValue } from "recoil";
import CONFIG from "@/config";
function NewMenu() {
  const [visible, setVisible] = useRecoilState(showMenuVisible); // 控制显示隐藏
  const configDataReal = useRecoilValue(configData);
  const onClose = () => {
    setVisible(false);
  };
  return (
    <Drawer
      className="drawer-wrap"
      title="大屏设置"
      closable
      visible={visible}
      onClose={onClose}
    >
      <iframe
        src={`https://${CONFIG.IFRAMEHOST}/#/control?corpid=${configDataReal.corpid}&isPC=1`}
        className="h100 w100"
      />
    </Drawer>
  );
}
export default NewMenu;
