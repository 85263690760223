import Tit from "@/components/tit";
import React, { useEffect, useState } from "react";
import "./index.less";
import { getDuty } from "@/api/rain";
import moment from "moment";
function setNameFun(item, num = 1) {
  const arr = []
  if (!!item) {
    item.split(",").map((item2, index) => {
      if (index <= num - 1) {
        if (item2.length === 2) {
          item2 = item2.slice(0, 1) + "\xa0\xa0\xa0\xa0" + item2.slice(1);
        }
        arr.push(item2);
      }
    });
    return arr.toString();
  } else {
    return ''
  }
}
function Onduty(params) {
  const [data, setData] = useState([]);
  useEffect(() => {
    getDuty().then((res) => {
      const results = res.data.results;
      const arr = [];
      if (!!results) {
        // eslint-disable-next-line array-callback-return
        results.map((item, index) => {
          item.tm = moment(item.tm).format("MM-DD");
          // 取两位
          item.leader = setNameFun(item.leader);
          item.duty_member1 = setNameFun(item.duty_member1, 2);
          item.duty_member2 = setNameFun(item.duty_member2);
          item.duty_zhb = setNameFun(item.duty_zhb);
          arr.push(item);
        });
        setData(arr);
      }
    });
  }, []);
  const CreatTb = (props) => {
    return props.data.map((item, index) => {
      return (
        <tr key={index} className={`tr-${index}`}>
          <td style={{ width: '70px' }}>{item.tm}</td>
          <td>{item.leader}</td>
          <td>{item.duty_member1}</td>
          <td>{item.duty_zhb}</td>
          <td>{item.duty_member2}</td>
        </tr>
      );
    });
  };
  return (
    <div className="onduty-wrap qcOnduty-wrap">
      <Tit tit="值班信息"></Tit>
      <div className="onduty-main-wrap-1">
        <div className="onduty-main-wrap m-t-19">
          {!data.length ? (
            <div className="no-list p-t-62 f-z-20 m-b-20">暂无数据</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>值班日期</th>
                  <th>值班组长</th>
                  <th>公司</th>
                  <th>指挥部</th>
                  <th>值班领导</th>
                </tr>
              </thead>
              <tbody>
                <CreatTb data={data}></CreatTb>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default Onduty;
