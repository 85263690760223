import axios from "axios";
import { message } from "antd";
import CONFIG from "@/config";
const service = axios.create({
  baseURL: process.env.NODE_ENV == "development" ? "/api" : "/api", // api 的 base_url
  timeout: 60000,
});

service.interceptors.request.use(
  (config) => {
    const channelid = CONFIG.CHANNELID;
    config.headers = {
      ...config.headers,
      channelid,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      if (response.code === 0 || !response.msg) {
        resolve(response);
      } else {
        // message.error(response.msg);
        reject(response);
      }
    });
  },
  (error) => {
    // 对响应错误做点什么
    try {
      message.error(CONFIG.ERRORINFO[error.response.status]);
    } catch (err) {
      console.log(err);
    }
    return Promise.reject(error);
  }
);
export default service;
