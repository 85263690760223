import React, { useEffect, useState } from "react";
import { getEcoFlow } from "@/api/rainBzj";
import { keepTwoDecimalFull } from "@/utils/common";
function WaterSupply(props) {
  const [data, setData] = useState(null);
  const getWaterSupplyFun = () => {
    getEcoFlow().then((res) => {
      // 供水信息
      const results = res.data.results;
      for (let i in results) {
        if (i != "ssll") {
          results[i] = String((results[i].val / 10000).toFixed(4)).split(".");
        } else if (i == "ssll") {
          // 瞬时供水
          results[i] = results[i].val ? String(keepTwoDecimalFull(results[i].val, 2)).split(".") : results[i].val;
        }
      }
      setData(results);
    });
  };
  useEffect(() => {
    getWaterSupplyFun();
    setInterval(() => {
      getWaterSupplyFun();
    }, 300000); //   5分钟接口单独请求一次
  }, []);
  return (
    <div className="water-supply-wrap flex  flex-j-b flex-a-c">
      <div className="tit-water-wrap">生态流量</div>
      <div className="water-supply-main-wrap  flex flex-j-b">
        <dl>
          <dt>瞬时生态流量</dt>
          <dd>
            {data && data[`ssll`] ? (<span>
              <i>{data[`ssll`][0]}</i>.{data[`ssll`][1]}
            </span>) : (
              <span>
                <i>0</i>
              </span>
            )}
            <span className="unit">m³/s</span>
          </dd>
        </dl>
        <dl>
          <dt>今日生态水量</dt>
          <dd>
            {data && data[`jrll`] ? (
              <span>
                <i>{data[`jrll`][0]}</i>.{data[`jrll`][1]}
              </span>
            ) : (
              <span>
                <i>0</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
        <dl>
          <dt>昨日生态水量</dt>
          <dd>
            {data && data[`zrll`] ? (
              <span>
                <i>{data[`zrll`][0]}</i>.{data[`zrll`][1]}
              </span>
            ) : (
              <span>
                <i>0</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
        <dl>
          <dt>当月累计生态水量</dt>
          <dd>
            {data && data[`byll`] ? (
              <span>
                <i>{data[`byll`][0]}</i>.{data[`byll`][1]}
              </span>
            ) : (
              <span>
                <i>0</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
        <dl>
          <dt>全年累计生态水量</dt>
          <dd>
            {data && data[`qnll`] ? (
              <span>
                <i>{data[`qnll`][0]}</i>.{data[`qnll`][1]}
              </span>
            ) : (
              <span>
                <i>0</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
      </div>
    </div>
  );
}

export default WaterSupply;
