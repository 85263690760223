import request from "../utils/request";
// import qs from "qs";
// import CONFIG from "@/config";


// 百丈及生态
export async function getEcoFlow(data) {
    return request(`/tx/ecoFlow`, {
        method: "get",
    });
}


// 百丈漈发电信息
export async function getOowerGenerationInfo(data) {
    return request(`/tx/powerGenerationInfo`, {
        method: "get",
    });
}
