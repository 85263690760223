/* eslint-disable react/style-prop-object */
import "./App.css";
import React, { useEffect, useState } from "react";
import "@/assets/css/index.less";
import * as componentLib from "./module/index"; // 模块入口
import NewMenu from "@/module/newMenu";
import Video from "@/module/video";
import ImageWrap from "@/module/image";
import Time from "@/components/time";
import Logo from "@/components/logo";
import LoadedImg from "@/components/loadedImg"; // 预加载图片入口
import {
  titModel,
  mapModel,
  webscocketStatus,
  settingData,
  configData,
} from "@/store/raininfo";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { Button, Spin, Result } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as dd from "dingtalk-jsapi";
import { getSystemConfig } from "@/api/rain";
import { GetRequest } from "@/utils/common";
const CHANNELID = GetRequest().channelid;
function App() {
  const [settingDataReal, setSettingDataReal] = useRecoilState(settingData); // 大屏数据配置信息
  const [configDataReal, setConfigDataReal] = useRecoilState(configData); // 大屏数据所有配置信息
  const showType = useRecoilValue(mapModel); // 2.显示地图 1.显示大坝
  const webscocketStatusType = useRecoilValue(webscocketStatus); // 信号Status
  const titType = useRecoilValue(titModel); // 2.党建 1.常规
  const [showBackBtn, setShowBackBtn] = useState(false); // 显示回车按钮
  const [isIos, setIsIos] = useState(true); // 判断是不是苹果手机还是安卓
  const renderDataMap = (type) => {
    const dom = settingDataReal[`layOut`][type].map((item, index) => {
      if (!(item.componentName && componentLib[item.componentName])) {
        return null;
      }
      if (item.componentName == "Emergency") {
        const TagName = componentLib[`Emergency`];
        const TagName2 = componentLib[`WaterCategory`];
        return <TagName WaterCategory={<TagName2 />} />;
      } else {
        const TagName = componentLib[item.componentName];
        return <TagName key={index} />;
      }
    });
    return dom;
  };
  useEffect(() => {
    if (dd.env.platform !== "notInDingTalk") {
      //进行钉钉登录操作
      dd.ready(function () {
        dd.device.base.getPhoneInfo({
          onSuccess: function (data) {
            setIsIos(!!data.brand.indexOf('iphone'))
          },
          onFail: function (err) { }
        });
      });
      setShowBackBtn(true);
    }
    if (!!CHANNELID) {
      getSystemConfig().then((res) => {
        setSettingDataReal(res.data.results.config_for_update);
        setConfigDataReal(res.data.results);
      });
    }
  }, []);
  return !!settingDataReal ? (
    <div className={`container-wrap flex flex-colum container-wrap-bg container-wrap-bg-1`}>
      <NewMenu />
      {/*头部*/}
      <div className="header-wrap p-t-29">
        <div
          className={`header-wrap-bg`}
          style={{
            backgroundImage: `url(${titType == 1
              ? require(`Image/${settingDataReal.setting.headerBg ||
                "img_title_yueshui@2x.png"
                }`).default
              : require("Image/img2.png").default
              })`,
          }}
        >
          {/* <div
            className={`wifi ${webscocketStatusType ? "has-wifi" : "no-wifi"}`}
          ></div> */}
          <Time />
        </div>
      </div>
      {/*内容显示*/}
      <div className="flex container-bd-wrap flex-1">
        {/*左侧栏*/}
        {!!settingDataReal[`layOut`]["leftCom"].length && <div className="container-left-wrap m-l-28 flex">
          <div>
            {renderDataMap("leftCom")}
          </div>
        </div>}
        {/*中间侧栏*/}
        <div className="container-center-wrap flex1 flex flex-a-c flex-1 flex-j-c">
          <div className="flex-1 h100">
            {renderDataMap("centerCom")}
          </div>
        </div>
        {/*右侧侧栏*/}
        {!!settingDataReal[`layOut`]["leftCom"].length && <div className="container-right-wrap m-r-28  flex">
          <div>
            {renderDataMap("rightCom")}
            {/* <Logo /> */}
          </div>
        </div>}
        <Video />
        <ImageWrap />
      </div>
      <LoadedImg />
      {
        showBackBtn ? (
          <Button
            type="primary"
            className={`back-btn ${isIos ? 'back-btn-ios' : ''} ${configDataReal && configDataReal.corpid.indexOf('3306240001') != -1 ? 'back-btn-2' : ''}`}
            onClick={() => {
              if (dd.env.platform !== "notInDingTalk") {
                dd.biz.navigation.close();
              }
            }}
            size="large"
          >
            关闭
          </Button>
        ) : (
          ""
        )
      }
    </div >
  ) : !!CHANNELID ? (
    <div className="allWrap">
      <div className="allWrap-child">
        <Spin
          size="large"
          tip="Loading..."
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        ></Spin>
      </div>
    </div>
  ) : (
    <Result status="warning" title="无法获取到大屏对应的channelid" />
  );
}

export default App;
