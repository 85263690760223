import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { settingData } from "@/store/raininfo";
import { getXxeco } from "@/api/rain";
import Tit from "@/components/tit";
import "./index.less";
import axios from "axios";
const _ = require("lodash");
function FloodWater(props) {
  const [settingDataReal, setSettingDataReal] = useState({
    open: 3,
    liuliang: 0,
  });
  const getXxskFun = () => {
    getXxeco()
      .then((res) => {
        const settingDataRealCopy = _.cloneDeep(settingDataReal);
        if (res.data.results) {
          res.data.results.map((item, index) => {
            if (item.name.indexOf("流量") != -1) {
              settingDataRealCopy[`liuliang`] = Number(item.value);
              settingDataRealCopy[`open`] = item.value == 0 ? 1 : 3;
            }
          });
          console.log("settingDataRealCopy==>", settingDataRealCopy);
          setSettingDataReal(settingDataRealCopy);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    // getXxskFun();
  }, []);
  return (
    <div className="xx-floodWater-wrap m-t-10">
      <Tit tit="溢洪道流量">
        <div className="tit">
          <span>
            <i>0</i>
          </span>
          <span className="unit m-l-5">m³/h</span>
        </div>
      </Tit>
      <div className="xx-floodWater-wrap-main m-t-10 flex flex-j-b flex-a-c">
        <div className="xx-floodWater-wrap-main-l">
          <ul className="flex flex-j-b">
            <li>
              <dt>1#弧门</dt>
              <dd>
                <span>
                  <i>0</i>
                </span>
                <span className="unit">m³/s</span>
              </dd>
              <dd>
                <span>
                  <i>0</i>
                </span>
                <span className="unit">m</span>
              </dd>
            </li>
            <li>
              <dt>2#弧门</dt>
              <dd>
                <span>
                  <i>0</i>
                </span>
                <span className="unit">m³/s</span>
              </dd>
              <dd>
                <span>
                  <i>0</i>
                </span>
                <span className="unit">m</span>
              </dd>
            </li>
            <li>
              <dt>3#弧门</dt>
              <dd>
                <span>
                  <i>0</i>
                </span>
                <span className="unit">m³/s</span>
              </dd>
              <dd>
                <span>
                  <i>0</i>
                </span>
                <span className="unit">m</span>
              </dd>
            </li>
          </ul>
        </div>
        <div className="xx-floodWater-wrap-main-r">
          <div className="xx-floodWater-wrap-main-r-c">
            <dt>泄放洞</dt>
            <dd>
              <span>
                <i>
                  {settingDataReal && settingDataReal.open == 3
                    ? Number(settingDataReal.liuliang).toFixed(2)
                    : 0}
                </i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            {/* <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default FloodWater;
