import React from "react";
function Wave(params) {
    return (
        <svg
            className="fill w100"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 665 423.469"
        >
            <linearGradient id="orange_red" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="rgba(83, 177, 252, 1)" />
                <stop offset="100%" stopColor="rgba(3, 27, 59, 1)" />
            </linearGradient>
            <g
                id="页面-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <path
                    id="img_water_front"
                    className="cls-1"
                    d="M665,424H0V9.194s6.1-.31,16.767-1.082c9-.649,10.445-6.063,20.012-5.949,8.9,0.105,13.865,8.653,21.094,8.653,9.736,0,15.956-9.735,24.339-9.735,9.79,0,12.694,9.194,19.472,9.194,8.138,0,12.793-9.194,22.175-9.194,8.147,0,12.855,9.194,21.094,9.194,8,0,14.774-8.112,21.635-8.112,7.851,0,11.107,6.49,17.848,6.49,7.248,0,8.571-7.031,17.308-7.031,8.124,0,18.076,6.594,26.5,6.49,6.328-.078,9.98-7.031,19.471-7.031,8.364,0,20.611,7.658,29.207,7.571,8.639-.087,14.421-6.972,23.257-7.031,8.175-.054,16.563,7.094,24.88,7.031,7.335-.054,14.747-4.821,22.176-4.867,7.117-.045,14.447,5.462,21.634,5.408,8.777-.064,18.189-5.895,27.043-5.949,7.846-.045,18.627,7.085,26.5,7.031,9.309-.061,17.718-8.6,27.043-8.653,8.8-.049,17.178,10.33,25.962,10.276,8.012-.054,14.228-9.194,26.5-9.194,11.759,0,19.2,7.109,30.829,7.031C538.9,9.693,545,.541,556.01.541c9.389,0,12.753,9.766,18.389,9.735,7.242-.04,12.867-9.7,20.012-9.735,8.854-.039,11.877,8.7,20.553,8.653,6.983-.035,13.83-6.49,24.339-6.49C647.091,2.7,665,9,665,9V424Z"
                    transform="translate(0 -0.438)"
                    fill="url(#orange_red)"
                >
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="translate"
                        from="0"
                        to="-80"
                        dur="2s"
                        repeatCount="indefinite"
                    ></animateTransform>
                </path>
                <path
                    id="img_water_front"
                    className="cls-1"
                    d="M665,424H0V9.194s6.1-.31,16.767-1.082c9-.649,10.445-6.063,20.012-5.949,8.9,0.105,13.865,8.653,21.094,8.653,9.736,0,15.956-9.735,24.339-9.735,9.79,0,12.694,9.194,19.472,9.194,8.138,0,12.793-9.194,22.175-9.194,8.147,0,12.855,9.194,21.094,9.194,8,0,14.774-8.112,21.635-8.112,7.851,0,11.107,6.49,17.848,6.49,7.248,0,8.571-7.031,17.308-7.031,8.124,0,18.076,6.594,26.5,6.49,6.328-.078,9.98-7.031,19.471-7.031,8.364,0,20.611,7.658,29.207,7.571,8.639-.087,14.421-6.972,23.257-7.031,8.175-.054,16.563,7.094,24.88,7.031,7.335-.054,14.747-4.821,22.176-4.867,7.117-.045,14.447,5.462,21.634,5.408,8.777-.064,18.189-5.895,27.043-5.949,7.846-.045,18.627,7.085,26.5,7.031,9.309-.061,17.718-8.6,27.043-8.653,8.8-.049,17.178,10.33,25.962,10.276,8.012-.054,14.228-9.194,26.5-9.194,11.759,0,19.2,7.109,30.829,7.031C538.9,9.693,545,.541,556.01.541c9.389,0,12.753,9.766,18.389,9.735,7.242-.04,12.867-9.7,20.012-9.735,8.854-.039,11.877,8.7,20.553,8.653,6.983-.035,13.83-6.49,24.339-6.49C647.091,2.7,665,9,665,9V424Z"
                    transform="translate(0 -0.438)"
                    fill="url(#orange_red)"
                />
            </g>
        </svg>
    );
}
export default Wave;
