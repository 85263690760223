import React, { useEffect, useState } from "react";
import Tit from "@/components/tit";
import * as echarts from 'echarts';
import { getYearOverallCategory, getInteractionInfo } from "@/api/rainQc";
import Style from "./index.module.less";
import { configData } from "@/store/raininfo";
import { useRecoilValue } from "recoil";
import { convert } from "@/utils/common";
import { Modal, Carousel } from 'antd';
import _ from 'lodash';
import "./index.less";
function setColorFun(num) {
    const colorList = ['#67cbac', '#ff8a3c', '#e95a54']
    if (num <= 5 && num > 3) {
        return colorList[0]
    } else if (num == 2) {
        return colorList[1]
    } else if (num == 1) {
        return colorList[2]
    }
}
function getColorFun(v) {
    const colorObj = {
        Red: '#e95a54',
        Green: '#67cbac',
        Orange: '#ff8a3c',
        White: '#FFF',
    }
    return colorObj[v]
}
function setNumFun(num) {
    const colorList = [1, 2, 3]
    if (num <= 5 && num > 3) {
        return colorList[0]
    } else if (num == 2) {
        return colorList[1]
    } else if (num == 1) {
        return colorList[2]
    }
    return num
}
function setNumFun2(num) {
    const colorList = [5, 4, 3, 2, 1]
    return colorList[num - 1]
}
function setNumTableFun(v) {
    const arr = ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ']
    const colorList = ["#67cbac", "#ff8a3c", "#e95a54"]
    const num = arr.indexOf(v) + 1
    if (num < 3) {
        return colorList[0]
    } else if (num < 4) {
        return colorList[1]
    } else if (num == 5) {
        return colorList[2]
    }
}
function setNum(v) {
    // const arr = ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ']  // ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ']
    const arr = ['Ⅴ', 'Ⅳ', 'Ⅲ', 'Ⅱ', 'Ⅰ']
    return arr.indexOf(v) + 1
}
function reverseFun(v) {
    const arr = ['Ⅴ', 'Ⅳ', 'Ⅲ', 'Ⅱ', 'Ⅰ']
    return arr[v - 1]
}
function WaterYear(props) {
    const configDataReal = useRecoilValue(configData); // 大屏数据所有配置信息
    const [tableList, setTableList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modelTit, setModelTit] = useState('');
    const [modelLevel, setModelLevel] = useState({});
    /**
     *  获取每个月的总水质指标
     */
    const getYearOverallCategoryFun = () => {
        getYearOverallCategory().then(res => {
            const results = res.data.results
            const xAxisData = []
            const seriesDta = []
            const arr = _.slice(results, results.length - 11, results.length)
            if (arr.length) {
                arr.map(item => {
                    seriesDta.push(setNum(item.overallCategory))
                    xAxisData.push(item.date)
                })
            }
            initChart({ xAxisData, seriesDta })
        })
    }

    /**
     * 
     */
    const initChart = ({ xAxisData = [], seriesDta = [] }) => {
        var chartDom = document.getElementById('WaterYearWrapId');
        var myChart = echarts.init(chartDom);
        myChart.on('click', (e) => {
            let handleIndex = Number(e.dataIndex); //索引下标
            targetFun({ index: handleIndex, month: xAxisData[handleIndex], level: seriesDta[handleIndex] })
        })

        const option = {
            // 缩放平移组件
            dataZoom: [
                {
                    type: 'inside',  //slider有滑块，inside内置
                    disabled: false,  //是否停止组件功能
                    xAxisIndex: 0,  //x轴,可以用数组表示多个轴
                    zoomLock: true,  //是否锁定区域大小（true,只能平移不能缩放）
                    preventDefaultMouseMove: true,
                    filterMode: "empty",
                    startValue: xAxisData.length - 5,  //一行有几个（起始数组下标）
                    endValue: xAxisData.length - 1,    //一行有几个（结束数组下标）
                    start: null,
                    end: null,
                },
            ],
            grid: {
                left: "9%",
                top: "18%",
                bottom: 50,
                right: 0,
            },
            tooltip: {
                trigger: "axis",
                formatter: function (params) {
                    return (
                        "类：" +
                        reverseFun(params[0].value) +
                        "<br>时间：" +
                        params[0].name
                    );
                },
            },
            xAxis: {
                type: 'category',
                data: xAxisData,
                axisLabel: {
                    textStyle: {
                        color: "#71e6fc",
                        fontSize: 12,
                        margin: 10,
                    },
                    alignWithLabel: true,
                },
                axisTick: {
                    show: false // 不显示坐标轴刻度线
                },
                axisLine: { //坐标轴线
                    show: true,
                    lineStyle: {
                        color: '#404f60'
                    },
                },
            },
            yAxis: {
                type: 'value',
                max: 0,
                min: 5,
                // inverse: true,
                // inverse: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#404f60'
                    },
                },
                axisTick: {
                    show: false // 不显示坐标轴刻度线
                },
                axisLabel: {
                    textStyle: {
                        color: "#71e6fc",
                        fontSize: 12,
                        margin: 10,
                    },
                    formatter: (val) => {
                        return reverseFun(val);
                    },
                    alignWithLabel: true,
                },
                splitLine: { show: false },
            },
            series: [
                {
                    data: seriesDta,
                    type: 'line',
                    animation: true,
                    lineStyle: {
                        width: 2,
                        color: "#00D8FF", //改变曲线线颜色
                    },
                    symbol: 'circle',
                    symbolSize: 8,
                    itemStyle: {
                        color: (item) => {
                            return setColorFun(item.data)
                        },
                        // shadowColor: 'red',
                        // shadowBlur: 90,
                        // shadowOffsetX: 40,
                        // shadowOffsetY: 40
                    },

                    areaStyle: {
                        color: {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: "rgb(6,96,138)", // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: "rgb(8,35,69)", // 100% 处的颜色
                                },
                            ],
                        },
                    },
                }
            ]
        }
        option && myChart.setOption(option)
    }
    /**
    * 水质大屏弹窗
    */
    const targetFun = ({ index, month, level }) => {
        setIsModalOpen(true);
        setModelTit(month)
        setModelLevel({
            overallCategoryNumCopy: setNumFun2(level),
            overallCategoryNum: setNumFun(level)
        })
        getInteractionInfoFun({ month })
    }
    /**
    * 获取每月水质信息 
    */
    const getInteractionInfoFun = ({ month }) => {
        getInteractionInfo({
            corpId: configDataReal.corpid,
            month
        }).then(res => {
            const result = res.data.results
            setTableList(result);
        })
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        getYearOverallCategoryFun()
    }, [])
    return <div className="WaterYearWrap">
        <Tit tit="水质年统计"></Tit>
        <div id="WaterYearWrapId" className="WaterYearWrapId"></div>
        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} bodyStyle={{ background: 'none' }} width={1563} height={960} footer={null} maskClosable closable={false} className={`water-modal-main`}>
            <div className={Style[`water-modal-wrapper`]}>
                <div className={Style[`water-modal-wrapper-main`]}>
                    <div className={Style[`water-modal-wrapper-main_tit`]}>
                        {modelTit}水质状况
                    </div>
                    <div className={Style[`water-modal-wrapper-main_bd`]}>
                        <div className={Style[`main_left`]}>
                            <div className={`${Style.WaterLevelWrapper} flex flex-colum m-l-44`}>
                                {!_.isEmpty(modelLevel) && <div className={`flex-1 w100 flex flex-a-c flex-j-c`} >
                                    <div className={`${Style.waterLamp} ${Style[`waterLamp-${modelLevel.overallCategoryNum}`]}`}>
                                        <div className={`${Style.waterLampMain} ${Style[`waterLampMain-${modelLevel.overallCategoryNum}`]}`}>
                                            <div className={`${Style.shuizhi} ${Style[`shuizhi-${modelLevel.overallCategoryNum}`]}`}>
                                                {convert(modelLevel.overallCategoryNumCopy)} 类
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div >
                        </div>
                        <div className={Style[`main_right`]}>
                            <div className={`${Style.targetTableWrap} flex flex-colum`}>
                                <div className={`h100 flex flex-a-c flex-j-c`}>
                                    <div className={`${Style.targetTableMian} flex flex-colum`}>
                                        <div className={`${Style.row} ${Style.header} w100 flex`}>
                                            <div className={`${Style.column} ${Style.column1}`}>序号</div>
                                            <div className={`${Style.column} ${Style.column2}`}>指标类型</div>
                                            <div className={`${Style.column} ${Style.column3}`}>实际值</div>
                                            <div className={`${Style.column} ${Style.column4}`}>II类标准</div>
                                        </div>
                                        <div className={`${Style.body}`}>
                                            <div className={`${Style.content}`}>
                                                <Carousel autoplay={1000} slidesToScroll={2} dotPosition="right" dots={false} vertical verticalSwiping draggable>
                                                    {tableList.map((item, index) => <div className={`${Style.row} flex ${Style[`row-${index % 2 == 0 ? 2 : ''}`]}`} key={index}>
                                                        <div className={`${Style.column} ${Style.column1} fz-18`}>{index + 1}</div>
                                                        <div className={`${Style.column} ${Style.column2} fz-18`}>{item.target}</div>
                                                        <div className={`${Style.column} ${Style.column3} fz-18`} style={{ color: item.value == '-' ? '' : getColorFun(item.color) }}>{item.value == '-' ? '-' : item.value}</div>
                                                        <div className={`${Style.column} ${Style.column4} fz-18`}>{item.category_II_value || ''}</div>
                                                    </div>)}
                                                </Carousel>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
}
export default WaterYear