// 警告等级
export const warnLevel = [
  {
    label: "无",
    value: 0,
  },
  {
    label: "一级",
    value: 1,
  },
  {
    label: "二级",
    value: 2,
  },
  {
    label: "三级",
    value: 3,
  },
  {
    label: "四级",
    value: 4,
  },
];

export const waterQuality = [
  {
    label: "Ⅰ类",
    value: 1,
  },
  {
    label: "Ⅱ类",
    value: 2,
  },
  {
    label: "Ⅲ类",
    value: 3,
  },
  {
    label: "Ⅳ类",
    value: 4,
  },
  {
    label: "Ⅴ类",
    value: 5,
  },
  {
    label: "劣Ⅴ类",
    value: 6,
  },
];
