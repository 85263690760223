import request from "../utils/request";
// import qs from "qs";
// import CONFIG from "@/config";


// // 百丈及生态
// export async function getEcoFlow(data) {
//     return request(`/tx/ecoFlow`, {
//         method: "get",
//     });
// }


// 获取每个月的总水质指标
export async function getYearOverallCategory(data) {
    return request(`/wqs/getYearOverallCategory`, {
        method: "get",
        data
    });
}


// 获取最新的总水质指标
export async function getOverallCategory(data) {
    return request(`/wqs/getOverallCategory`, {
        method: "get",
    });
}

// 获取重要指标信息
export async function getImportanceTarget(data) {
    return request(`/wqs/getImportanceTarget`, {
        method: "get",
    });
}

// 获取重要指标信息
export async function getWaterQuality(data) {
    return request(`/wqs/getWaterQuality`, {
        method: "get",
    });
}


// 获取监测点数据
export async function getMonitoringPointData(data) {
    return request(`/wqs/getMonitoringPointData`, {
        method: "get",
    });
}


// 获取水质交互数据
export async function getInteractionInfo(data) {
    return request(`/wqs/getInteractionInfo`, {
        method: "POST",
        data
    });
}

// 获取4个支流点监测点数据
export async function getTributaryWaterQuality(data) {
    return request(`/wqs/getTributaryWaterQuality`, {
        method: "get",
    });
}

// 钦寸(水量)
export async function getSpillway(query) {
    return request(`/bs/qcsk/spillway`, {
        method: "get",
        // noApi: true,
        params: query,
    });
}



// 获取水质交互数据新
export async function getInteractionInfo2(data) {
    return request(`/wqs/getInteractionInfoNew`, {
        method: "POST",
        data
    });
}