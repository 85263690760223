import React, { useEffect, useState } from "react";
import { getFlowByName } from "@/api/rainHs";
import { convert } from "@/utils/common";
import { settingData } from "@/store/raininfo";
import { useRecoilValue } from "recoil";
function WaterSupply(props) {
  const settingDataReal = useRecoilValue(settingData);
  const [dataNB, sethataNB] = useState([[], [], [], [], []]); // 里石门
  const getFlowByNameFun = (type) => {
    return getFlowByName({ name: type }).then((res) => {
      // 供水信息
      const results = res.data.results;
      const arr = []
      for (let i in results) {
        if (i != "ssll") {
          results[i] = String((results[i].val / 10000).toFixed(4)).split(".");
        } else if (i == "ssll") {
          // 瞬时供水
          results[i] = Math.ceil([results[i].val]);
        }
      }
      [`ssll`, `jrll`, `zrll`, `byll`, `qnll`].map(item => {
        arr.push(results[item])
      })
      sethataNB(arr);
    }).catch(err => {

    })
  };
  const to = (cb) => {
    return cb.then(data => [null, data]).catch(err => [err, null])
  }
  const allFun = async () => {
    const [err2, data2] = await to(getFlowByNameFun('waterSupplyNB'));
    setTimeout(() => {
      allFun()
    }, 300000); //   5分钟接口单独请求一次
  }
  useEffect(() => {
    // allFun()
  }, []);
  return (
    <div className="water-supply-wrap flex  flex-j-b flex-a-c ls-water-supply-wrap">
      <div className="tit-water-wrap">供水信息</div>
      <div className="water-supply-main-wrap water-supply-main-wrap__gh m-t-18 m-b-15 flex flex-j-b p-r-7">
        <div className="water-left-wrap m-r-7">
          <div className="watercategory-main-wrap-ls flex flex-j-c">
            <div className="wave-main-ls m-t-19">
              <div className="wave-ls">
                <div className="wave-tit-ls">
                  <div>{convert(settingDataReal.setting.waterQuality)}类</div>
                  <div className="fz-12 blod m-t-5">水质</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="water-right-wrap p-t-25">
          <table className="w100" style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th>瞬时供水量</th>
                <th>今日供水量</th>
                <th>昨日供水量</th>
                <th>当月累计供水量</th>
                <th>全年累计供水量</th>
                {/* <th>总供水量</th> */}
              </tr>
            </thead>
            <tbody>
              <tr >
                {dataNB.map((item, index) =>
                  <td key={index}>
                    <div className="m-b-7 pos-r">
                      <span className='num'>
                        {item.length ? (<><i>{item[0]}</i>.{item[1]}</>) : <i>0</i>}
                      </span>
                      <span className="unit">{index === 0 ? 'm³/h' : '万m³'}</span>
                    </div>
                  </td>)}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default WaterSupply;
