import React, { useState, useEffect } from "react";
import Tit from "@/components/tit";
import "./index.less";
function FloodWaterConfig(props) {
  const [data, setData] = useState(new Array(8).fill(8)); // 流量
  useEffect(() => {
    getDtuFun();
  }, []);
  const getDtuFun = () => {
    // getDtu().then((res) => {
    //   // 获取流量
    //   if (res.data.code == 0) {
    //     const data = res.data.results;
    //     if (data && data.status != 500) {
    //       try {
    //         for (let v in data) {
    //           if (
    //             v != "dateHour" &&
    //             typeof data[v] != "object" &&
    //             typeof data[v] == "number" &&
    //             data[v] != parseInt(data[v])
    //           ) {
    //             data[v] = String(data[v].toFixed(4)).split(".");
    //           }
    //         }
    //         setLiuliang(data);
    //       } catch (err) {
    //         console.log(err);
    //       }
    //     }
    //   }
    // });
  };
  return (
    <div className="floodWater-config-wrap m-t-10">
      <Tit tit="溢洪道流量">
        <div className="tit">
          <span>
            <i>0</i>
          </span>
          <span className="unit m-l-5">m³/h</span>
        </div>
      </Tit>
      <div className="m-t-10 flex flex-j-b">
        <ul className="flex flex-j-b floodWater-wrap-main-l">
          {data.length
            ? data.map((item, index) => {
                return (
                  <li key={index}>
                    <dt>{index}#弧门</dt>
                    <dd>
                      <span>
                        <i>0</i>
                      </span>
                      <span className="unit">m³/s</span>
                    </dd>
                    <dd>
                      <span>
                        <i>0</i>
                      </span>
                      <span className="unit">m</span>
                    </dd>
                  </li>
                );
              })
            : ""}
        </ul>
        <div className="floodWater-wrap-main-r">
          <div className="floodWater-wrap-main-r-c">
            <dt>泄洪洞</dt>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd>
          </div>
          <div className="floodWater-wrap-main-r-c">
            <dt>生态管</dt>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FloodWaterConfig;
