import React from "react";
import "./index.less";
const Tit = (props) => {
  return (
    <div className={`tit-wrap ${props.size}-tit flex flex flex-j-b`}>
      <div className="tit-l no-wrap">{props.tit}</div>
      <div className="tit-r flex flex-a-c">{props.children}</div>
    </div>
  );
};
export default Tit;
