import React, { useState, useEffect } from "react";
import Style from "./index.module.less";
import Power from "../../components/power";
import Spillway from "../../components/spillway";
import Wave from "../../components/wave";
import OutFlow from "../../components/outFlow";
import { keepTwoDecimalFull } from "@/utils/common";
function DamLeft(props) {
    const [rainInfoReal, setRainInfoReal] = useState({});
    const [waveStyle, setWaveStyle] = useState({});
    useEffect(() => {
        setRainInfoReal(props.reservoirInfo)
        setWaveStyle({
            lineWidth: `410`,
            waveTop: `${356.78 - 3.526 * (230 - props.reservoirInfo.val)}`,
            lineTop: `${3.392 * (230 - props.reservoirInfo.val) + 14.61}`,
        });
    }, [props])
    return (
        <div className={`${Style['DamLeft-wrapper']}`}>
            <div className={`${Style['tag-wrapper']}`}>
                <div className={`${Style['tag-bg']}`}></div>
                <div className={`${Style['tag-main']}`}>周公宅水库</div>
            </div>
            <div className={`${Style[`DamLeft-main`]}`}>
                {/*标题*/}
                <div className={`${Style[`dykeDam-wrap-hd`]} flex flex-j-c`}>
                    <div className={`${Style[`dykeDam-wrap-hd-item`]}`}>
                        <div className={`${Style['dykeDam-wrap-hd-item-h4']}`}>
                            <h4>当前库容</h4>
                        </div>
                        <div className={`${Style[`dykeDam-wrap-hd-item-num`]}`}>
                            <span>{keepTwoDecimalFull(rainInfoReal.sskr, 0)}</span>万m³
                        </div>
                    </div>
                    <div className={`${Style[`dykeDam-wrap-hd-item`]}`}>
                        <div className={`${Style[`dykeDam-wrap-hd-item-h4`]}`}>
                            <h4>总库容</h4>
                        </div>
                        <div className={`${Style[`dykeDam-wrap-hd-item-num`]}`}>
                            <span>{rainInfoReal.capacity || 0}</span>万m³
                        </div>
                    </div>
                    <div className={`${Style[`dykeDam-wrap-hd-item`]}`}>
                        <div className={`${Style[`dykeDam-wrap-hd-item-h4`]}`}>
                            <h4>正常库容</h4>
                        </div>
                        <div className={`${Style[`dykeDam-wrap-hd-item-num`]}`}>
                            <span>{keepTwoDecimalFull(rainInfoReal.zckr, 0)}</span>万m³
                        </div>
                    </div>
                </div>
                {/*标题End*/}
                {/*地图*/}
                <div className={`${Style['dykeDam-wrap-bd']} pos-r`}>
                    <div className={`${Style[`kedu`]}`}></div>
                    <div className={`${Style[`dykeDam-wrap-bd-img-wrapper`]}`}>
                        <div className={`${Style[`mark-line`]}`} style={{
                            width: `${waveStyle.lineWidth}px`,
                            top: `${Number(waveStyle.lineTop)}px`,
                        }}>
                            <span>当前水位：{rainInfoReal.val} m</span>
                        </div>
                        <div className={`${Style[`dykeDam-wrap-bd-img`]}`}></div>
                    </div>
                    <div className={`${Style[`wave-wrap`]}`} style={{ height: `${waveStyle.waveTop}px` }}>
                        <div className={`${Style[`wave-svg`]}`} >
                            <Wave />
                        </div>
                    </div>
                    <OutFlow typeName="zgzsk"></OutFlow>
                </div>
                {/*地图End*/}
            </div>
            <div className={`${Style[`DamLeft-footer`]} flex flex-j-c`}>
                <Power typeName="zgzsk" />
                <Spillway />
            </div>
        </div >
    );
}
export default DamLeft;
