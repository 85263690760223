import React, { useState, useEffect } from "react";
import { getDtu } from "@/api/rain";
import { keepTwoDecimalFull } from "@/utils/common";
function WaterSupply(props) {
  const [liuliang, setLiuliang] = useState({}); // 流量
  useEffect(() => {
    getDtuFun();
  }, []);
  const getDtuFun = () => {
    getDtu().then((res) => {
      // 获取流量
      if (res.data.code == 0) {
        const data = res.data.results;
        if (data && data.status != 500) {
          try {
            for (let v in data) {
              if (
                v != "dateHour" &&
                typeof data[v] != "object" &&
                typeof data[v] == "number"
              ) {
                if (
                  v == "totalFlowDaily" ||
                  v == "yesterdayTotalFlow" ||
                  v == "totalFlowYearly" ||
                  v == "totalFlowMonthly"
                ) {
                  data[v] = data[v] / 10000;
                }
                data[v] = keepTwoDecimalFull(data[v], 2).split(".");
              }
            }
            setLiuliang(data);
          } catch (err) {
            console.log(err);
          }
        }
      }
      setTimeout(() => {
        getDtuFun();
      }, 300000); //   5分钟接口单独请求一次
    });
  };

  return (
    <div className="water-supply-wrap flex  flex-j-b flex-a-c water-supply_xiayou-wrap ">
      <div className="tit-water-wrap">下游流量</div>
      <div className="water-supply-main-wrap  flex flex-j-b water-supply_xiayou-main-wrap">
        <div className="w100 p-l-20 p-r-20 p-t-25">
          <table className="w100">
            <thead>
              <tr>
                <th></th>
                <th>瞬时流量</th>
                <th>今日水量</th>
                <th>昨日水量</th>
                <th>当月累计水量</th>
                <th>全年累计水量</th>
                {/* <th>总供水量</th> */}
              </tr>
            </thead>
            <tbody>
              <tr >
                <td className="w40"></td>
                <td>
                  <span className='num'>
                    {liuliang.totalInstantaneousFlow &&
                      typeof liuliang.totalInstantaneousFlow == "object" ? (<><i>{liuliang.totalInstantaneousFlow[0]}</i></>) : <i>0</i>}
                  </span>
                  <span className="unit">m³/h</span>
                </td>
                <td>
                  <span className='num'>
                    {liuliang.totalFlowDaily &&
                      typeof liuliang.totalFlowDaily == "object" ? (<><i>{liuliang.totalFlowDaily[0]}</i>.{liuliang.totalFlowDaily[1]}</>) : <i>0</i>}
                  </span>
                  <span className="unit">万m³</span>
                </td>
                <td>
                  <span className='num'>
                    {liuliang.yesterdayTotalFlow &&
                      typeof liuliang.yesterdayTotalFlow == "object" ? (<><i>{liuliang.yesterdayTotalFlow[0]}</i>.
                        {liuliang.yesterdayTotalFlow[1]}</>) : <i>0</i>}
                  </span>
                  <span className="unit">万m³</span>
                </td>
                <td>
                  <span className='num'>
                    {liuliang.totalFlowMonthly &&
                      typeof liuliang.totalFlowMonthly == "object" ? (<><i>{liuliang.totalFlowMonthly[0]}</i>.{liuliang.totalFlowMonthly[1]}</>) : <i>0</i>}
                  </span>
                  <span className="unit">万m³</span>
                </td>
                <td>
                  <span className='num'>
                    {liuliang.totalFlowYearly &&
                      typeof liuliang.totalFlowYearly == "object" ? (<><i>{liuliang.totalFlowYearly[0]}</i>.{liuliang.totalFlowYearly[1]}</>) : <i>0</i>}
                  </span>
                  <span className="unit">万m³</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default WaterSupply;
