import React, { useEffect, useState } from "react";
import Tit from "@/components/tit";
import echarts from "echarts/lib/echarts";
import moment from "moment"; // 引入柱状图
import "echarts/lib/chart/bar"; // 引入提示框和标题组件
import "echarts/lib/component/markPoint";
import "echarts/lib/component/markLine";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import { keepTwoDecimalFull } from "@/utils/common";
import "./index.less";
import "../PowerAmount/index.less";
const _ = require('lodash');
function PowerHistory(props) {
    const [data, setData] = useState();
    const setChartFun = (data) => {
        const tms2 = [], minMaxRain = [];
        data.map((item) => {
            tms2.push(item.years);
            minMaxRain.push((item.value / 10000));
        });
        const maxLabeY = _.max(minMaxRain)
        minMaxRain.map((item, index) => {
            // 标记柱形最大值
            minMaxRain[index] = {
                value: Number(item) ? keepTwoDecimalFull(item, 2) : 0,
            };
            minMaxRain[index][`label`] = {
                show: true, //开启显示
                position: "top", //在上方显示
                textStyle: {
                    //数值样式
                    color: '#2bfc70',
                    fontSize: 10,
                    fontWeight: 700,
                },
            };
        });
        const myfChart = echarts.init(document.getElementById("PowerHistory-chart"));
        myfChart.setOption({
            title: { text: "" },
            grid: {
                left: "10%",
                top: 30,
                bottom: "10%",
                right: "5%",
            },
            tooltip: {
                trigger: "axis",
                formatter: function (params) {
                    return (
                        "时间：" +
                        params[0].name +
                        "<br>发电：" +
                        (isNaN(params[0].value)
                            ? "-"
                            : parseFloat(params[0].value).toFixed(1) + "万KW·h")
                    );
                },
            },

            xAxis: {
                nameTextStyle: {
                    color: "#6FD7E8",
                    fontSize: 12,
                    align: "middle",
                    verticalAlign: "bottom",
                    padding: [0, 0, 10, 0],
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: "#404f60",
                    },
                },
                data: tms2,
                position: "bottom",
                boundaryGap: ["20%", "20%"], //设置为true代表离零刻度间隔一段距离
                axisLabel: {
                    textStyle: {
                        color: "#68A8CC",
                        fontSize: 12,
                        margin: 10,
                    },

                    // formatter: (val) => {
                    //     return moment(val).format("MM-DD");
                    // },
                    alignWithLabel: true,
                },
                splitLine: {
                    show: false,
                },
            },
            yAxis: {
                name: "",
                nameLocation: "end",
                nameTextStyle: {
                    color: "#68A8CC",
                    fontSize: 12,
                    align: "middle",
                    verticalAlign: "bottom",
                    padding: [0, 30, 0, 0],
                },
                axisTick: {
                    show: false,
                },
                type: "value",
                inverse: false,
                min: 0,
                max: Math.ceil(maxLabeY),
                axisLabel: {
                    rotate: 0,
                    formatter: function (value, index) {
                        return value.toFixed(1);
                    },
                    textStyle: {
                        color: "#6FD7E8", //更改坐标轴文字颜色
                        fontSize: 10, //更改坐标轴文字大小
                    },
                },
                splitLine: { show: false },
                axisLine: {
                    lineStyle: {
                        color: "#404f60",
                    },
                },
            },
            series: [
                {
                    barWidth: 20, //柱图宽度
                    barGap: 30, //柱图间距
                    name: "销量",
                    type: "bar",
                    data: minMaxRain,
                    color: "#6AA9FC",
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1, //渐变色在下面修改，这里是透明度
                                [
                                    {
                                        offset: 0,
                                        color: "#67C7E8",
                                    },
                                    {
                                        offset: 1,
                                        color: "#051934",
                                    },
                                ]
                            ),
                        },
                    },
                    cursor: 'default' // 鼠标样式
                },
            ],
        });

        window.addEventListener("resize", () => {
            myfChart.resize();
        });
    }
    useEffect(() => {
        const dataCopy = {}
        const powerGenerationSum = props.powerGenerationSum
        if (!_.isEmpty(props.historyYearsPowerGenerationSum)) {
            setChartFun(props.historyYearsPowerGenerationSum)
        }
        if (!_.isEmpty(powerGenerationSum)) {
            for (let i in powerGenerationSum) {
                if (i == "powerGenerationSumAll" || i == "powerGenerationThisYear") {
                    if (powerGenerationSum[i] < 0) {
                        powerGenerationSum[i] = 0
                    }
                    dataCopy[i] = String((powerGenerationSum[i] / 10000).toFixed(2)).split(".");
                }
            }
            powerGenerationSum.EcologicalFlowList.map((item => {
                item[`flow`] = keepTwoDecimalFull((item[`generatorFlow`]) / 10000, 0)
            }))
            setData(dataCopy)
        }

    }, [props])
    return !props.loading && <div className="PowerHistory-wrap wrap PowerAmount-wrap">
        {/* <Tit tit="历史年份发电"></Tit> */}
        <div className="PowerAmount-hd">
            <div className="PowerAmount-hd_left PowerAmount-hd_item flex flex-a-c">
                <div className="PowerAmount-hd_item-logo left"></div>
                <div className="flex-1 m-l-15 flex flex-j-b flex-a-c">
                    <div className="item-desc fz-24">
                        总累计发电量
                    </div>
                    <div className="item-num fz-16">
                        {data && data[`powerGenerationSumAll`] ? (
                            <span>
                                <i className="fz-48">{data[`powerGenerationSumAll`][0]}</i>.{data[`powerGenerationSumAll`][1]}
                            </span>
                        ) : (
                            <span>
                                <i className="fz-48">0</i>
                            </span>
                        )}
                        <span className="fz-16 m-l-10">万kW·h</span>
                    </div>

                </div>
            </div>
        </div>
        <div className="PowerHistory-bd m-t-20">
            <div className="PowerHistory-chart" id="PowerHistory-chart"></div>
        </div>
    </div>
}
export default PowerHistory