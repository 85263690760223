import React, { useEffect, useState } from "react";
import { getWaterlevels } from "@/api/rainHs.js";
import { keepTwoDecimalFull } from "@/utils/common";
import Tit from "@/components/tit";
import './index.less';
const _ = require('lodash')
function StationHs() {
    const [list, setList] = useState([]); // 当前数据
    const getWaterlevelsFun = () => {
        getWaterlevels().then(res => {
            setList(res.data.results)
            setTimeout(() => {
                getWaterlevelsFun();
            }, 300000); //   5分钟接口单独请求一次
        }).catch(err => {
            console.log(err)
            setTimeout(() => {
                getWaterlevelsFun();
            }, 300000); //   5分钟接口单独请求一次
        })
    }
    useEffect(() => {
        getWaterlevelsFun()
    }, [])
    return <div className="StationHs-wrap m-t-20">
        <Tit tit="库中各测点水位信息" size="large"></Tit>
        <table className="m-t-20">
            <thead>
                <tr>
                    <th>测点名称</th>
                    <th>当前水位(m)</th>
                    <th>库容(万m³)</th>
                    <th>警戒水位(m)</th>
                </tr>
            </thead>
            <tbody>
                {list.map((item, index) => {
                    return <tr key={index}>
                        <td>{item.stnm}</td>
                        <td>{keepTwoDecimalFull(item.waterLevelText || 0, 2)}</td>
                        <td>{item.storage ? keepTwoDecimalFull(item.storage || 0, 0) : '-'}</td>
                        <td>{item.limitLevel ? keepTwoDecimalFull(item.limitLevel || 0, 2) : '-'}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>
}

export default StationHs;