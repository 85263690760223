import React, { useState, useEffect } from "react";
import { getDtu } from "@/api/rain";
import Tit from "@/components/tit";
import "./index.less";
function FloodWater(props) {
  const [liuliang, setLiuliang] = useState({}); // 流量
  useEffect(() => {
    getDtuFun();
  }, []);
  const getDtuFun = () => {
    getDtu().then((res) => {
      // 获取流量
      if (res.data.code == 0) {
        const data = res.data.results;
        if (data && data.status != 500) {
          try {
            for (let v in data) {
              if (
                v != "dateHour" &&
                typeof data[v] != "object" &&
                typeof data[v] == "number" &&
                data[v] != parseInt(data[v])
              ) {
                data[v] = String(data[v].toFixed(4)).split(".");
              }
            }
            setLiuliang(data);
          } catch (err) {
            console.log(err);
          }
        }
      }
    });
  };
  return (
    <div className="floodWater-wrap m-t-10">
      <Tit tit="溢洪道流量">
        <div className="tit">
          <span>
            <i>0</i>
          </span>
          <span className="unit m-l-5">m³/h</span>
        </div>
      </Tit>
      <div className="floodWater-wrap-main m-t-10">
        <ul className="flex flex-j-b">
          <li>
            <dt>1#弧门</dt>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd>
          </li>
          <li>
            <dt>2#弧门</dt>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd>
          </li>
          <li>
            <dt>3#弧门</dt>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd>
          </li>
          <li>
            <dt>4#弧门</dt>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd>
          </li>
          <li>
            <dt>5#弧门</dt>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default FloodWater;
