import React from "react";
function Wave(params) {
  return (
    <svg
      className="fill w100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 505 274"
    >
      <linearGradient id="orange_red" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="rgba(83, 177, 252, 1)" />
        <stop offset="100%" stopColor="rgba(3, 27, 59, 1)" />
      </linearGradient>
      <g
        id="页面-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          id="img_water_front"
          className="cls-1"
          d="M843.277,8.178a27.428,27.428,0,0,1-30.516,0c-16.4-11.558-33.791,0-33.791,0-13.067,8.537-27.354,0-27.354,0-16.174-11.558-28.991,0-28.991,0-8.9,6.37-20.686,2.387-26.1-.047-5.754-3.874-12.884-7.659-22.867-7.7a52.713,52.713,0,0,0-28,7.747,21.792,21.792,0,0,1-28.963,0c-18.976-13.554-33.513,0-33.513,0-15.924,10.533-34.1,0-34.1,0-18.494-12.036-33.79,0-33.79,0a27.428,27.428,0,0,1-30.516,0c-15.647-11.029-33.791,0-33.791,0-13.067,8.537-27.354,0-27.354,0-16.1-11.5-28.991,0-28.991,0C382.716,16.715,365.6,6.654,365.6,6.654,354.226,1.664,349.133,10.4,337,12s-18.965-6-30-6c-13.592,0-7.5,6.654-28,7-12.916.218-22.592-6.98-40-7-12.161-.014-20.279,6.835-34,7-9.584.115-22.026-7.019-32-7-11.312.022-21.656,5.868-33,6-10.7.125-23.677-6.972-34-7-12.476-.034-21.817,6.656-33,7-11.764.362-17.327-6.8-27-7-7.881-.165-16.948,6.261-23,7C10.677,13.5,1.635,10.109.006,10.286V432l106.32,0.275L843.237,55.909Z"
          transform="translate(0 -0.438)"
          fill="url(#orange_red)"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="translate"
            from="0"
            to="-80"
            dur="2s"
            repeatCount="indefinite"
          ></animateTransform>
        </path>
        <path
          id="img_water_front"
          className="cls-1"
          d="M843.277,8.178a27.428,27.428,0,0,1-30.516,0c-16.4-11.558-33.791,0-33.791,0-13.067,8.537-27.354,0-27.354,0-16.174-11.558-28.991,0-28.991,0-8.9,6.37-20.686,2.387-26.1-.047-5.754-3.874-12.884-7.659-22.867-7.7a52.713,52.713,0,0,0-28,7.747,21.792,21.792,0,0,1-28.963,0c-18.976-13.554-33.513,0-33.513,0-15.924,10.533-34.1,0-34.1,0-18.494-12.036-33.79,0-33.79,0a27.428,27.428,0,0,1-30.516,0c-15.647-11.029-33.791,0-33.791,0-13.067,8.537-27.354,0-27.354,0-16.1-11.5-28.991,0-28.991,0C382.716,16.715,365.6,6.654,365.6,6.654,354.226,1.664,349.133,10.4,337,12s-18.965-6-30-6c-13.592,0-7.5,6.654-28,7-12.916.218-22.592-6.98-40-7-12.161-.014-20.279,6.835-34,7-9.584.115-22.026-7.019-32-7-11.312.022-21.656,5.868-33,6-10.7.125-23.677-6.972-34-7-12.476-.034-21.817,6.656-33,7-11.764.362-17.327-6.8-27-7-7.881-.165-16.948,6.261-23,7C10.677,13.5,1.635,10.109.006,10.286V432l106.32,0.275L843.237,55.909Z"
          transform="translate(0 -0.438)"
          fill="url(#orange_red)"
        />
      </g>
    </svg>
  );
}
export default Wave;
