import React, { useEffect, useState } from "react";
import Style from "./index.module.less";
import Tit from "@/components/tit";
import { getImportanceTarget, getWaterQuality, getInteractionInfo2 } from "@/api/rainQc";
import { Chart } from '@antv/g2';
import * as echarts from 'echarts';
import { Carousel, Modal, Tabs } from 'antd';
import { configData } from "@/store/raininfo";
import { useRecoilValue } from "recoil";
// @c1 : #67cbac;

// @c2 : #ff8a3c;

// @c3 : #e95a54;
function setNumFun(v) {
    const arr = ["I", "II", "III", "IV", "V"]
    const colorList = ["#67cbac", "#ff8a3c", "#e95a54"]
    const num = arr.indexOf(v) + 1
    if (num < 3) {
        return colorList[0]
    } else if (num < 4) {
        return colorList[1]
    } else if (num == 5) {
        return colorList[2]
    }
}
function getColorFun(v) {
    const colorObj = {
        Red: '#e95a54',
        Green: '#67cbac',
        Orange: '#ff8a3c',
        White: '#FFF',
    }
    return colorObj[v]
}

function MainTarget() {
    const configDataReal = useRecoilValue(configData); // 大屏数据所有配置信息
    const [list, setList] = useState([])
    const [tableList, setTableList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modelTit, setModelTit] = useState('');
    const [tabList, setTabList] = useState([]) // tab
    const [tabData, setTabData] = useState(null) // tab
    /**
     * 获取重要指标信息 
     */
    const getImportanceTargetFun = () => {
        getImportanceTarget().then(res => {
            const result = res.data.results
            setList(result);
            setChartFun(result)
        })
    }
    const setChartFun = (result) => {
        if (!!result && result.length) {
            try {
                result.map((item, index) => {
                    const chart = new Chart({
                        container: `targetItemCircle_${index}`,
                        theme: 'classic',
                        gird: [0, 0, 0, 0],
                        autoFit: true,
                        width: 134,
                        height: 134,
                    });

                    chart
                        .data([
                            {
                                name: 'activity1',
                                percent: parseInt(item.percent) / 100,
                                color: setNumFun(item.overallCategory),
                                icon: 'https://gw.alipayobjects.com/zos/antfincdn/ck11Y6aRrz/shangjiantou.png',
                            }
                        ])
                        .coordinate({ type: 'radial', innerRadius: 0.7 });

                    chart
                        .interval()
                        .encode('x', 'name')
                        .encode('y', 1)
                        .encode('size', 52)
                        .encode('color', '#ffffff66')
                        .scale('color', { type: 'identity' })
                        .style('fillOpacity', 0.25)
                        .animate(false);
                    chart
                        .interval()
                        .encode('x', 'name')
                        .encode('y', 'percent')
                        .encode('color', 'color')
                        .encode('size', 52)
                        .style('radius', 26)
                        .style('shadowColor', 'rgba(0,0,0,0.45)')
                        .style('shadowBlur', 20)
                        .style('shadowOffsetX', -2)
                        .style('shadowOffsetY', -5)
                        .axis(false)
                        .animate('enter', {
                            type: 'waveIn',
                            easing: 'easing-out-bounce',
                            duration: 1000,
                        });

                    chart.render();

                })
            } catch (err) {
                console.log(err)
            }
        }
    }
    /**
     * 水质信息 
     */
    const getWaterQualityFun = () => {
        getWaterQuality().then(res => {
            const result = res.data.results
            setTableList(result);
        })
    }
    /**
   * 获取每月水质信息 
   */
    const getInteractionInfoFun = ({ index, listCopy }) => {
        setModelTit(listCopy[index][`target`])
        getInteractionInfo2({
            corpId: configDataReal.corpid,
            no: listCopy[index][`no`]
        }).then(res => {
            const results = res.data.results
            if (results) {
                const arr = Object.keys(results)
                setTabList(arr)
                setTabData(results)
            }
        })
    }
    /**
     *  切换年月日
     */
    const onChangeTabFun = (e) => {
        const xAxisData = []
        const seriesDta = []
        tabData[e].map((item) => {
            xAxisData.push(item.date)
            seriesDta.push(item.value)
        })
        setTimeout(() => initChart({ xAxisData, seriesDta, labelName: modelTit, results: tabData[e], e }), 1000)
    }
    /**
     *    画图
     */
    const initChart = ({ xAxisData = [], seriesDta = [], labelName = '', results = [], e }) => {
        var chartDom = document.getElementById(`WaterYearWrapId2${e}`);
        var myChart = echarts.init(chartDom);
        // myChart.on('click', (e) => {
        //     let handleIndex = Number(e.dataIndex); //索引下标
        //     targetFun({ index: handleIndex, month: xAxisData[handleIndex], level: seriesDta[handleIndex] })
        // })
        const option = {
            // 缩放平移组件
            // dataZoom: [
            //     {
            //         type: 'inside',  //slider有滑块，inside内置
            //         disabled: false,  //是否停止组件功能
            //         xAxisIndex: 0,  //x轴,可以用数组表示多个轴
            //         zoomLock: true,  //是否锁定区域大小（true,只能平移不能缩放）
            //         preventDefaultMouseMove: false,
            //         filterMode: "empty",
            //         startValue: xAxisData.length - 10,  //一行有几个（起始数组下标）
            //         endValue: xAxisData.length - 1,    //一行有几个（结束数组下标）
            //         start: null,
            //         end: null,
            //     },
            // ],
            grid: {
                left: "5%",
                top: "18%",
                bottom: 50,
                right: 0,
            },
            label: {
                show: true,
                formatter: '{c}',
                color: "#71e6fc",
                position: 'middle',/*****文字显示的位置**********/
                fontSize: 16,
            },
            tooltip: {
                trigger: "axis",
                formatter: function (params) {
                    return (
                        `${labelName}:` +
                        params[0].value +
                        "<br>时间：" +
                        params[0].name
                    );
                },
            },
            xAxis: {
                type: 'category',
                data: xAxisData,
                axisLabel: {
                    textStyle: {
                        color: "#71e6fc",
                        fontSize: 12,
                        margin: 10,
                    },
                    alignWithLabel: true,
                },
                axisTick: {
                    show: false // 不显示坐标轴刻度线
                },
                axisLine: { //坐标轴线
                    show: true,
                    lineStyle: {
                        color: '#404f60'
                    },
                },
            },
            yAxis: {
                type: 'value',
                // inverse: true,
                // inverse: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#404f60'
                    },
                },
                axisTick: {
                    show: false // 不显示坐标轴刻度线
                },
                axisLabel: {
                    textStyle: {
                        color: "#71e6fc",
                        fontSize: 12,
                        margin: 10,
                    },
                    // formatter: (val) => {
                    //     return reverseFun(val);
                    // },
                    alignWithLabel: true,
                },
                splitLine: { show: false },
            },
            series: [
                {
                    data: seriesDta,
                    type: 'line',
                    animation: true,
                    lineStyle: {
                        width: 2,
                        color: "#00D8FF", //改变曲线线颜色
                    },
                    symbol: 'circle',
                    symbolSize: 8,
                    itemStyle: {
                        color: (item) => {
                            return setNumFun(results[item.dataIndex].category)
                        },
                        // shadowColor: 'red',
                        // shadowBlur: 90,
                        // shadowOffsetX: 40,
                        // shadowOffsetY: 40
                    },

                    areaStyle: {
                        color: {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: "rgb(6,96,138)", // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: "rgb(8,35,69)", // 100% 处的颜色
                                },
                            ],
                        },
                    },
                }
            ]
        }
        option && myChart.setOption(option)
    }
    /**
     * 水质大屏弹窗
     */
    const targetFun = ({ index, listCopy }) => {
        setIsModalOpen(true);
        getInteractionInfoFun({ index, listCopy })
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        getImportanceTargetFun()
        getWaterQualityFun()
    }, [])
    useEffect(() => {
        if (tabData) {
            const arr = Object.keys(tabData)
            onChangeTabFun(arr[0])
        }
    }, [tabData])
    return <div className={`${Style.MainTargetWrap} flex flex-colum h100 ${Style.hidden}`}>
        <Tit tit="主要指标"></Tit>
        <div className={`${Style.MainTargetWrapMain} m-b-40`}>
            {list.map((item, index) => <div className={`${Style.targetItem}`} key={index} onClick={() => targetFun({ index, listCopy: list })}>
                <div className={`${Style.targetItemCircle} pos-r`} id={`targetItemCircle_${index}`}>
                    <div className={`${Style.valueLabel}`}>{item.value}</div>
                </div>
                <div className={`${Style.text} m-b-10`}>{item.target}</div>
                <div className={`${Style.text}`}>({item.categoryII})</div>
            </div>)}
        </div>
        <div className={`flex-1 ${Style.targetTableWrap} flex flex-colum`}>
            <div className={`h100 ${Style.hidden}`}>
                <div className={`${Style.targetTableMian} flex flex-colum`}>
                    <div className={`${Style.row} ${Style.header} w100 flex`}>
                        <div className={`${Style.column} ${Style.column1}`}>序号</div>
                        <div className={`${Style.column} ${Style.column2}`}>指标类型</div>
                        <div className={`${Style.column} ${Style.column3}`}>实际值</div>
                        <div className={`${Style.column} ${Style.column4}`}>II类标准</div>
                    </div>
                    <div className={`${Style.body}`}>
                        <div className={`${Style.content}`}>
                            <Carousel autoplay={1000} slidesToShow={6} slidesToScroll={1} dotPosition="right" dots={false} swipeToSlide={true} pauseOnHover={false} vertical verticalSwiping draggable>
                                {tableList.map((item, index) => <div className={`${Style.row} flex ${Style[`row-${index % 2 == 0 ? 2 : ''}`]}`} key={index} onDoubleClick={() => targetFun({ index, listCopy: tableList })}>
                                    <div className={`${Style.column} ${Style.column1} fz-18`}>{index + 1}</div>
                                    <div className={`${Style.column} ${Style.column2} fz-18`}>{item.target}</div>
                                    <div className={`${Style.column} ${Style.column3} fz-18`} style={{ color: item.value == '-' ? '' : getColorFun(item.color) }}>{item.value == '-' ? '-' : item.value}</div>
                                    <div className={`${Style.column} ${Style.column4} fz-18`}>{item.category_II_value || ''}</div>
                                </div>)}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} bodyStyle={{ background: 'none' }} width={1563} height={960} footer={null} maskClosable closable={false} className={`water-modal-main`}>
            <div className={Style[`water-modal-wrapper`]}>
                <div className={Style[`water-modal-wrapper-main`]}>
                    <div className={Style[`water-modal-wrapper-main_tit`]}>
                        {modelTit}统计
                    </div>
                    <div className={Style[`water-modal-wrapper-main_bd`]}>
                        <Tabs
                            className="w100"
                            tabPosition={'left'}
                            tabBarStyle={{ color: '#fff' }}
                            onChange={(e) => {
                                onChangeTabFun(e)
                            }}
                            items={tabList.map((o, i) => {
                                return {
                                    label: o,
                                    key: o,
                                    forceRender: true,
                                    children: <div className={`${Style[`main_right`]} m-t-20`} key={i}>
                                        <div className={`${Style.targetTableWrap} flex flex-colum`}>
                                            <div className={`h100 flex flex-a-c flex-j-c`}>
                                                <div id={`WaterYearWrapId2${o}`} className={`${Style[`WaterYearWrapId2`]}`}></div>
                                            </div>
                                        </div>
                                    </div>,
                                };
                            })}
                        />

                    </div>
                </div>
            </div>
        </Modal >
    </div >
}
export default MainTarget;