import React, { useEffect, useState } from "react";
import { getDayRainInfo } from "@/api/rainHs.js";
import Tit from "@/components/tit";
import { keepTwoDecimalFull } from "@/utils/common";
import './index.less';
const _ = require('lodash')
function StationRainHs() {
    const [list, setList] = useState([]); // 当前数据
    const getDayRainInfoFun = () => {
        getDayRainInfo().then(res => {
            console.log(res.data.results)
            setList(res.data.results)
            setTimeout(() => {
                getDayRainInfoFun();
            }, 300000); //   5分钟接口单独请求一次
        }).catch(err => {
            setTimeout(() => {
                getDayRainInfoFun();
            }, 300000); //   5分钟接口单独请求一次
        })
    }
    useEffect(() => {
        getDayRainInfoFun()
    }, [])
    return <div className="StationHs-wrap m-t-20">
        <Tit tit="库中各测点雨量信息" size="large"></Tit>
        <table className="m-t-20">
            <thead>
                <tr>
                    <th>测点名称</th>
                    <th>当天(mm)</th>
                    <th>前1天(mm)</th>
                    <th>前3天(mm)</th>
                </tr>
            </thead>
            <tbody>
                {list.map((item, index) => {
                    return <tr key={index} style={{ backgroundColor: index == (list.length - 1) ? '#1D4886' : '' }}>
                        <td>{item.stnm}</td>
                        <td>{keepTwoDecimalFull(item[`0days`] || 0, 1)}</td>
                        <td>{keepTwoDecimalFull(item[`1days`] || 0, 1)}</td>
                        <td>{keepTwoDecimalFull(item[`3days`] || 0, 1)}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>
}

export default StationRainHs;