import React, { useState, useEffect } from "react";
import { getEcoFlow } from "@/api/rainHs";
function WaterSupply(props) {
    const [streamFlow, setStreamFlow] = useState([]);
    const getFlowByNameFun = () => {
        getEcoFlow().then((res) => {
            // 供水信息
            const results = res.data.results;
            const arr = []
            for (let i in results) {
                if (i != "ssll") {
                    results[i] = String((results[i].val / 10000).toFixed(4)).split(".");
                } else if (i == "ssll") {
                    // 瞬时供水
                    results[i] = Math.ceil([results[i].val]);
                }
            }
            [`ssll`, `jrll`, `zrll`, `byll`, `qnll`].map(item => {
                arr.push(results[item])
            })

            setStreamFlow(arr);
        }).catch(err => {
            console.log(err)
        })
    };
    useEffect(() => {
        getFlowByNameFun();
    }, []);
    return (
        <div className="water-supply-wrap flex  flex-j-b flex-a-c water-supply_xiayou-wrap">
            <div className="tit-water-wrap">生态流量</div>
            <div className="water-supply-main-wrap flex flex-j-b water-supply_xiayou-main-wrap">
                <div className="w100 p-t-25">
                    <table className="w100" style={{ tableLayout: 'fixed' }}>
                        <thead>
                            <tr>
                                <th>瞬时生态流量</th>
                                <th>今日生态水量</th>
                                <th>昨日生态水量</th>
                                <th>当月累计生态水量</th>
                                <th>全年累计生态水量</th>
                                {/* <th>总供水量</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                {streamFlow.map((item, index) =>
                                    <td key={index}>
                                        <div className=" m-b-7 pos-r">
                                            <span className='num'>
                                                {index == 0 ? <i>{item}</i> : item && item[0] ? (<><i>{item[0]}</i>.{item[1]}</>) : 0}
                                            </span>
                                            <span className="unit">{index === 0 ? 'm³/s' : '万m³'}</span>
                                        </div>
                                    </td>)}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    );
}

export default WaterSupply;
