import Tit from "@/components/tit";
import React from "react";
import { useRecoilValue } from "recoil";
import { settingData } from "@/store/raininfo";
import { convert } from "@/utils/common";
import "./index.less";
function WaterCategory(params) {
  const settingDataReal = useRecoilValue(settingData);
  return (
    <div className="watercategory-wrap">
      <Tit tit="水质类别" size="small"></Tit>
      <div className="watercategory-main-wrap">
        <div className="wave-main m-t-19">
          <div className="wave">
            <div className="wave-tit">
              {convert(settingDataReal.setting.waterQuality)}类
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WaterCategory;
