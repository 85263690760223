import React from "react";
function Wave(params) {
  return (
    <svg
      className="fill"
      viewBox="0 0 1171 993"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="orange_red" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="rgba(83, 177, 252, 1)" />
        <stop offset="100%" stopColor="rgba(3, 27, 59, 1)" />
      </linearGradient>
      <g
        id="页面-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M1,51.2168663 C27.7905774,17.089099 57.1489488,0.0252153132 89.0751144,0.0252153132 C136.964363,0.0252153132 151.862225,33.8176445 192.641237,33.8176445 C233.42025,33.8176445 249.968314,0.0252153132 287.778075,0.0252153132 C325.587835,0.0252153132 349.846305,35.0001557 385.581277,33.8176445 C421.316249,32.6351333 434.56807,0.0252153132 477.389653,0.0252153132 C520.211236,0.0252153132 532.039541,33.8176445 577.149367,33.8176445 C622.259193,33.8176445 640.57975,-1.06907196 682.816063,0.0252153132 C725.052376,1.11950258 745.699008,33.8176445 786.228019,33.8176445 C826.75703,33.8176445 849.507791,0.0252153132 884.197173,0.0252153132 C918.886555,0.0252153132 942.094834,33.8176445 986.982847,33.8176445 C1031.87086,33.8176445 1047.28602,0.0252153132 1081.48706,0.0252153132 C1104.28776,0.0252153132 1134.1254,11.2893584 1171,33.8176445 L0,992.556541 L1,51.2168663 Z"
          id="直线-2"
          fill="url(#orange_red)"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="translate"
            from="0"
            to="-180"
            dur="3s"
            repeatCount="indefinite"
          ></animateTransform>
        </path>
        <path
          d="M1,51.2168663 C27.7905774,17.089099 57.1489488,0.0252153132 89.0751144,0.0252153132 C136.964363,0.0252153132 151.862225,33.8176445 192.641237,33.8176445 C233.42025,33.8176445 249.968314,0.0252153132 287.778075,0.0252153132 C325.587835,0.0252153132 349.846305,35.0001557 385.581277,33.8176445 C421.316249,32.6351333 434.56807,0.0252153132 477.389653,0.0252153132 C520.211236,0.0252153132 532.039541,33.8176445 577.149367,33.8176445 C622.259193,33.8176445 640.57975,-1.06907196 682.816063,0.0252153132 C725.052376,1.11950258 745.699008,33.8176445 786.228019,33.8176445 C826.75703,33.8176445 849.507791,0.0252153132 884.197173,0.0252153132 C918.886555,0.0252153132 942.094834,33.8176445 986.982847,33.8176445 C1031.87086,33.8176445 1047.28602,0.0252153132 1081.48706,0.0252153132 C1104.28776,0.0252153132 1134.1254,11.2893584 1171,33.8176445 L0,992.556541 L1,51.2168663 Z"
          id="直线-2"
          fill="url(#orange_red)"
        ></path>
      </g>
    </svg>
  );
}
export default Wave;
