import React from "react";
function Wave(params) {
  return (
    <svg
      className="fill w100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 505 423"
    >
      <linearGradient id="orange_red" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="rgba(83, 177, 252, 1)" />
        <stop offset="100%" stopColor="rgba(3, 27, 59, 1)" />
      </linearGradient>
      <g
        id="页面-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          id="img_water_front"
          className="cls-1"
          d="M506,423H0V2.958c0.94-.089,6.154,1.61,13.26.857,3.49-.37,8.718-3.584,13.263-3.5C32.1,0.415,35.312,6,42.1,5.815c6.449-.172,11.835-5.518,19.03-5.5,5.953,0.014,13.436,5.564,19.606,5.5,6.542-.066,12.507-4.99,19.03-5C105.514,0.8,114.473,6.058,120,6c7.912-.083,10.809-5.193,17.821-5.186C147.86,0.824,149.552,6.109,157,6c11.823-.173,9.12-5.186,18.034-5.186,8.209,0,16.049,8.1,21.966,2.186,2.792-2.916,14.074-2.971,19,0,0,0,8.121,5.271,15,1,0,0,5.966-5.878,15.293-.1,0,0,8.239,4.271,15.774,0,0,0,11.031-5.782,20.486,0C287.876,7,291.827,7,297.15,3.9c0,0,6.928-6.78,19.486,0,0,0,11.479,5.269,20.662,0,0,0,8.382-6.78,19.325,0C361.384,7.577,368.24,8.674,373,5c0,0,4.31-5,15-5a26.8,26.8,0,0,1,13.658,3.88c3.121,1.217,9.917,3.21,15.05.023,0,0,7.391-5.782,16.718,0,0,0,8.239,4.271,15.774,0,0,0,10.031-5.782,19.486,0a17.851,17.851,0,0,0,17.6,0s6.927-6.78,19.486,0Z"
          fill="url(#orange_red)"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="translate"
            from="0"
            to="-80"
            dur="2s"
            repeatCount="indefinite"
          ></animateTransform>
        </path>
        <path
          id="img_water_front"
          className="cls-1"
          d="M506,423H0V2.958c0.94-.089,6.154,1.61,13.26.857,3.49-.37,8.718-3.584,13.263-3.5C32.1,0.415,35.312,6,42.1,5.815c6.449-.172,11.835-5.518,19.03-5.5,5.953,0.014,13.436,5.564,19.606,5.5,6.542-.066,12.507-4.99,19.03-5C105.514,0.8,114.473,6.058,120,6c7.912-.083,10.809-5.193,17.821-5.186C147.86,0.824,149.552,6.109,157,6c11.823-.173,9.12-5.186,18.034-5.186,8.209,0,16.049,8.1,21.966,2.186,2.792-2.916,14.074-2.971,19,0,0,0,8.121,5.271,15,1,0,0,5.966-5.878,15.293-.1,0,0,8.239,4.271,15.774,0,0,0,11.031-5.782,20.486,0C287.876,7,291.827,7,297.15,3.9c0,0,6.928-6.78,19.486,0,0,0,11.479,5.269,20.662,0,0,0,8.382-6.78,19.325,0C361.384,7.577,368.24,8.674,373,5c0,0,4.31-5,15-5a26.8,26.8,0,0,1,13.658,3.88c3.121,1.217,9.917,3.21,15.05.023,0,0,7.391-5.782,16.718,0,0,0,8.239,4.271,15.774,0,0,0,10.031-5.782,19.486,0a17.851,17.851,0,0,0,17.6,0s6.927-6.78,19.486,0Z"
          fill="url(#orange_red)"
        />
      </g>
    </svg>
  );
}
export default Wave;
