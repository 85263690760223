import { atom } from "recoil";
import dataMap from "@/data";
// 数据大屏配置信息
export const settingData = atom({
  key: "settingData",
  default: null,
});

// 数据大屏所有配置信息
export const configData = atom({
  key: "configData",
  default: null,
});

// webscocket连接信号
export const webscocketStatus = atom({
  key: "webscocketStatus",
  default: false,
});

// 水雨信息
export const rainInfo = atom({
  key: "rainInfo",
  default: {},
});

// 水库应急等级
export const waterLevel = atom({
  key: "waterLevel",
  default: {},
});

// 登录状态
export const isLogin = atom({
  key: "isLogin",
  default: false,
});

// 展示菜单栏
export const showMenuVisible = atom({
  key: "showMenuVisible",
  default: false,
});

// 展示地图还是堤坝  1.大坝 2.地图
export const mapModel = atom({
  key: "mapModel",
  default: null,
});

// 头部展示 常规1 党建2
export const titModel = atom({
  key: "titModel",
  default: 1,
});

// 显示视频
export const showVideo = atom({
  key: "showVideo",
  default: false,
});

// 显示视频对象
export const videoConfig = atom({
  key: "videoConfig",
  default: {
    source: "",
  },
});

// 显示图片
export const showImage = atom({
  key: "showImage",
  default: false,
});

// 显示图片对象
export const imageConfig = atom({
  key: "imageConfig",
  default: {
    source: "",
  },
});



// 横山特供下泄总流量

export const nowFlow = atom({
  key: "nowFlow",
  default: []
});



// 溢洪道 + 放空洞

export const gateTunne = atom({
  key: "gateTunne",
  default: {
    gateList: [], // 溢洪道
    tunnelList: [],// 泄洪洞放空洞信息
    amount: 0
  }
});