import { GetRequest } from "@/utils/common";
import { message } from "antd";
const CHANNELID = GetRequest().channelid;

const CONFIG = {
  ID: 3171, // 水库id
  CORPID: 3310230001, // 里石门
  CHANNELID: CHANNELID,
  ERRORINFO: {
    500: "服务器出错",
    504: "网关超时",
  },
  STATIONS: require("./stations.js"),
  SOCKETHOST: "lsapi.app.19930322.com",
  IFRAMEHOST:
    process.env.NODE_ENV == "development"
      ? "localhost:8081"
      : "lsc.app.19930322.com",
};
export default CONFIG;
