import React from "react";
import { useRecoilValue } from "recoil";
import { settingData } from "@/store/raininfo";
import { convert } from "@/utils/common";
import "./index.less";
function WaterCategory(params) {
  const settingDataReal = useRecoilValue(settingData);
  return (
    <div className="watercategoryMin-wrap">
      <div className="watercategory-main-wrap h100">
        <div className="wave-main m-t-19">
          <div className="wave">
            <div className="wave-tit">
              <div className="wave-tit-class">
                {convert(settingDataReal.setting.waterQuality)}类
              </div>
              <div className="wave-tit-desc m-t-6">水质</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WaterCategory;
