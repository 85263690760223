import React, { useState, useEffect } from "react";
import "./index.less";
import { getWaterSupply, getStationRain, getSwitch, getDownstreamWater } from "@/api/rain";
import { addPos, mapTag } from "./common"; // 组件对应方法
import Wave from "./wave"; //浪花
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
  rainInfo,
  mapModel,
  titModel,
  showVideo,
  videoConfig,
  showImage,
  imageConfig,
  webscocketStatus,
  settingData,
  configData,
} from "@/store/raininfo";
import WaterSupply from "./components/waterSupply";
import Xiayou from "./components/xiayou";
import WebsocketHeartbeatJs from "@/utils/websocket-heartbeat-js/lib";
import { GetRequest } from "@/utils/common";
import { message, Button } from "antd";
import CONFIG from "@/config";
let showTypeIndex = 0; // 展示数组下标
let showTypeArr = []; // 轮播展示的数组
let setIntervalObj = null; //定时器
const key = "updatable";
const _ = require("lodash");
function XxMapConfig(peops) {
  const [stationRain, setStationRain] = useState([]);
  const [settingDataReal, setSettingDataReal] = useRecoilState(settingData); // 配置信息
  const [showType, setShowType] = useRecoilState(mapModel); // 1.显示大坝 2.显示地图  3.欢迎界面
  // const [showTypeArr, setShowTypeArr] = useRecoilState(mapModelArr); // 显示界面数组
  const setWebscocketStatusType = useSetRecoilState(webscocketStatus); // 信号Status
  const [titType, setTitType] = useRecoilState(titModel); // 2.党建 1.常规
  const [loading, setLoading] = useState(false); // 控制区域
  const rainInfoReal = useRecoilValue(rainInfo);
  const [waveStyle, setWaveStyle] = useState({});
  const setIsModalVisible = useSetRecoilState(showVideo); // 展示视频推送
  const setShowVideoConfig = useSetRecoilState(videoConfig); // 展示视频
  const setShowImage = useSetRecoilState(showImage); // 展示图片
  const setShowImageConfig = useSetRecoilState(imageConfig); // 展示图片地址
  const configDataReal = useRecoilValue(configData); // 大屏数据所有配置信息
  const [waveWaterInfo, setWaveWaterInfo] = useState({
    water: 0,
    flow: 0
  })
  /**
   * 组合渲染
   */
  const showTypeFun = (data) => {
    showTypeArr = [...JSON.parse(data.switchArr)]
    if (!!data.config) {
      const config = JSON.parse(data.config)
      const arr = config.list.filter(item => config.checkList.includes(item.id))
      showTypeArr = [...showTypeArr, ...arr]
      console.log('config==>', config)
    }
  }/**
   *  获取溪下流量
   */
  const getDownstreamWaterFun = () => {
    getDownstreamWater().then(res => {
      setWaveWaterInfo(res.data.results)
    })
    setTimeout(() => {
      getDownstreamWaterFun()
    }, 30000)
  }
  useEffect(() => {
    getDownstreamWaterFun()
    // getWaterSupply().then((res) => {
    //   // 供水信息
    //   const obj = {};
    //   res.data.results.map((item, index) => {
    //     if (item.id != "NBYS_64727630888_V101") {
    //       obj[item.id] = String(item.val / 10000).split(".");
    //     } else {
    //       obj[item.id] = [item.val];
    //     }
    //   });
    //   setData(obj);
    // });
    getStationRain().then((res) => {
      // 站点信息
      let showTypeNum = 0;
      res.data.results.map((item) => {
        // 增加坐标系
        addPos(item);
      });
      const qyStation = res.data.results.filter((item) => {
        //  过滤对应站点
        showTypeNum += item.val;
        return typeof item.pos == "object";
      });
      setShowType(2)
      // getSwitch().then((res2) => {
      //   // 获取配置
      //   const response_data = res2.data.results;
      //   if (response_data.switchArr) {
      //     showTypeFun(response_data);
      //     setShowType(showTypeArr[0]);
      //   } else {
      //     setShowType(showTypeNum / qyStation.length < 5);
      //   }
      //   setTitType(response_data.title);
      //   if (showTypeArr.length > 1 && response_data.interval) {
      //     setIntervalObj = setInterval(() => {
      //       showTypeIndex =
      //         showTypeIndex < showTypeArr.length - 1 ? showTypeIndex + 1 : 0;
      //       setShowType(showTypeArr[showTypeIndex]);
      //     }, response_data.interval * 1000);
      //   }
      // });
      setStationRain(qyStation);
      setLoading(true);
    });
  }, []);
  useEffect(() => {
    setWaveStyle({
      // lineWidth: `${424.085 - 6.43 * (103 - rainInfoReal.val)}`,
      waveTop: `${14.97388 * (60 - rainInfoReal.val) + 571.839}`,
      lineTop: `${7.719 * (60 - rainInfoReal.val) + 63.979}`,
    });
  }, [rainInfoReal]);
  // useEffect(() => {
  //   const queryArr = GetRequest();
  //   if (!(queryArr && queryArr.channelid)) {
  //     message.warning("通讯连接失败,地址栏请填写通道channelid");
  //     return;
  //   }
  //   let websocketHeartbeatJs = new WebsocketHeartbeatJs({
  //     url: `wss://${CONFIG.SOCKETHOST}/websocket/${queryArr.channelid}`,
  //     reconnectTimeout: 2000,
  //     pongTimeout: 10000,
  //   });
  //   websocketHeartbeatJs.onopen = function () {
  //     setWebscocketStatusType(true);
  //     // message.success({ content: "远程链接连成功", key, duration: 2 });
  //   };
  //   websocketHeartbeatJs.onmessage = function (e) {
  //     if (e.data && e.data.indexOf("Channel controll connected") != -1) {
  //       return;
  //     }
  //     if (e.data && JSON.parse(e.data).message) {
  //       // 防止message丢失报错
  //       const res2 = JSON.parse(e.data).message;
  //       if (typeof res2 === "string") {
  //         return;
  //       }
  //       if (res2.showVideo) {
  //         // 视频播放指令
  //         setShowImage(false);
  //         setIsModalVisible(res2.videoShowType);
  //         setShowVideoConfig({
  //           source: res2.videoSource,
  //         });
  //       } else if (res2.showImage) {
  //         // 图片指令
  //         setIsModalVisible(false);
  //         setShowImage(res2.imageShowType);
  //         setShowImageConfig({
  //           source: res2.imageSource,
  //         });
  //       } else if (res2.showSetting) {
  //         // 水质变化
  //         const set = _.cloneDeep(settingDataReal);
  //         set[`setting`][`waterLevel`] = res2.waterLevel;
  //         set[`setting`][`waterQuality`] = res2.waterQuality;
  //         if (res2.valve) {
  //           set[`setting`][`valve`] = res2.valve;
  //         }
  //         setSettingDataReal(set);
  //       } else {
  //         // 大屏界面设置指令
  //         window.clearTimeout(setIntervalObj); //取消定时器
  //         showTypeIndex = 0;
  //         showTypeFun(res2);
  //         setShowType(showTypeArr[0]);
  //         setTitType(res2.title); // 切换头部
  //         if (showTypeArr.length > 1 && res2.interval) {
  //           setIntervalObj = setInterval(() => {
  //             showTypeIndex =
  //               showTypeIndex < showTypeArr.length - 1 ? showTypeIndex + 1 : 0;
  //             setShowType(showTypeArr[showTypeIndex]);
  //           }, res2.interval * 1000);
  //         }
  //       }
  //     }
  //   };
  //   websocketHeartbeatJs.onreconnect = function () {
  //     setWebscocketStatusType(false);
  //     // message.loading({ content: "远程链接连接中", key });
  //   };
  // }, []);
  const DrawCyline = (props) => {
    return props.stationRain.map((item, index) => {
      return (
        item.pos && (
          <div
            className={`tips`}
            key={index}
            style={{ left: `${item.pos.x}px`, bottom: `${item.pos.y}px` }}
          >
            <div className="tis-name">{item.stnm}</div>
            <div
              className="tips-foot"
              style={{ borderColor: item.theme.borderColor }}
            >
              <div
                className="tips-foot-before"
                style={{ backgroundColor: item.theme.borderColor }}
              ></div>
              <div
                className="tips-foot-c"
                style={{ borderColor: item.theme.borderColor }}
              >
                <div
                  className="tips-foot-c-c"
                  style={{ backgroundColor: item.theme.borderColor }}
                >
                  {item.val ? (
                    <div
                      className="tips-foot-cylinder"
                      style={{
                        borderColor: item.theme.borderColor,
                        backgroundImage: item.theme.fillColor,
                        height: `${item.theme.height}px`,
                      }}
                    >
                      <div
                        className={`tips-foot-cylinder-val tips-foot-cylinder-val-${item.pos.tpos}`}
                        style={{
                          borderColor: item.theme.borderColor,
                          background: item.theme.txtFillColor,
                        }}
                      >
                        {item.val.toFixed(1)}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      );
    });
  };
  const CreatTag = (props) => {
    // 创建标签1
    return props.arr.map((item, index) => {
      const bgColor = props.type === 1 ? item.color : "none";
      return (
        <li
          className={props.type === 1 ? "tag-l-li" : "tag-r-li"}
          style={{ background: bgColor }}
          key={index}
        >
          {props.type === 1 ? "" : item.size}
        </li>
      );
    });
  };
  /**
  * 针对手机端大屏切换项目 
  */
  const changeSliderFun = () => {
    setShowType(showType === 2 ? 1 : 2)
  }
  return (
    <div className="xx-map-main-wrap">
      <div className="map-wrap m-t-16">
        <div className="show-child-wrap m-lr-auto pos-r">
          <Button className="changeSliderBtn" onClick={changeSliderFun}>切换</Button>
          {/* 大坝界面 */}
          <div
            className={`dykeDam-wrap ${showType === 1 ? "show_map" : "hidden_map"
              }`}
            style={{ paddingTop: 0 }}
          >
            {/*标题*/}
            <div className="dykeDam-wrap-hd flex flex-j-c">
              <div className="dykeDam-wrap-hd-item">
                <div className="dykeDam-wrap-hd-item-h4">
                  <h4>当前库容</h4>
                </div>
                <div className="dykeDam-wrap-hd-item-num">
                  <span>{rainInfoReal.sskr}</span>万m³
                </div>
              </div>
              <div className="dykeDam-wrap-hd-item">
                <div className="dykeDam-wrap-hd-item-h4">
                  <h4>总库容</h4>
                </div>
                <div className="dykeDam-wrap-hd-item-num">
                  <span>{configDataReal.config.capacity}</span>万m³
                </div>
              </div>
              <div className="dykeDam-wrap-hd-item">
                <div className="dykeDam-wrap-hd-item-h4">
                  <h4>正常库容</h4>
                </div>
                <div className="dykeDam-wrap-hd-item-num">
                  <span>{configDataReal.config.normalCapacity}</span>万m³
                </div>
              </div>
            </div>
            {/*标题End*/}
            <div className="dykeDam-wrap-bd m-lr-auto m-b-34">
              <div className="kedu"></div>
              <div className="dykeDam-wrap-bd-img"></div>
              <div
                className="mark-line"
                style={{
                  width: `505px`,
                  top: `${Number(waveStyle.lineTop)}px`,
                }}
              >
                <span>当前水位：{rainInfoReal.val} m</span>
              </div>
              <div className={`wave-water ${waveWaterInfo.flow == 0 && 'wave-water-stop'}`}>
                <div className="wave-water-num">{waveWaterInfo.water}<span class="unit m-l-5">m</span></div>
              </div>
              <div className="wave-wrap">
                <div
                  className="wave-svg"
                  style={{ height: `${waveStyle.waveTop}px` }}
                >
                  <Wave></Wave>
                </div>
              </div>
            </div>
          </div>
          {/* 地图界面 */}
          <div
            className={`map-f m-lr-auto  map-child-wrap ${showType === 2 ? "show_map" : "hidden_map"
              }`}
          >
            <div className="map">
              <DrawCyline stationRain={stationRain} />
              <div className="tag-wrap ">
                <div className="tag-num">mm</div>
                <div className="flex">
                  <ul className="tag-wrap-l">
                    <CreatTag arr={mapTag} type={1} />
                  </ul>
                  <ul className="tag-wrap-r m-l-9">
                    <CreatTag arr={mapTag} type={2} />
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* 欢迎界面 */}
          <div
            className={`map-f m-lr-auto  huanying-child-wrap ${showType === 3 ? "show_map" : "hidden_map"
              }`}
          ></div>
          {/* 用户图片 */}
          <div
            className={`huanying-child-wrap-img ${![1, 2, 3].includes(showType) && typeof showType === 'string' ? "" : "hidden"
              }`}
          >
            {/* <Image
              width={'100%'}
              src={showType}
              preview={false}
            // onError={() => {
            //   message.error('图片加载失败')
            // }}
            /> */}
            <img src={typeof showType === 'string' ? showType : ''} />
          </div>
          {/* 用户图片加背景墙 */}
          <div
            className={`huanying-child-wrap-img ${![1, 2, 3].includes(showType) && !_.isNull(showType) && typeof showType !== 'string' ? "" : "hidden"
              }`}
          >
            {
              !_.isNull(showType) && (typeof showType === 'string' ? <img src={typeof showType === 'string' ? showType : ''} /> : <div className="slider-bg flex flex-a-c flex-j-c" style={{
                backgroundImage: `${!!showType.bgFile ? `url(${encodeURI(showType.bgFile[0].url)})` : ''}`
              }}>
                {showType.checked == 1 ? <div className="slider-list-wrap flex flex-a-c flex-j-c" style={{ backgroundImage: `${showType.imgFile.length ? `url(${encodeURI(showType.imgFile[0].url)})` : ''}` }}>
                </div> : <div className="slider-list-tit">{showType.text}</div>}
              </div>)
            }

          </div>
        </div>
        <div className="m-lr-auto water-info-wrap">
          <WaterSupply />
          <Xiayou></Xiayou>
        </div>
      </div>
    </div>
  );
}

export default XxMapConfig;
