import React, { useState, useEffect } from "react";
import { getWaterSupply } from "@/api/union";
import Tit from "@/components/tit";
import { keepTwoDecimalFull } from "@/utils/common";
import Style from "./index.module.less";
const _ = require("lodash");
function Downstream(props) {
    const [spillwayTunnel, setSpillwayTunnel] = useState([]); // 供水
    const [amount, setAmount] = useState(0);
    /**
     * 总出库 
     */
    const getWaterSupplyFun = () => {
        getWaterSupply().then(res => {
            setSpillwayTunnel([{
                flow: res.data.results
            }])
            setAmount(res.data.results)
        }).catch(err => {
            console.log(err)
        })
        setTimeout(() => {
            getWaterSupplyFun();
        }, 300000); //   5分钟接口单独请求一次
    }
    useEffect(() => {
        getWaterSupplyFun();
    }, []);
    return (
        <div className={`${Style[`supplyWater-wrap`]} ${Style[`${props.typeName}`]}`}>
            <Tit tit='供水' >
                <div className="tit">
                    <span>
                        <i>{keepTwoDecimalFull(amount || 0, 1)}</i>
                    </span>
                    <span className="unit m-l-5">m³/s</span>
                </div>
            </Tit>
            <ul className={`${Style[`fenche`]}`}>
                {/*泄洪洞*/}
                {spillwayTunnel.map((item, index) => {
                    return (
                        <li className={`${Style[`fenche-li`]} flex flex-j-c`} key={index}>
                            <div className={`${Style[`fenche-li-main`]} `}>
                                <div className={`${Style[`fenche-li-hd`]} fz-12 theme-bg-color`} style={{ opacity: 0 }}>
                                    {keepTwoDecimalFull(item.generatedOutput || 0, 0)}
                                    <span className="m-l-3">Kw</span>
                                </div>
                                <div className={`${Style[`fenche-li-bd`]}`}>
                                    {!item.flow ? (
                                        <>
                                            <div
                                                className={`${Style[`op-7`]} ${Style[`op-7-${item.type}`]}`}
                                                style={{ opacity: item.type == 1 ? 0.6 : 1 }}
                                            >
                                                <span className={`${Style[`fenche-desc`]} fz-14`}>供水</span>
                                            </div>
                                            <span className={`${Style[`top-icon`]}`}></span>
                                        </>
                                    ) : (
                                        <div className={`h100 ${Style[`fenche-li-img`]}`}>
                                            <div className={`${Style[`fenche-li-img-gif`]}`}></div>
                                            <div className={`${Style[`fenche-li-desc`]} fz-14`}>供水</div>
                                        </div>
                                    )}
                                </div>
                                <div className={`${Style[`fenche-li-ft`]} fz-12 theme-bg-color`}>
                                    {keepTwoDecimalFull(item.flow || 0, 1)}
                                    <span className="m-l-3">m³/s</span>
                                </div>
                            </div>
                        </li>
                    );
                })
                }
            </ul>
        </div >
    );
}

export default Downstream;
