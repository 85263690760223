import React, { useState, useEffect } from "react";
import { getOutFlow, getWaterSupply } from "@/api/union";
import { keepTwoDecimalFull } from "@/utils/common";
import Style from "./index.module.less";
const _ = require("lodash");
function Downstream(props) {
    const [outFlow, setOutFlow] = useState(0); // 总出库
    /**
     * 总出库 
     */
    const getOutFlowFun = () => {
        getOutFlow().then(res => {
            let results = res.data.results[`${props.typeName}`]
            // if (props.typeName == 'jksk') {
            //     getWaterSupply().then(res1 => {
            //         setOutFlow(results - res1.data.results)
            //     })
            // } else {
            setOutFlow(results)
            // }
        }).catch(err => {
            console.log(err)
        })
        setTimeout(() => {
            getOutFlowFun();
        }, 300000); //   5分钟接口单独请求一次
    }
    useEffect(() => {
        getOutFlowFun();
    }, []);
    return (
        <div className={`${Style[`outwrap-wrap`]} ${Style[`${props.typeName}`]}`}>
            <div className={`${Style[`outwrap-wrap-hd`]}`}>
                总出库{props.typeName == 'jksk' ? '(下游)' : ''}
            </div>
            <div className={`${Style[`outwrap-wrap-bd`]}`}>
                {keepTwoDecimalFull(outFlow, 1)}<span className={`${Style[`unit`]}`}>m³/s</span>
            </div>
            <div className={`${Style[`outwrap-wrap-foot`]}`}>

            </div>
        </div >
    );
}

export default Downstream;
