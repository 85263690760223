import React, { useState, useEffect } from "react";
import { getFlowByName } from "@/api/rain";

function WaterSupply(props) {
  const [data, setData] = useState({});
  useEffect(() => {
    // getFlowByNameFun();
  }, []);
  const getFlowByNameFun = () => {
    getFlowByName({ name: "downstreamFlow" }).then((res) => {
      const results = res.data.results;
      for (let i in results) {
        if (i != "ssll") {
          results[i] = String((results[i].val / 10000).toFixed(4)).split(".");
        } else if (i == "ssll") {
          // 瞬时供水 m/s
          results[i] = results[i].val * 3600;
        }
      }
      setData(results);
    });
  };

  return (
    <div className="water-supply-wrap flex  flex-j-b flex-a-c">
      <div className="tit-water-wrap">下游流量</div>
      <div className="water-supply-main-wrap  flex flex-j-b">
        <dl>
          <dt>瞬时流量</dt>
          <dd>
            {data && data[`ssll`] ? <><span>
              <i>{data && data[`ssll`] ? data[`ssll`] : ' 0'}</i>
            </span>
              <span className="unit">m³/h</span></> : <span><i>-</i></span>}
          </dd>
        </dl>
        <dl>
          <dt>今日水量</dt>
          <dd>
            {data && data[`jrll`] ? (
              <>
                <span>
                  <i>{data[`jrll`][0]}</i>.{data[`jrll`][1]}
                </span>
                <span className="unit">万m³</span>
              </>
            ) : (
              <span>
                <i>-</i>
              </span>
            )}
          </dd>
        </dl>
        <dl>
          <dt>昨日水量</dt>
          <dd>
            {data && data[`zrll`] ? (
              <>
                <span>
                  <i>{data[`zrll`][0]}</i>.{data[`zrll`][1]}
                </span>
                <span className="unit">万m³</span>
              </>
            ) : (
              <span>
                <i>-</i>
              </span>
            )}
          </dd>
        </dl>
        <dl>
          <dt>当月累计水量</dt>
          <dd>
            {data && data[`byll`] ? (
              <>
                <span>
                  <i>{data[`byll`][0]}</i>.{data[`byll`][1]}
                </span>
                <span className="unit">万m³</span>
              </>
            ) : (
              <span>
                <i>-</i>
              </span>
            )}
          </dd>
        </dl>
        <dl>
          <dt>全年累计水量</dt>
          <dd>
            {data && data[`qnll`] ? (
              <><span>
                <i>{data[`qnll`][0]}</i>.{data[`qnll`][1]}
              </span>
                <span className="unit">万m³</span></>
            ) : (
              <span>
                <i>-</i>
              </span>
            )}
          </dd>
        </dl>
      </div>
    </div>
  );
}

export default WaterSupply;
