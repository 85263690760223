import React, { useEffect, useState } from 'react';
import Style from './index.module.less';
import Tit from "@/components/tit";
import { getOverallCategory, getInteractionInfo } from "@/api/rainQc";
import { convert } from "@/utils/common";
import { Modal, Carousel } from 'antd';
import { configData } from "@/store/raininfo";
import { useRecoilValue } from "recoil";
import moment from 'moment';
import _ from "lodash";
function setNumFun(num) {
    const colorList = [1, 2, 3]
    if (num < 3) {
        return colorList[0]
    } else if (num < 4) {
        return colorList[1]
    } else if (num == 5) {
        return colorList[2]
    }
    return num
}
function getColorFun(v) {
    const colorObj = {
        Red: '#e95a54',
        Green: '#67cbac',
        Orange: '#ff8a3c',
        White: '#FFF',
    }
    return colorObj[v]
}
// function setNumTableFun(v) {
//     const arr = ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ']
//     const colorList = ["#67cbac", "#ff8a3c", "#e95a54"]
//     const num = arr.indexOf(v) + 1
//     if (num < 3) {
//         return colorList[0]
//     } else if (num < 4) {
//         return colorList[1]
//     } else if (num == 5) {
//         return colorList[2]
//     }
// }
function WaterLevel() {
    const configDataReal = useRecoilValue(configData); // 大屏数据所有配置信息
    const [level, setLevel] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modelTit, setModelTit] = useState('');
    const [modelLevel, setModelLevel] = useState({});
    const [tableList, setTableList] = useState([])

    /**
     * // 获取最新的总水质指标
     */
    const getOverallCategoryFun = () => {
        getOverallCategory().then(res => {
            const results = res.data.results
            results.overallCategoryNumCopy = results.overallCategoryNum
            results.overallCategoryNum = setNumFun(results.overallCategoryNum)
            setLevel(results)
        })
    }
    /**
    * 水质大屏弹窗
    */
    const targetFun = () => {
        const month = moment().format('YYYY-MM')
        setIsModalOpen(true);
        getInteractionInfoFun({ month })
    }
    /**
     * 获取每月水质信息 
     */
    const getInteractionInfoFun = ({ month }) => {
        getInteractionInfo({
            corpId: configDataReal.corpid,
        }).then(res => {
            const result = res.data.results
            setTableList(result);
        })
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        getOverallCategoryFun()
    }, [])
    return <div className={`${Style.WaterLevelWrapper} flex flex-colum m-l-44`}>
        <Tit tit="当前水质类别"></Tit>
        {!_.isEmpty(level) && <div className={`flex-1 w100 flex flex-a-c flex-j-c`} onClick={targetFun}>
            <div className={`${Style.waterLamp} ${Style[`waterLamp-${level.overallCategoryNum}`]}`}>
                <div className={`${Style.waterLampMain} ${Style[`waterLampMain-${level.overallCategoryNum}`]}`}>
                    <div className={`${Style.shuizhi} ${Style[`shuizhi-${level.overallCategoryNum}`]}`}>
                        {convert(level.overallCategoryNumCopy)} 类
                    </div>
                </div>
            </div>

        </div>}
        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} bodyStyle={{ background: 'none' }} width={1563} height={960} footer={null} maskClosable closable={false} className={`water-modal-main`}>
            <div className={Style[`water-modal-wrapper`]}>
                <div className={Style[`water-modal-wrapper-main`]}>
                    <div className={Style[`water-modal-wrapper-main_tit`]}>
                        {modelTit}水质状况
                    </div>
                    <div className={Style[`water-modal-wrapper-main_bd`]}>
                        <div className={Style[`main_left`]}>
                            <div className={`${Style.WaterLevelWrapper} flex flex-colum m-l-44`}>
                                {!_.isEmpty(level) && <div className={`flex-1 w100 flex flex-a-c flex-j-c`} >
                                    <div className={`${Style.waterLamp} ${Style[`waterLamp-${level.overallCategoryNum}`]}`}>
                                        <div className={`${Style.waterLampMain} ${Style[`waterLampMain-${level.overallCategoryNum}`]}`}>
                                            <div className={`${Style.shuizhi} ${Style[`shuizhi-${level.overallCategoryNum}`]}`}>
                                                {convert(level.overallCategoryNumCopy)} 类
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div >
                        </div>
                        <div className={Style[`main_right`]}>
                            <div className={`${Style.targetTableWrap} flex flex-colum`}>
                                <div className={`h100 flex flex-a-c flex-j-c`}>
                                    <div className={`${Style.targetTableMian} flex flex-colum`}>
                                        <div className={`${Style.row} ${Style.header} w100 flex`}>
                                            <div className={`${Style.column} ${Style.column1}`}>序号</div>
                                            <div className={`${Style.column} ${Style.column2}`}>指标类型</div>
                                            <div className={`${Style.column} ${Style.column3}`}>实际值</div>
                                            <div className={`${Style.column} ${Style.column4}`}>II类标准</div>
                                        </div>
                                        <div className={`${Style.body}`}>
                                            <div className={`${Style.content}`}>
                                                <Carousel autoplay={1000} slidesToShow={11} slidesToScroll={1} swipeToSlide={true} dotPosition="right" dots={false} pauseOnHover={false} vertical verticalSwiping draggable>
                                                    {tableList.map((item, index) => <div className={`${Style.row} flex ${Style[`row-${index % 2 == 0 ? 2 : ''}`]}`} key={index}>
                                                        <div className={`${Style.column} ${Style.column1} fz-18`}>{index + 1}</div>
                                                        <div className={`${Style.column} ${Style.column2} fz-18`}>{item.target}</div>
                                                        <div className={`${Style.column} ${Style.column3} fz-18`} style={{ color: item.value == '-' ? '' : getColorFun(item.color) }}>{item.value == '-' ? '-' : item.value}</div>
                                                        <div className={`${Style.column} ${Style.column4} fz-18`}>{item.category_II_value || ''}</div>
                                                    </div>)}
                                                </Carousel>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div >
}


export default WaterLevel