import request from "../utils/request";
import qs from "qs";
import CONFIG from "@/config";


// 库中各测点水位信息
export async function getWaterlevels(data) {
    return request(`/hs/waterlevels`, {
        method: "get",
    });
}

// 雨量信息
export async function getDayRainInfo(data) {
    return request(`/hs/rains`, {
        method: "get",
    });
}

// 供水查询，流量--config配置(json的key)里面获取
export async function getFlowByName(data) {
    return request(`/bs/getFlowByName`, {
        method: "get",
        params: data
    });
}


// 发电机信息
export async function getPowerGenerator(data) {
    return request(`/hs/powerGenerator`, {
        method: "get",
        params: data
    });
}

// 发电量统计
export async function getGeneratedEnergy() {
    return request(`/hs/generatedEnergy`, {
        method: "get",
    });
}


// 生态流量
export async function getEcoFlow() {
    return request(`/hs/ecoFlow`, {
        method: "get",
    });
}


// 溢洪道信息
export async function getSpillway() {
    return request(`/hs/spillway`, {
        method: "get",
    });
}


// 泄洪洞放空洞信息
export async function getSpillwayTunnel() {
    return request(`/hs/spillwayTunnel`, {
        method: "get",
    });
}


// 下泄信息，单位是万m³
export async function getDownstreamFlow() {
    return request(`/hs/downstreamFlow`, {
        method: "get",
    });
}


// 接口返回溢洪道信息 + 泄洪洞放空洞信息
export async function getSpillwayTunnels() {
    return request(`/hs/getSpillwayTunnels`, {
        method: "get",
    });
}


// 横山表
export async function getSpillwayTunnelsNew() {
    return request(`/hs/getSpillwayTunnelsNew `, {
        method: "get",
    });
}