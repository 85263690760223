import React, { useEffect, useState } from 'react';
import Style from './index.module.less';
import Tit from "@/components/tit";
import { getMonitoringPointData, getInteractionInfo, getOverallCategory, getTributaryWaterQuality } from "@/api/rainQc";
import { Modal, Carousel, Tabs } from 'antd';
import { configData } from "@/store/raininfo";
import { useRecoilValue } from "recoil";
import _ from "lodash";

function setNumFun(v) {
    const arr = ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ']
    const colorList = ["#67cbac", "#ff8a3c", "#e95a54"]
    const num = arr.indexOf(v) + 1
    if (num < 3) {
        return colorList[0]
    } else if (num < 4) {
        return colorList[1]
    } else if (num == 5) {
        return colorList[2]
    }
}
function getColorFun(v) {
    const colorObj = {
        Red: '#e95a54',
        Green: '#67cbac',
        Orange: '#ff8a3c',
        White: '#FFF',
    }
    return colorObj[v]
}

function setNumTableFun(v) {
    const arr = ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ']
    const colorList = ["#67cbac", "#ff8a3c", "#e95a54"]
    const num = arr.indexOf(v) + 1
    if (num < 3) {
        return colorList[0]
    } else if (num < 4) {
        return colorList[1]
    } else if (num == 5) {
        return colorList[2]
    }
}
function addPos(o) {
    // 增加柱形颜色
    o.theme = setNumFun(o);
    // 和高度计算
    // o.theme.height = (o.val * 150) / 250 < 38 ? 38 : (o.val * 150) / 250;
}
const locationPoints = {
    bq: {  // 主要监测点（坝前
        stnm: "主要监测点（坝前）",
        pos: {
            x: 289,
            y: 445,
        },
        namePos: 'l',
    },
    mx: {
        stnm: "支流监测点（梅溪）",
        pos: {
            x: 512,
            y: 390,
        },
        namePos: 'r',
    },
    hx: {
        stnm: "支流监测点（合溪）",
        pos: {
            x: 686,
            y: 333,
        },
        namePos: 'r',
    },
    xx: {
        stnm: "支流监测点（雪溪）",
        pos: {
            x: 792,
            y: 283,
        },
        namePos: 'r',
    },
    jgx: {
        stnm: "支流监测点（莒根溪）",
        pos: {
            x: 562,
            y: 185,
        },
        namePos: 'r',
    }
}
function StationMap() {
    // const configDataReal = useRecoilValue(configData); // 大屏数据所有配置信息
    // const [level, setLevel] = useState({});
    const [list, setList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modelTit, setModelTit] = useState('');
    const [tabList, setTabList] = useState([]) // tab
    const getMonitoringPointDataFun = () => {
        getTributaryWaterQuality().then(res => {
            const results = res.data.results
            const dataList = results.map((item, index) => {
                console.log('item', item)
                const locationPoint = locationPoints[item.id]
                console.log(locationPoint)
                locationPoint[`stnm`] = item[`name`]
                locationPoint[`theme`] = {
                    borderColor: "#67cbac",
                    fillColor: " linear-gradient(0deg, #67CBAC, #02865F);",
                    txtFillColor: "#67cbac"
                }
                locationPoint[`list`] = item[`list`]
                return locationPoint
            })
            setList(dataList)
        })
    }
    const DrawCyline = (props) => {
        return props.stationRain.map((item, index) => {
            return (
                item.pos && (
                    <div
                        className={`${Style.tips}`}
                        key={index}
                        style={{ left: `${item.pos.x}px`, bottom: `${item.pos.y}px` }}
                        onClick={() => targetFun({ index, listCopy: props.stationRain })}
                    >
                        <div className={`${Style[`tis-name`]} ${Style[`tis-name-${item.namePos}`]} fz-14`}>{item.stnm}</div>
                        <div className={`${Style[`tips-foot`]}`} style={{ borderColor: item.theme.borderColor }}>
                            <div
                                className={`${Style[`tips-foot-before`]}`}
                                style={{ backgroundColor: item.theme.borderColor }}
                            >
                            </div>
                            <div
                                className={`${Style[`tips-foot-c`]}`}
                                style={{ borderColor: item.theme.borderColor }}
                            >
                                <div
                                    className={`${Style[`tips-foot-c-c`]}`}
                                    style={{ backgroundColor: item.theme.borderColor }}
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                )
            );
        });
    };
    // /**
    //  * // 获取最新的总水质指标
    //  */
    // const getOverallCategoryFun = () => {
    //     getOverallCategory().then(res => {
    //         const results = res.data.results
    //         results.overallCategoryNumCopy = results.overallCategoryNum
    //         results.overallCategoryNum = setNumFun2(results.overallCategoryNum)
    //         setLevel(results)
    //     })
    // }
    // /**
    //  * 获取每月水质信息 
    //  */
    // const getInteractionInfoFun = ({ index }) => {
    //     getInteractionInfo({
    //         corpId: configDataReal.corpid,
    //     }).then(res => {
    //         const result = res.data.results
    //         setModelTit('主要监测点（坝前）')
    //         setTableList(result);
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }
    /**
    * 水质大屏弹窗
    */
    const targetFun = ({ index, listCopy }) => {
        const itemObJ = list[index]
        setTabList(itemObJ[`list`].map((item, index) => {
            for (let key in item) {
                return { tabName: key, index, list: item[key] }
            }
        }))
        setIsModalOpen(true);
        setModelTit(itemObJ[`stnm`])
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        getMonitoringPointDataFun()
        // getOverallCategoryFun()
    }, [])
    return <div className={`${Style.StationMapWrap}`}>
        <Tit tit="监测点"></Tit>
        <div className={`${Style.StationMapWrapMain}`}>
            <DrawCyline stationRain={list} />
        </div>
        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} bodyStyle={{ background: 'none' }} width={900} height={800} footer={null} maskClosable closable={false} className={`water-modal-main`} >
            <div className={Style[`water-modal-wrapper`]}>
                <div className={Style[`water-modal-wrapper-main`]}>
                    <div className={Style[`water-modal-wrapper-main_tit`]}>
                        {modelTit}水质状况
                    </div>
                    <div className={Style[`water-modal-wrapper-main_bd`]}>
                        {/* <div className={Style[`main_left`]}>
                            <div className={`${Style.WaterLevelWrapper} flex flex-colum m-l-44`}>
                                {!_.isEmpty(level) && <div className={`flex-1 w100 flex flex-a-c flex-j-c`} >
                                    <div className={`${Style.waterLamp} ${Style[`waterLamp-${level.overallCategoryNum}`]}`}>
                                        <div className={`${Style.waterLampMain} ${Style[`waterLampMain-${level.overallCategoryNum}`]}`}>
                                            <div className={`${Style.shuizhi} ${Style[`shuizhi-${level.overallCategoryNum}`]}`}>
                                                {convert(level.overallCategoryNumCopy)} 类
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div >
                        </div> */}
                        <Tabs
                            tabPosition={'left'}
                            tabBarStyle={{ color: '#fff' }}
                            items={tabList.map((o, i) => {
                                return {
                                    label: o.tabName,
                                    key: o.tabName,
                                    children: <div className={`${Style[`main_right`]} m-t-20`}>
                                        <div className={`${Style.targetTableWrap} flex flex-colum`}>
                                            <div className={`h100 flex flex-a-c flex-j-c`}>
                                                <div className={`${Style.targetTableMian} flex flex-colum w100`}>
                                                    <div className={`${Style.row} ${Style.header} w100 flex`}>
                                                        <div className={`${Style.column} ${Style.column1}`}>序号</div>
                                                        <div className={`${Style.column} ${Style.column2}`}>指标类型</div>
                                                        <div className={`${Style.column} ${Style.column3}`}>实际值</div>
                                                        <div className={`${Style.column} ${Style.column4}`}>II类标准</div>
                                                    </div>
                                                    <div className={`${Style.body}`}>
                                                        <div className={`${Style.content}`} >
                                                            {o.list.length > 15 ? <Carousel autoplay={1000} slidesToScroll={1} dotPosition="right" dots={false} pauseOnHover={false} vertical verticalSwiping draggable>
                                                                {o.list.map((item, index) => <div className={`${Style.row} flex ${Style[`row-${index % 2 == 0 ? 2 : ''}`]}`} key={index}>
                                                                    <div className={`${Style.column} ${Style.column1} fz-18`}>{index + 1}</div>
                                                                    <div className={`${Style.column} ${Style.column2} fz-18`}>{item.target}</div>
                                                                    <div className={`${Style.column} ${Style.column3} fz-18`} style={{ color: item.value == '-' ? '' : getColorFun(item.color) }}>{item.value == '-' ? '-' : item.value}</div>
                                                                    <div className={`${Style.column} ${Style.column4} fz-18`}>{item.category_II_value || ''}</div>
                                                                </div>)}
                                                            </Carousel> :
                                                                o.list.map((item, index) => <div className={`${Style.row} flex ${Style[`row-${index % 2 == 0 ? 2 : ''}`]}`} key={index}>
                                                                    <div className={`${Style.column} ${Style.column1} fz-18`}>{index + 1}</div>
                                                                    <div className={`${Style.column} ${Style.column2} fz-18`}>{item.target}</div>
                                                                    <div className={`${Style.column} ${Style.column3} fz-18`} style={{ color: item.value == '-' ? '' : getColorFun(item.color) }}>{item.value == '-' ? '-' : item.value}</div>
                                                                    <div className={`${Style.column} ${Style.column4} fz-18`}>{item.category_II_value || ''}</div>
                                                                </div>)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>,
                                };
                            })}
                        />

                    </div>
                </div>
            </div>
        </Modal>
    </div>
}
export default StationMap;