import React, { useEffect, useState } from "react";
import { keepTwoDecimalFull } from "@/utils/common";
import { getSpillway, getSpillwayTunnels } from "@/api/rainHs";
import { useRecoilValue } from "recoil";
import Tit from "@/components/tit";
import "./index.less";
const _ = require("lodash");
function FloodWater(props) {
  const [gateTunneCopy, setGateTunneCopy] = useState([]); // 溢洪道 + 放空洞
  const [fkData, setFkData] = useState({});
  const [xhData, setXhData] = useState({});
  const [amount, setAmount] = useState(0); // 总量
  // const getSpillwayFun = () => {
  //   getSpillway().then(res => {
  //     setGateTunneCopy(res.data.results)
  //   })
  // }
  const getSpillwayTunnelsFun = () => {
    getSpillwayTunnels().then(res => {
      const results = res.data.results
      setGateTunneCopy(results.spillway)
      setFkData(results.spillwayTunnel[0])
      setXhData(results.spillwayTunnel[1])
      results.spillway.map(item => {
        amount += item.flow
      })
      amount = amount + results.spillwayTunnel[0][`flow`] + results.spillwayTunnel[1][`flow`]
    }).catch(err => {

    })
  }
  const allFun = async () => {
    // await getSpillwayFun();
    await getSpillwayTunnelsFun()
    setTimeout(() => {
      allFun()
    }, 300000); //   5分钟接口单独请求一次
  }
  useEffect(() => {
    allFun()
  }, [])
  return (
    <div className="xx-floodWater-wrap m-t-30">
      <Tit tit="溢洪道 + 放空洞 + 泄洪洞">
        <div className="tit">
          <span>
            <i>{keepTwoDecimalFull(amount * 3600 || 0, 1)}</i>
          </span>
          <span className="unit m-l-5">m³/h</span>
        </div>
      </Tit>
      <div className="hs-floodWater-wrap-main m-t-10 flex flex-a-c flex-colum">
        <div className="hs-zm">
          <ul className="flex">
            {gateTunneCopy.map((item, index) =>
              <li key={index} className="flex flex-a-c flex-colum flex-j-c flex-1">
                <div className="flex flex-a-c flex-colum flex-j-c">
                  <dt>{item.gateNo}#弧门</dt>
                  <dd>
                    <span className="num">
                      <i>{keepTwoDecimalFull(item.flow || 0, 1)}</i>
                    </span>
                    <span className="unit">m³/s</span>
                  </dd>
                  <dd>
                    <span className="num">
                      <i>{keepTwoDecimalFull(item.open || 0, 2)}</i>
                    </span>
                    <span className="unit">m</span>
                  </dd>
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className="hs-fk flex w100 flex-a-c flex-j-c">
          <div className="hs-fk-item flex flex-a-c flex-colum flex-j-c">
            <dt>放空洞</dt>
            <dd>
              <span className="num">
                <i>
                  {keepTwoDecimalFull(fkData.flow || 0, 1)}
                </i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            <dd>
              <span className="num">
                <i>{keepTwoDecimalFull(fkData.open || 0, 2)}</i>
              </span>
              <span className="unit">m</span>
            </dd>
          </div>
          <div className="hs-fk-item flex flex-a-c flex-colum flex-j-c">
            <dt>泄洪洞</dt>
            <dd>
              <span className="num">
                <i>
                  {keepTwoDecimalFull(xhData.flow || 0, 1)}
                </i>
              </span>
              <span className="unit">m³/s</span>
            </dd>
            <dd>
              <span className="num">
                <i>{keepTwoDecimalFull(xhData.open || 0, 2)}</i>
              </span>
              <span className="unit">m</span>
            </dd>
          </div>
        </div>
      </div>
    </div >
  );
}
export default FloodWater;
