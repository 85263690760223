import request from "../utils/request";
// import qs from "qs";
// import CONFIG from "@/config";
// 电站的一些基本信息
export async function getZjbsInfo(data) {
    return request(`/zjbs/info`, {
        method: "get",
    });
}


// 发电机信息
export async function getPowerGenerator(data) {
    return request(`/zjbs/powerGenerator`, {
        method: "get",
    });
}


// 发电量统计
export async function getGeneratedEnergy(data) {
    return request(`/zjbs/generatedEnergy`, {
        method: "get",
    });
}

// 钟家坛断面信息
export async function getRealTimeInfo(data) {
    return request(`/zjbs/getRealTimeInfo`, {
        method: "get",
    });
}

// 溢洪道信息
export async function getSpillway(data) {
    return request(`/zjbs/spillway`, {
        method: "get",
    });
}



// 溢洪道信息
export async function getEmptyTunnels(data) {
    return request(`/zjbs/emptyTunnels`, {
        method: "get",
    });
}


// 闸门信息所有数据
export async function getDeviceDetails(data) {
    return request(`/zjbs/deviceDetails`, {
        method: "get",
    });
}

// 总出库接口 

export async function getOutFlow(data) {
    return request(`/zjbs/outFlow`, {
        method: "get",
    });
}

// 皎口供水

export async function getWaterSupply(data) {
    return request(`/zjbs/waterSupply`, {
        method: "get",
    });
}