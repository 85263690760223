import React, { useEffect, useState } from "react";
import { getWaterSupply, getWaterSupply2,getWaterSupplyOfSZ4 } from "@/api/rain";
import { keepTwoDecimalFull } from "@/utils/common";
import { CloseCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import './index.less';
function WaterSupply() {
    const [dataNB, sethataNB] = useState([]); // 宁波
    const [dataFH, sethataFH] = useState([]); //  溪坑
    const [dataSC, setDataSC] = useState([]); //  水厂
    const [visible,setVisible] = useState(false); // 显示隐藏
    const getWaterSupplyFun = (type) => {
        return getWaterSupply({ name: type }).then((res) => {
            // 供水信息
            const results = res.data.results;
            const arr = []
            for (let i in results) {
                if (i != "ssll") {
                    results[i] = keepTwoDecimalFull((results[i].val / 10000), 2).split(".");
                } else if (i == "ssll") {
                    // 瞬时供水
                    results[i] = Math.ceil([results[i].val]);
                }
            }
            [`ssll`, `jrll`, `zrll`, `byll`, `qnll`].map(item => {
                arr.push(results[item])
            })

            if (type == 'waterSupplyXK') {
                sethataFH(arr);
            } else {
                sethataNB(arr);
            }
        }).catch(err => {

        })
    };
    const getWaterSupplyFun2 = (type) => {
        return getWaterSupply2({ name: type }).then((res) => {
            // 供水信息
            const results = res.data.results;
            const arr = []
            for (let i in results) {
                if (i == "ssll") {
                    // 瞬时供水
                    results[i] = Math.ceil([results[i].val]);
                } else if (i == "jrll" || i == "zrll") {
                    // 今日供水 昨日

                    let tm = i == "jrll" ? 0 : 86400
                    if (i == "jrll") {
                        tm = moment().diff(moment().startOf('day'), 'seconds')
                    }

                    // if (results[i].val / tm < 0.005) {
                    //     results[i] = null
                    // } else {
                    results[i] = keepTwoDecimalFull((results[i].val / 10000), 2).split(".");
                    // }
                } else {
                    results[i] = keepTwoDecimalFull((results[i].val / 10000), 2).split(".");
                }
            }
            [`ssll`, `jrll`, `zrll`, `byll`, `qnll`].map(item => {
                arr.push(results[item])
            })
            if (type == 'waterSupplyXK') {
                sethataFH(arr);
            } else {
                sethataNB(arr);
            }
        }).catch(err => {

        })
    };
    /**
     * 嵊州第四水厂的数据
     */
    const getWaterSupplyOfSZ4Fun =()=>{
        getWaterSupplyOfSZ4().then(res=>{
            const results = res.data.results;
            const arr = []
            for (let i in results) {
                if (i == "ssll") {
                    // 瞬时供水
                    results[i] = Math.ceil([results[i].val]);
                } else if (i == "jrll" || i == "zrll") {
                    // 今日供水 昨日

                    let tm = i == "jrll" ? 0 : 86400
                    if (i == "jrll") {
                        tm = moment().diff(moment().startOf('day'), 'seconds')
                    }

                    // if (results[i].val / tm < 0.005) {
                    //     results[i] = null
                    // } else {
                    results[i] = keepTwoDecimalFull((results[i].val / 10000), 2).split(".");
                    // }
                } else {
                    results[i] = keepTwoDecimalFull((results[i].val / 10000), 2).split(".");
                }
            }
            [`ssll`, `jrll`, `zrll`, `byll`, `qnll`].map(item => {
                arr.push(results[item])
            })
            setDataSC(arr);
        })
    }
    const to = (cb) => {
        return cb.then(data => [null, data]).catch(err => [err, null])
    }
    /**
     * 显示
     */
    const showFun = ()=>{
        setVisible(true)
    }
    const allFun = () => {
        getWaterSupplyFun2('waterSupplyXK')
        getWaterSupplyFun('waterSupplyNB')
        getWaterSupplyOfSZ4Fun()
        setTimeout(() => {
            allFun()
        }, 300000); //   5分钟接口单独请求一次
    }
    useEffect(() => {
        allFun()
    }, []);
    return (
        <div className="pos-r">
            {visible&&<div className="water-supply-main-wrap-2">
                <CloseCircleOutlined className="closeBtn" onClick={()=>setVisible(false)}/>
                <table className="w100">
                    <tbody>
                        <tr >
                                <td className="w40" onClick={showFun}><div className="blue-bg m-b-7 pos-r"><span className="tag">水厂</span></div></td>
                                {dataSC.map((item, index) =>
                                    <td key={index}>
                                        <div className="blue-bg">
                                            <span className='num'>
                                                {index == 0 ? <i>{item}</i> : item && item[0] ? (<><i>{item[0]}</i>.{item[1]}</>) : <i>0</i>}
                                            </span>
                                            <span className="unit">{index === 0 ? 'm³/h' : '万m³'}</span>
                                        </div>
                                    </td>)}
                            </tr>
                    </tbody>
                </table>
            </div>}
        <div className="water-supply-wrap flex  flex-j-b flex-a-c">
            <div className="tit-water-wrap flex flex-a-c">供水信息</div>
            <div className="water-supply-main-wrap flex flex-j-b">
                <div className="w100 p-l-20 p-r-20 p-t-25">
                    <table className="w100">
                        <thead>
                            <tr>
                                <th></th>
                                <th>瞬时供水量</th>
                                <th>今日供水量</th>
                                <th>昨日供水量</th>
                                <th>当月累计供水量</th>
                                <th>全年累计供水量</th>
                                {/* <th>总供水量</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td className="w40"><div className="purple-bg m-b-7 pos-r"><span className="tag">宁波</span></div></td>
                                {dataNB.map((item, index) =>
                                    <td key={index}>
                                        <div className="purple-bg m-b-7 pos-r">
                                            <span className='num'>
                                                {index == 0 ? <i>{item}</i> : item && item[0] ? (<><i>{item[0]}</i>.{item[1]}</>) : 0}
                                            </span>
                                            <span className="unit">{index === 0 ? 'm³/h' : '万m³'}</span>
                                        </div>
                                    </td>)}
                            </tr>
                            <tr >
                                <td className="w40" onClick={showFun}><div className="blue-bg m-b-7 pos-r"><span className="tag">嵊州</span></div></td>
                                {dataFH.map((item, index) =>
                                    <td key={index}>
                                        <div className="blue-bg">
                                            <span className='num'>
                                                {index == 0 ? <i>{item}</i> : item && item[0] ? (<><i>{item[0]}</i>.{item[1]}</>) : <i>0</i>}
                                            </span>
                                            <span className="unit">{index === 0 ? 'm³/h' : '万m³'}</span>
                                        </div>
                                    </td>)}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
        </div>
    );
}

export default WaterSupply;
