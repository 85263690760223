import React, { useEffect, useState } from "react";
import { keepTwoDecimalFull } from "@/utils/common";
import { Tooltip } from 'antd';
import Wave from "./wave";
import './index.less';
const _ = require('lodash');
function Daba(props) {
    const [ecoLDetails, setEcoLDetails] = useState([]); //  生态阀
    const [deviceDetails, setDeviceDetails] = useState([]); // 发电机组
    const [waterSupplyDetails, setWaterSupplyDetails] = useState([]); // 供水
    const [powerAmont, setPowerAmont] = useState(0); // 发电机组
    const [ecoAmont, setEcoAmont] = useState(0); // 生态
    const [waveStyle, setWaveStyle] = useState({});
    const [liushuiStatus, setLiushuiStatus] = useState(0); // 流水状态
    const showTips = {
        'DN500': '数据源于电站室外DN500流量计',
        'DN1400': '数据源于电站室外DN1400流量计',
        'DN300': '数据源于电站室内DN300流量计'
    }
    useEffect(() => {
        if (!_.isEmpty(props.powerDetails)) {
            let liushuiStatusCopy = 0
            props.powerDetails.powerDetails.map((item, index) => {
                if (index == 0) {
                    props.powerDetails[`powerDetails`][index][`ding`] = 250
                }
                if (index == 1) {
                    props.powerDetails[`powerDetails`][index][`ding`] = 1250
                }
                if (index == 2) {
                    props.powerDetails[`powerDetails`][index][`ding`] = 1250
                }
                if ((props.powerDetails[`powerDetails`][index][`power`] > 0) && !liushuiStatusCopy) {
                    liushuiStatusCopy = 1
                }
            })
            props.powerDetails.ecoLDetails.map((item, index) => {
                if ((item[`flowValue`] > 0) && !liushuiStatusCopy) {
                    liushuiStatusCopy = 1
                }
            })
            setDeviceDetails(props.powerDetails[`powerDetails`])
            setEcoLDetails(props.powerDetails[`ecoLDetails`])
            setWaterSupplyDetails(props.powerDetails[`waterSupplyDetails`])
            setLiushuiStatus(liushuiStatusCopy)
        } else {
            setDeviceDetails([])
            setEcoLDetails([])
            setLiushuiStatus(0)
        }
        setPowerAmont(_.sumBy(props.powerDetails.powerDetails, function (o) { return o.flowSeconds; }))
        setEcoAmont(_.sumBy(props.powerDetails.ecoLDetails, function (o) { return o.deviceTag == 'DN300' && (o.flowSeconds); }))
        if (!_.isEmpty(props.rainInfoReal)) {
            setWaveStyle({
                lineWidth: `${543}`,
                waveTop: `-${70.686 + 9.9856 * (100 - props.rainInfoReal.val)}`,
                lineTop: `${90.881 + 9.9 * (100 - props.rainInfoReal.val)}`,
                val: props.rainInfoReal.val
            });
        }
    }, [props]);
    return <div className="Daba-wrap">
        <div className="Daba-main-wrap">
            <div className={`liushui-daba liushui-daba-${props.zmStatus}`}>
            </div>
            <div className="liushui-mark-line"
                style={{
                    width: `${waveStyle.lineWidth}px`,
                    top: `${Number(waveStyle.lineTop)}px`,
                }}
            >
                <span>当前水位：{waveStyle.val} m</span>
            </div>
            <div className={`liushui liushui-${liushuiStatus ? 'active' : '2'}`}></div>
            <div className="liushui-water-wrap">
                <div className={`liushui-water`} style={{ bottom: `${Number(waveStyle.waveTop)}px` }}>
                    <Wave />
                </div>
            </div>
            <div className={`liushui-kedu`}></div>
            <div className="Daba-Children ">
                <div className="tit-amount flex">
                    <div className="tit-amount-left">供水</div><div className="flex-1 tit-amount-right">下泄(生态+发电)流量：{keepTwoDecimalFull(ecoAmont + powerAmont, 2)} <span className="m-l-3 fz-12">m³/s</span></div>
                </div>
                <div className="flex">
                    <div className="liushui-ck"></div>
                    <div className="Daba-Children-left flex flex-colum">
                        {/* <div className="tit">
                            生态管流量：{keepTwoDecimalFull(ecoAmont, 2)} <span className="m-l-3 fz-12">m³/s</span>
                        </div> */}
                        <div className="flex flex-1">
                            <ul className="fenche after ">
                                {waterSupplyDetails.map((item, index) => {
                                    return (
                                        <li className="fenche-li" key={index}>
                                            <div className="tit">{index == 0 ? '嵊州' : item.deviceTitle}</div>
                                            <div className="fenche-li-ecol-hd flex  flex-a-c">
                                                <div className="flex flex-colum w100">
                                                    <div className="fz-12 m-b-10 w100 fz-12 txt-c">{index == 0 ? '年累计' : '累计总量'}</div>
                                                    <div className="txt-c w100 flex flex-a-c flex-j-c fz-14">
                                                        {keepTwoDecimalFull((item.flowAmount || 0) / 10000, 2)}<span className="fz-10">万m³</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="fenche-li-hd fz-12 theme-bg-color" style={{ opacity: 0 }}>
                                                {keepTwoDecimalFull(item.generatedOutput || 0, 0)}
                                                <span className="m-l-3">Kw</span>
                                            </div> */}
                                            <div className="fenche-li-bd">
                                                {!item.flowValue ? (
                                                    <>
                                                        <div
                                                            className={`op-7 op-7-1`}
                                                            style={{ opacity: item.type == 1 ? 0.6 : 1 }}
                                                        >
                                                            <Tooltip title={showTips[item.deviceTag]}>
                                                                <div className="fenche-li-desc fz-12">{item.deviceTag}</div>
                                                            </Tooltip>
                                                        </div>
                                                        <span className="top-icon"></span>
                                                    </>
                                                ) : (
                                                    <div className="h100 fenche-li-img">
                                                        <div className="fenche-li-img-gif"></div>
                                                        <Tooltip title={showTips[item.deviceTag]}>
                                                            <div className="fenche-li-desc fz-12">{item.deviceTag}</div>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="fenche-li-ft fz-12 theme-bg-color">
                                                {keepTwoDecimalFull(item.flowSeconds, 2)}
                                                <span className="m-l-3">m³/s</span>
                                            </div>
                                        </li>
                                    );
                                })}
                                {ecoLDetails.map((item, index) => {
                                    return (
                                        <li className="fenche-li" key={index}>
                                            <div className="tit">{item.deviceTitle}</div>
                                            <div className="fenche-li-ecol-hd flex  flex-a-c">
                                                <div className="flex flex-colum w100">
                                                    <div className="fz-12 m-b-10 w100 fz-12 txt-c">年累计</div>
                                                    <div className="txt-c w100 flex flex-a-c flex-j-c fz-14">
                                                        {keepTwoDecimalFull((item.flowAmount || 0) / 10000, 2)}<span className="fz-10">万m³</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="fenche-li-hd fz-12 theme-bg-color" style={{ opacity: 0 }}>
                                                {keepTwoDecimalFull(item.generatedOutput || 0, 0)}
                                                <span className="m-l-3">Kw</span>
                                            </div> */}
                                            <div className="fenche-li-bd">
                                                {!item.flowValue ? (
                                                    <>
                                                        <div
                                                            className={`op-7 op-7-1`}
                                                            style={{ opacity: item.type == 1 ? 0.6 : 1 }}
                                                        >
                                                            <Tooltip title={showTips[item.deviceTag]}>
                                                                <div className="fenche-li-desc fz-12">{item.deviceTag}</div>
                                                            </Tooltip>
                                                        </div>
                                                        <span className="top-icon"></span>
                                                    </>
                                                ) : (
                                                    <div className="h100 fenche-li-img">
                                                        <div className="fenche-li-img-gif"></div>
                                                        <Tooltip title={showTips[item.deviceTag]}>
                                                            <div className="fenche-li-desc fz-12">{item.deviceTag}</div>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="fenche-li-ft fz-12 theme-bg-color">
                                                {keepTwoDecimalFull(item.flowSeconds, 2)}
                                                <span className="m-l-3">m³/s</span>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="Daba-Children-right m-l-10">
                        <div className="tit">
                            发电总流量：{keepTwoDecimalFull(powerAmont, 2)} <span className="m-l-3 fz-12">m³/s</span>
                        </div>
                        <ul className="fenche after m-l-19">
                            {deviceDetails.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <div className="fenche-li">
                                            <div className="fenche-li-tit fz-12 flex flex-colum flex-a-c">
                                                <div className="txt-c">装机容量</div>
                                                <div className="fz-14 m-t-4  txt-c">{keepTwoDecimalFull(item.ding, 0)}<span className="m-l-3">kW</span></div>
                                            </div>
                                            <div className="fenche-li-hd fz-12 theme-bg-color">
                                                {keepTwoDecimalFull(item.power, 1)}
                                                <span className="m-l-3">kW</span>
                                            </div>
                                            <div
                                                className={`fenche-li-bd ${item.power ? "active" : ""}`}
                                            >
                                                <div className={`op-6 ${item.power ? "active" : ""}`}>
                                                    <span className="fenche-ani"></span>
                                                    <span className="fenche-desc fenche-desc-2 fz-12">
                                                        {index == 0 ? '生态机组' : index + 1 + '号机组'}
                                                    </span>
                                                    <span className="fenche-desc fenche-desc-1 fz-12">
                                                        {index == 0 ? 'DN600' : 'DN1250'}
                                                    </span>
                                                </div>
                                                {item.power ? "" : <span className="top-icon"></span>}
                                            </div>
                                            <div className="fenche-li-ft fz-12 theme-bg-color">
                                                {keepTwoDecimalFull(item.flowSeconds, 2)}
                                                <span className="m-l-3">m³/s</span>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default Daba