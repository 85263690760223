/* eslint-disable */
export function dateFilter(data, format = "") {
  // 时间转换
  var dt = new Date(data);
  var y = dt.getFullYear();
  var m = (dt.getMonth() + 1).toString().padStart(2, "0");
  var d = dt.getDate().toString().padStart(2, "0");
  var h = dt.getHours().toString().padStart(2, "0");
  var mm = dt.getMinutes().toString().padStart(2, "0");
  var s = dt.getSeconds().toString().padStart(2, "0");
  if (format.toLocaleLowerCase() === "mm-dd") {
    return `${m}-${d}`;
  } else if (
    format.toLocaleLowerCase() === "yyyy-mm-dd" ||
    format.toLocaleLowerCase() === ""
  ) {
    return `${y}-${m}-${d}`;
  } else if (format.toLocaleLowerCase() === "yyyy/mm/dd") {
    return `${y}/${m}/${d}`;
  } else if (format.toLocaleLowerCase() === "yyyy-mm-dd hh:mm:ss") {
    return `${y}-${m}-${d} ${h}:${mm}:${s}`;
  } else if (format.toLocaleLowerCase() === "yyyy/mm/dd hh:mm:ss") {
    return `${y}/${m}/${d} ${h}:${mm}:${s}`;
  } else {
    return `输入的时间格式有误`;
  }
}

export function SectionToChinese(section, type) {
  var sectionCopy = section
  // 数字转中文
  var index = 0;
  var chnNumChar = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  var chnUnitSection = ["", "万", "亿", "万亿", "亿亿"];
  var chnUnitChar = ["", "十", "百", "千"];
  var strIns = "",
    chnStr = "";
  var unitPos = 0;
  var zero = true;
  while (section > 0) {
    index++;
    var v = section % 10;
    if (v === 0) {
      if (!zero) {
        zero = true;
        chnStr = chnNumChar[v] + chnStr;
      }
    } else {
      zero = false;
      strIns = chnNumChar[v];
      if (strIns == "一" && chnUnitChar[unitPos] == "十") strIns = "";
      strIns += chnUnitChar[unitPos];
      chnStr = strIns + chnStr;
    }
    unitPos++;
    section = Math.floor(section / 10);
  }
  if (type) {
    return sectionCopy == 0 ? '天' : chnStr
  } else {
    return chnStr;
  }
}

export function convert(num) {
  // 数字转罗马文字
  var ans = "";
  var k = Math.floor(num / 1000);
  var h = Math.floor((num % 1000) / 100);
  var t = Math.floor((num % 100) / 10);
  var o = num % 10;
  var one = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"];
  var ten = ["X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC"];
  var hundred = ["C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM"];
  var thousand = "M";
  for (var i = 0; i < k; i++) {
    ans += thousand;
  }
  if (h) ans += hundred[h - 1];
  if (t) ans += ten[t - 1];
  if (o) ans += one[o - 1];
  return ans;
}

export function GetRequest() {
  const url = location.search; //获取url中"?"符后的字串
  let theRequest = new Object();
  if (url.indexOf("?") != -1) {
    let str = url.substr(1);
    let strs = str.split("&");
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

export function keepTwoDecimalFull(number, n = 1, showZero = false) {
  //四舍五入保留w位小数（不够位数，则用0替补）  n为保留几位小数 默认为1
  n = n ? parseInt(n) : 0;
  if (number == 0 && showZero) {
    return number
  }
  if (n <= 0) {
    return Math.round(number);
  }
  number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n); //四舍五入
  number = Number(number).toFixed(n); //补足位数
  return number;
}