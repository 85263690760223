import React, { useEffect, useState } from "react";
import Tit from "@/components/tit";
import Style from "./index.module.less";
import { useRecoilValue } from "recoil";
import { nowFlow } from "@/store/raininfo";
import { getSpillwayTunnelsNew, getDownstreamFlow } from "@/api/rainHs";
import { keepTwoDecimalFull } from "@/utils/common";

const _ = require('lodash')
function TableHs() {
    const [tableData, setTableData] = useState({})
    const getSpillwayTunnelsNewFun = () => {
        getSpillwayTunnelsNew().then(res => {
            const results = res.data.results
            console.log('results', results)
            setTableData(results)
        })
        setTimeout(() => {
            getSpillwayTunnelsNewFun()
        }, 300000)
    }

    useEffect(() => {
        getSpillwayTunnelsNewFun()
    }, [])
    return <div>
        <Tit tit="下泄信息" size="large"></Tit>
        {!_.isEmpty(tableData) && <table className={`${Style[`tableHs`]} m-t-20`}>
            <thead>
                <tr>
                    <th className={Style[`th_min`]}></th>
                    <th className={Style[`th_min`]}></th>
                    <th><div>开度<div className="inline m-t-5 fz-14">(m)</div></div></th>
                    <th><div>流量<br /><div className="inline m-t-5 fz-14">(m³/s)</div></div></th>
                    <th><div>总流量<br /><div className="inline m-t-5 fz-14">(m³/s)</div></div></th>
                    <th><div>今日<br />水量<div className="inline m-t-5 fz-14">(万m³)</div></div></th>
                    <th><div>昨日<br />水量<div className="inline m-t-5 fz-14">(万m³)</div></div></th>
                    <th><div>当月量<br />计水量<div className="inline m-t-5 fz-14">(万m³)</div></div></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowSpan={3}><div className={Style[`min`]}>溢洪道</div></td>
                    <td className={Style[`bg`]}>1#</td>
                    <td>{keepTwoDecimalFull(tableData.spillway[0].open, 1)}</td>
                    <td rowSpan={3}>{keepTwoDecimalFull(tableData.spillway[0].flow, 2, true)}</td>
                    <td rowSpan={3}>{keepTwoDecimalFull(tableData.spillway[0].flowSum, 2, true)}</td>
                    <td rowSpan={3}>{keepTwoDecimalFull(tableData.spillway[0].jrll, 3, true)}</td>
                    <td rowSpan={3}>{keepTwoDecimalFull(tableData.spillway[0].zrll, 3, true)}</td>
                    <td rowSpan={3}>{keepTwoDecimalFull(tableData.spillway[0].byll, 2, true)}</td>
                </tr>
                <tr>
                    <td>2#</td>
                    <td>{keepTwoDecimalFull(tableData.spillway[1].open, 1)}</td>
                </tr>
                <tr>
                    <td>3#</td>
                    <td>{keepTwoDecimalFull(tableData.spillway[2].open, 1)}</td>
                </tr>
                <tr>
                    <td colSpan={2}><div className="flex flex-j-c"><div className={`${Style[`w-22`]} flex flex-a-c ${Style[`h-75`]}`}>放空洞</div>{tableData.emptyTunnel.isRepair?<div className={Style[`w-22`]}><div className={Style[`rotat`]}>(</div>检修中<div className={Style[`rotat`]}>)</div></div>:''}</div></td>
                    <td >{keepTwoDecimalFull(tableData.emptyTunnel.open, 1, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.emptyTunnel.flow, 2, true)}</td>
                    <td>{keepTwoDecimalFull(tableData.emptyTunnel.flowSum, 2, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.emptyTunnel.jrll, 3, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.emptyTunnel.zrll, 3, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.emptyTunnel.byll, 2, true)}</td>
                </tr>
                <tr>
                <td colSpan={2}><div className="flex flex-j-c"><div className={`${Style[`w-22`]} flex flex-a-c ${Style[`h-75`]}`}>泄洪洞1</div>{tableData.spillwayTunnel.isRepair?<div className={Style[`w-22`]}><div className={Style[`rotat`]}>(</div>检修中<div className={Style[`rotat`]}>)</div></div>:''}</div></td>
                    <td >{keepTwoDecimalFull(tableData.spillwayTunnel.open, 1, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.spillwayTunnel.flow, 2, true)}</td>
                    <td>{keepTwoDecimalFull(tableData.spillwayTunnel.flowSum, 2, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.spillwayTunnel.jrll, 3, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.spillwayTunnel.zrll, 3, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.spillwayTunnel.byll, 2, true)}</td>
                </tr>
                <tr>
                    <td colSpan={2}><div className="flex flex-j-c"><div className={`${Style[`w-22`]} flex flex-a-c`}>泄洪洞2</div><div className={Style[`w-22`]}><div className={Style[`rotat`]}>(</div>建设中<div className={Style[`rotat`]}>)</div></div></div></td>
                    <td >{keepTwoDecimalFull(0, 1, true)}</td>
                    <td >{keepTwoDecimalFull(0, 1, true)}</td>
                    <td>{keepTwoDecimalFull(0, 0, true)}</td>
                    <td >{keepTwoDecimalFull(0, 0, true)}</td>
                    <td >{keepTwoDecimalFull(0, 0, true)}</td>
                    <td >{keepTwoDecimalFull(0, 0, true)}</td>
                </tr>
                <tr>
                    <td colSpan={2}><div className={Style[`min`]}>生态流量</div></td>
                    <td >-</td>
                    <td >{keepTwoDecimalFull(tableData.ecolFlow.flow, 2, true)}</td>
                    <td>{keepTwoDecimalFull(tableData.ecolFlow.flowSum, 2, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.ecolFlow.jrll, 3, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.ecolFlow.zrll, 3, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.ecolFlow.byll, 2, true)}</td>
                </tr>
                <tr>
                    <td colSpan={2}><div className={Style[`min`]}>总下泄流量</div></td>
                    <td >-</td>
                    <td >{keepTwoDecimalFull(tableData.all.flow, 2, true)}</td>
                    <td>{keepTwoDecimalFull(tableData.all.flowSum, 2, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.all.jrll, 3, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.all.zrll, 3, true)}</td>
                    <td >{keepTwoDecimalFull(tableData.all.byll, 2, true)}</td>
                </tr>
            </tbody>
        </table>}
    </div>
}
export default TableHs;