import request from "../utils/request";
import qs from "qs";
import CONFIG from "@/config";
// 电站的一些基本信息
export async function getPowerGenerationInfo(data) {
    return request(`/ps/getPowerGenerationInfo`, {
        method: "get",
    });
}
// 月发电量汇总
export async function getPowerGenerationSum(data) {
    return request(`/ps/getPowerGenerationSum`, {
        method: "get",
    });
}

// 获取发电机组数据
export async function getPowerDetails(data) {
    return request(`/ps/getPowerDetails`, {
        method: "get",
    });
}


// 历史年度发电
export async function getHistoryYearsPowerGenerationSum(data) {
    return request(`/ps/getHistoryYearsPowerGenerationSum`, {
        method: "get",
    });
}


// 获取生态流量
export async function getEcoFlow(data) {
    return request(`/ps/ecoFlow`, {
        method: "get",
    });
}


// 水头信息
export async function getWaterHead(data) {
    return request(`/ps/getWaterHead`, {
        method: "get",
    });
}