import React, { useState, useEffect } from "react";
import Style from "./index.module.less";
import DamLeft from "./module/DamLeft";
import DamRight from "./module/DamRight";
import { getZjbsInfo } from "@/api/union";
function UnionZj(props) {
    const [jkReservoirInfo, setJkReservoirInfo] = useState({}) // 皎口信息
    const [zgzReservoirInfo, setZgzReservoirInfo] = useState({}) // 周公信息
    const getZjbsInfoFun = () => {
        getZjbsInfo().then(res => {
            const results = res.data.results
            if (!!results) {
                setJkReservoirInfo(results[`jksk`][`realtime`])
                setZgzReservoirInfo(results[`zgzsk`][`realtime`])
            }
            setTimeout(() => {
                getZjbsInfo()
            }, 300000)
        }).catch(err => {
            setTimeout(() => {
                getZjbsInfo()
            }, 300000)
        })

    }
    useEffect(() => {
        getZjbsInfoFun()
    }, [])
    return (
        <div className={`${Style['UnionZj-wrapper']} flex flex-a-c `}>
            <div className="flex fle-a-c flex-1 h100">
                <DamLeft reservoirInfo={zgzReservoirInfo} />
                <DamRight reservoirInfo={jkReservoirInfo} />
            </div>
        </div>
    );
}
export default UnionZj;
