import Tit from "@/components/tit";
import React, { useEffect, useState } from "react";
import "./index.less";
import { getDuty } from "@/api/rain";
import moment from "moment";
function Onduty(params) {
  const [data, setData] = useState([]);
  useEffect(() => {
    getDuty().then((res) => {
      const results = res.data.results;
      const arr = [];
      if (!!results) {
        // eslint-disable-next-line array-callback-return
        results.map((item, index) => {
          item.tm = moment(item.tm).format("MM-DD");
          // if (index == 0 || index == 1) {
          // 取两位
          const duty_member1 = [];
          item.duty_member1.split(",").map((item2) => {
            if (item2.length === 2) {
              item2 = item2.slice(0, 1) + "\xa0\xa0\xa0\xa0" + item2.slice(1);
            }
            duty_member1.push(item2);
          });
          item.duty_member1 = duty_member1.toString();
          arr.push(item);
          // }
        });
        setData(arr);
      }
    });
  }, []);
  const CreatTb = (props) => {
    return props.data.map((item, index) => {
      return (
        <tr key={index} className={`tr-${index}`}>
          <td className="td1">{item.tm}</td>
          <td>{item.duty_member1}</td>
          <td>{item.leader ? item.leader : "- -"}</td>
          {/* <td>{item.duty_member2}</td> */}
        </tr>
      );
    });
  };
  return (
    <div className="onduty-wrap m-t-16">
      <Tit tit="值班信息"></Tit>
      <div className="onduty-main-wrap-1">
        <div className="onduty-main-wrap m-t-19">
          {!data.length ? (
            <div className="no-list p-t-62 f-z-20 m-b-20">暂无数据</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>值班日期</th>
                  <th style={{ width: "270px" }}>值班人员</th>
                  <th>带班领导</th>
                </tr>
              </thead>
              <tbody>
                <CreatTb data={data}></CreatTb>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default Onduty;
