import React, { useState, useEffect } from "react";
import { SectionToChinese } from "@/utils/common";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { showMenuVisible, isLogin, showImage, showVideo } from "@/store/raininfo";
import Cookies from "js-cookie";
import moment from "moment";
import "./index.less";
const firstShowTime = Date.now(); // 第一次刷屏记录
function Time() {
  const [nowTime, setNowTime] = useState(moment(firstShowTime).format("HH:mm:ss"));
  const [week, setWeek] = useState(moment(firstShowTime).format("e"));
  const [date, setDate] = useState(moment(firstShowTime).format("YYYY年MM月DD日"));
  const setVisible = useSetRecoilState(showMenuVisible); // 控制显示隐藏
  const setIsLoginState = useSetRecoilState(isLogin); // 控制显示隐藏
  const showImageStatus = useRecoilValue(showImage); // 获取是否图片显示
  const showVideoStatus = useRecoilValue(showVideo); // 获取是否视频显示
  /**
   * 时间显示
   */
  const setTimeFun = () => {
    setTimeout(() => {
      const now = Date.now();
      if (
        !showImageStatus &&
        !showVideoStatus &&
        now - firstShowTime > 3600000
      ) {
        // 1小时刷新一次(整个界面)
        window.location.reload();
      }
      setNowTime(moment().format("HH:mm:ss"));
      setWeek(moment().format("e"));
      setDate(moment().format("YYYY年MM月DD日"));
    }, 1000)
  }
  const showMenu = async () => {
    if (Cookies.get("loginMd5")) {
      await setIsLoginState(true);
    }
    setVisible(true);
  };
  useEffect(() => {
    setTimeFun()
  }, [nowTime]);
  return (
    <div className="time-wrap flex flex-a-c">
      <div className="time-wrap-l">{nowTime}</div>
      <div className="time-wrap-r m-l-23">
        <div className="date">{date}</div>
        <div className="week m-t-2">星期{SectionToChinese(week, 1)}</div>
      </div>
      {/* <div className="menu m-l-20" onClick={showMenu}>
        <svg
          t="1605923925837"
          className="fill"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4974"
        >
          <path
            d="M832.2 264.6H192.3c-35.2 0-64-28.8-64-64s28.8-64 64-64h639.8c35.2 0 64 28.8 64 64 0.1 35.2-28.7 64-63.9 64z m0 313.3H196.1c-35.2 0-64-28.8-64-64s28.8-64 64-64h636.1c35.2 0 64 28.8 64 64s-28.8 64-64 64z m0 319H192.3c-35.2 0-64-28.8-64-64s28.8-64 64-64h639.8c35.2 0 64 28.8 64 64 0.1 35.2-28.7 64-63.9 64z"
            p-id="4975"
            fill="#59B9F5"
          ></path>
        </svg>
      </div> */}
    </div>
  );
}
export default Time;
