import React, { useState, useEffect, useRef } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { showVideo, videoConfig } from "@/store/raininfo";
import { Player } from "@/utils/video-react";
import "@/utils/video-react/dist/video-react.css";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "./index.less";
function Video() {
  const [isModalVisible, setIsModalVisible] = useRecoilState(showVideo);
  const videoConfigData = useRecoilValue(videoConfig);
  const player = useRef();
  useEffect(() => {
    if (
      isModalVisible &&
      player.current &&
      player.current.video &&
      videoConfigData.source
    ) {
      player.current.video.load();
      player.current.video.play();
    }
    if (!isModalVisible && videoConfigData.source) {
      // 指令发送停止播放
      player.current.video.pause();
    }
  }, [isModalVisible, videoConfigData]);
  return (
    <>
      <div
        className="screen-video-wrap"
        style={{ display: isModalVisible ? "" : "none" }}
      >
        <CloseCircleOutlined
          className="colse-btn"
          onClick={() => {
            setIsModalVisible(false);
            player.current.video.pause();
          }}
        />
        <Player
          videoId="video-1"
          ref={player}
          isFullscreen={true}
          width="100%"
          height="100%"
          fluid={false}
          autoPlay
        >
          <source src={videoConfigData.source} />
        </Player>
      </div>
    </>
  );
}

export default Video;
