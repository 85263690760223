import React, { useState, useEffect } from "react";
import { getDtu } from "@/api/rain";

function WaterSupply(props) {
  const [liuliang, setLiuliang] = useState({}); // 流量
  useEffect(() => {
    getDtuFun();
  }, []);
  const getDtuFun = () => {
    // getDtu().then((res) => {
    //   // 获取流量
    //   if (res.data.code == 0) {
    //     const data = res.data.results;
    //     if (data && data.status != 500) {
    //       try {
    //         for (let v in data) {
    //           if (
    //             v != "dateHour" &&
    //             typeof data[v] != "object" &&
    //             typeof data[v] == "number" &&
    //             data[v] != parseInt(data[v])
    //           ) {
    //             if (
    //               v == "totalFlowDaily" ||
    //               v == "yesterdayTotalFlow" ||
    //               v == "totalFlowYearly" ||
    //               v == "totalFlowMonthly"
    //             ) {
    //               data[v] = data[v] / 10000;
    //             }
    //             data[v] = String(data[v].toFixed(4)).split(".");
    //           }
    //         }
    //         setLiuliang(data);
    //       } catch (err) {
    //         console.log(err);
    //       }
    //     }
    //   }
    // });
  };

  return (
    <div className="water-supply-wrap flex  flex-j-b flex-a-c">
      <div className="tit-water-wrap">下游流量</div>
      <div className="water-supply-main-wrap  flex flex-j-b">
        <dl>
          <dt>瞬时流量</dt>
          <dd>
            {liuliang.totalInstantaneousFlow &&
            typeof liuliang.totalInstantaneousFlow == "object" ? (
              <span>
                <i>{liuliang.totalInstantaneousFlow[0]}</i>.
                {liuliang.totalInstantaneousFlow[1]}
              </span>
            ) : (
              <span>
                <i>-</i>
              </span>
            )}
            <span className="unit">m³/h</span>
          </dd>
        </dl>
        <dl>
          <dt>今日水量</dt>
          <dd>
            {liuliang.totalFlowDaily &&
            typeof liuliang.totalFlowDaily == "object" ? (
              <span>
                <i>{liuliang.totalFlowDaily[0]}</i>.{liuliang.totalFlowDaily[1]}
              </span>
            ) : (
              <span>
                <i>-</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
        <dl>
          <dt>昨日水量</dt>
          <dd>
            {liuliang.yesterdayTotalFlow &&
            typeof liuliang.yesterdayTotalFlow == "object" ? (
              <span>
                <i>{liuliang.yesterdayTotalFlow[0]}</i>.
                {liuliang.yesterdayTotalFlow[1]}
              </span>
            ) : (
              <span>
                <i>-</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
        <dl>
          <dt>当月累计水量</dt>
          <dd>
            {liuliang.totalFlowMonthly &&
            typeof liuliang.totalFlowMonthly == "object" ? (
              <span>
                <i>{liuliang.totalFlowMonthly[0]}</i>.
                {liuliang.totalFlowMonthly[1]}
              </span>
            ) : (
              <span>
                <i>-</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
        <dl>
          <dt>全年累计水量</dt>
          <dd>
            {liuliang.totalFlowYearly &&
            typeof liuliang.totalFlowYearly == "object" ? (
              <span>
                <i>{liuliang.totalFlowYearly[0]}</i>.
                {liuliang.totalFlowYearly[1]}
              </span>
            ) : (
              <span>
                <i>-</i>
              </span>
            )}
            <span className="unit">万m³</span>
          </dd>
        </dl>
      </div>
    </div>
  );
}

export default WaterSupply;
