import Tit from "@/components/tit";
import React, { Suspense, useEffect, useState } from "react";
import "./index.less";
import { getRainbear } from "@/api/rain";
import { configData, rainInfo } from "@/store/raininfo";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { keepTwoDecimalFull } from "@/utils/common";
const _ = require('lodash');
function RainInfo(params) {
  const setRainInfoCopy = useSetRecoilState(rainInfo); // 雨水信息全局
  const [rainInfoData, setRainInfoData] = useState({}); // 雨水信息
  const [yearRain, setYearRain] = useState(null);
  const configDataCopy = useRecoilValue(configData)
  const getRainbearFun = () => {
    getRainbear({ corpid: configDataCopy.corpid, rsvrCode: configDataCopy.config.rsvrCode }).then(res => {
      const data = res.data
      if (!_.isEmpty(data)) {
        data.realtime.sskr = keepTwoDecimalFull(data.realtime.sskr, 0)
        if (configDataCopy.corpid == '3302130002' || configDataCopy.corpid == '3302130002') { // 横山和亭下
          data.realtime.sskrBl = {
            name: '限蓄库容比',
            value: keepTwoDecimalFull(data.realtime.sskr / (data.period === 'zc' ? data.realtime.zckr : data.realtime.xxkr) * 100, 1)
          }
        } else {
          data.realtime.sskrBl = {
            name: '正常库容比',
            value: keepTwoDecimalFull((data.realtime.sskr / data.realtime.zckr) * 100, 1)
          }
        }
        setRainInfoData(data.realtime)
        setRainInfoCopy(data.realtime)
        console.log(!_.isNull(data.yearRain) ? keepTwoDecimalFull(data.yearRain, 1) : data.yearRain)
        setYearRain(!_.isNull(data.yearRain) ? keepTwoDecimalFull(data.yearRain, 1) : data.yearRain)
      }
      setTimeout(() => {
        getRainbearFun()
      }, 300000)
    })
  }
  useEffect(() => {
    getRainbearFun()
  }, [])
  return (
    <Suspense fallback={<div></div>}>
      <div className="raininfo-wrap">
        <Tit tit="水雨情信息" size={!_.isNull(yearRain) ? 'large' : 'mini'}>
          {!_.isNull(yearRain) && <div className="tit-r">
            <div className="tit-r-item no-wrap">
              今年累计降雨量:<span className="c1 m-l-5">{yearRain} mm</span>
            </div>
          </div>}
        </Tit>
        <div className="flex flex-a-c flex-j-c">
          <ul className="raininfo-ul m-t-28 after">
            <li className="raininfo-li m-r-40">
              <div className="raininfo-li-image raininfo-li-image-1"></div>
              <div className="raininfo-li-desc">
                <dt>今日雨量</dt>
                <dd>
                  <span>{parseFloat(rainInfoData.rain).toFixed(1)}</span>
                  mm
                </dd>
              </div>
            </li>
            <li className="raininfo-li">
              <div className="raininfo-li-image raininfo-li-image-2"></div>
              <div className="raininfo-li-desc">
                <dt>水库水位</dt>
                <dd>
                  <span>{parseFloat(rainInfoData.val).toFixed(2)}</span>m
                </dd>
              </div>
            </li>
            <li className="raininfo-li m-r-40">
              <div className="raininfo-li-image raininfo-li-image-3"></div>
              <div className="raininfo-li-desc">
                <dt>水库库容</dt>
                <dd>
                  <span>{rainInfoData.sskr}</span>万m³
                </dd>
              </div>
            </li>
            {!_.isEmpty(rainInfoData) && <li className="raininfo-li">
              <div className="raininfo-li-image raininfo-li-image-4"></div>
              <div className="raininfo-li-desc">
                <dt>{rainInfoData.sskrBl.name}</dt>
                <dd>
                  <span>
                    {rainInfoData.sskrBl.value}
                  </span>
                  %
                </dd>
              </div>
            </li>}
          </ul>
        </div>
      </div>
    </Suspense>
  );
}

export default RainInfo;
