import React from "react";
function Wave(params) {
  return (
    <svg
      className="fill w100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 621 786"
    >
      <linearGradient id="orange_red" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="rgba(83, 177, 252, 1)" />
        <stop offset="100%" stopColor="rgba(3, 27, 59, 1)" />
      </linearGradient>
      <g
        id="页面-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          id="img_water_front"
          className="cls-1"
          d="M371.112,786H0.006V9.286C1.635,9.109,10.677,12.5,23,11c6.052-.739,15.119-7.165,23-7,9.673,0.2,15.236,7.362,27,7,11.183-.344,20.524-7.034,33-7,10.323,0.028,23.3,7.125,34,7,11.344-.132,21.688-5.978,33-6,9.974-.019,22.416,7.115,32,7,13.721-.165,21.839-7.014,34-7,17.408,0.02,27.084,7.218,40,7,20.5-.346,17.375-5.6,28-7,6.382-.842,22.922,3.176,29.718-1.064A27.763,27.763,0,0,1,365.6,5.654s17.118,10.062,29.047,1.524c0,0,12.817-11.558,28.991,0,0,0,14.287,8.537,27.354,0,0,0,17.394-11.558,33.791,0a27.428,27.428,0,0,0,30.516,0s12.013-13.554,33.79,0c0,0,18.172,10.533,34.1,0,0,0,14.537-13.554,33.513,0C617.647,8.024,621,9,621,9V786S81.551,786,371.112,786Z"
          fill="url(#orange_red)"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="translate"
            from="0"
            to="-80"
            dur="2s"
            repeatCount="indefinite"
          ></animateTransform>
        </path>
        <path
          id="img_water_front"
          className="cls-1"
          d="M371.112,786H0.006V9.286C1.635,9.109,10.677,12.5,23,11c6.052-.739,15.119-7.165,23-7,9.673,0.2,15.236,7.362,27,7,11.183-.344,20.524-7.034,33-7,10.323,0.028,23.3,7.125,34,7,11.344-.132,21.688-5.978,33-6,9.974-.019,22.416,7.115,32,7,13.721-.165,21.839-7.014,34-7,17.408,0.02,27.084,7.218,40,7,20.5-.346,17.375-5.6,28-7,6.382-.842,22.922,3.176,29.718-1.064A27.763,27.763,0,0,1,365.6,5.654s17.118,10.062,29.047,1.524c0,0,12.817-11.558,28.991,0,0,0,14.287,8.537,27.354,0,0,0,17.394-11.558,33.791,0a27.428,27.428,0,0,0,30.516,0s12.013-13.554,33.79,0c0,0,18.172,10.533,34.1,0,0,0,14.537-13.554,33.513,0C617.647,8.024,621,9,621,9V786S81.551,786,371.112,786Z"
          fill="url(#orange_red)"
        />
      </g>
    </svg>
  );
}
export default Wave;
