import React, { useState, useEffect } from "react";
import { getFlowByName } from "@/api/rain";
import Tit from "@/components/tit";
import WaterCategory from "../watercategory";
import "../powerConfig/index.less";
import "./index.less";
const _ = require("lodash");
function Valve(props) {
  const [settingDataReal, setSettingDataReal] = useState({
    open: 3,
    liuliang: 0,
  });
  const getXxskFun = () => {
    getFlowByName({ name: "ecoflow" })
      .then((res) => {
        const settingDataRealCopy = _.cloneDeep(settingDataReal);
        const results = res.results
        // if (res.data.results) {
        //   res.data.results.map((item, index) => {
        //     if (item.name.indexOf("ssll") != -1) {
        //       settingDataRealCopy[`liuliang`] = Number(item.value);
        //       settingDataRealCopy[`open`] = item.value == 0 ? 1 : 3;
        //     }
        //   });
        //   console.log("settingDataRealCopy==>", settingDataRealCopy);
        //   setSettingDataReal(settingDataRealCopy);
        // }
        if (!_.isEmpty(results)) {
          for (let v in results) {
            if (v == 'ssll') {
              settingDataRealCopy[`liuliang`] = Number(results[`ssll`][`val`]);
              settingDataRealCopy[`open`] = results[`ssll`][`val`] == 0 ? 3 : 1;
              console.log('settingDataRealCopy', settingDataRealCopy)
            }
          }
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getXxskFun();
  }, []);
  return (
    <div className="flex flex-j-b m-t-10 m-b-30">
      <div className="xx-power-wrap power-wrap flex-1 m-r-32">
        <Tit tit="生态流量">
          <div className="tit">
            {settingDataReal && settingDataReal.open == 3 ? (
              <span>
                <i>{Number(settingDataReal.liuliang).toFixed(2)}</i>
              </span>
            ) : (
              <span>
                <i>0</i>
              </span>
            )}
            <span className="unit m-l-5">m³/s</span>
          </div>
        </Tit>
        <div className="power-main-wrap">
          <ul className="fenche after flex flex-j-c">
            <li className="fenche-li">
              {/* <div className="fenche-li-hd fz-12 theme-bg-color">0 %</div> */}
              <div className="fenche-li-bd">
                {settingDataReal.open == 3 ? (
                  <div
                    className={`op-7 op-7-${settingDataReal.open}`}
                    style={{ opacity: 1 }}
                  >
                    <span className="fenche-desc fz-14">生态流量</span>
                  </div>
                ) : (
                  <div className="h100 fenche-li-img">
                    <div className="fenche-li-img-gif"></div>
                    <div className="fenche-li-desc">生态管</div>
                  </div>
                )}
              </div>
              <div className="fenche-li-ft fz-12 theme-bg-color">
                {settingDataReal && settingDataReal.open == 3
                  ? Math.ceil(settingDataReal.liuliang * 3600)
                  : 0}
                <span className="m-l-3">m³/h</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <WaterCategory />
    </div>
  );
}
export default Valve;
