import React, { useEffect, useState } from "react";
import { Spin, message } from "antd";
import { PowerInfo, DayNum, Onduty, PowerAmount, PowerHistory, Daba } from "./module"
import { getPowerGenerationInfo, getPowerGenerationSum, getHistoryYearsPowerGenerationSum, getEcoFlow, getPowerDetails, getWaterHead } from "@/api/station";
import { getRainInfo, getSwitch } from "@/api/rain";
import {
    rainInfo,
    mapModel,
    titModel,
    showVideo,
    videoConfig,
    showImage,
    imageConfig,
    webscocketStatus,
    settingData,
    configData,
} from "@/store/raininfo";
import WebsocketHeartbeatJs from "@/utils/websocket-heartbeat-js/lib";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import CONFIG from "@/config";
import { GetRequest } from "@/utils/common";
import "./index.less";
let showTypeIndex = 0; // 展示数组下标
let showTypeArr = []; // 轮播展示的数组
const intervalTime = 300000 //5分钟
let setIntervalObj = null; //定时器
const _ = require('lodash')
function QcPowerStation() {
    const [showType, setShowType] = useRecoilState(mapModel); // 1.显示大坝 2.显示地图  3.欢迎界面
    const setWebscocketStatusType = useSetRecoilState(webscocketStatus); // 信号Status
    const setIsModalVisible = useSetRecoilState(showVideo); // 展示视频推送
    const setShowVideoConfig = useSetRecoilState(videoConfig); // 展示视频
    const setShowImage = useSetRecoilState(showImage); // 展示图片
    const setShowImageConfig = useSetRecoilState(imageConfig); // 展示图片地址
    const [titType, setTitType] = useRecoilState(titModel); // 2.党建 1.常规
    const [settingDataReal, setSettingDataReal] = useRecoilState(settingData); // 配置信息
    const [powerGenerationInfo, setPowerGenerationInfo] = useState({});  // 电站的一些基本信息
    const [powerGenerationSum, setPowerGenerationSum] = useState({});  // 月发电量汇总
    const [historyYearsPowerGenerationSum, setHistoryYearsPowerGenerationSum] = useState({});  // 历史年度发电
    const [ecoFlow, setEcoFlow] = useState({});  // 获取生态流量
    const [powerDetails, setPowerDetails] = useState([]);  // 获取发电机组数据
    const configDataReal = useRecoilValue(configData); // 大屏数据所有配置信息
    const [rainInfoData, setRainInfoData] = useRecoilState(rainInfo); // 雨水信息
    const [waterHead, setWaterHead] = useState({});  // 获取发电机组数据
    const [loading, setLoading] = useState(true);
    const [zmStatus, setZmStatus] = useState(0); // 全关
    // 电站的一些基本信息
    const getPowerGenerationInfoFun = () => {
        getPowerGenerationInfo().then(res => {
            const results = res.data.results
            setPowerGenerationInfo(results)
        }).catch(err => { })
    }
    // 月发电量汇总
    const getPowerGenerationSumFun = () => {
        getPowerGenerationSum().then(res => {
            const results = res.data.results
            setPowerGenerationSum(results)
        })
    }
    // 历史年度发电
    const getHistoryYearsPowerGenerationSumFun = () => {
        getHistoryYearsPowerGenerationSum().then(res => {
            const results = res.data.results
            setHistoryYearsPowerGenerationSum(results)
        })
    }
    // // 获取生态流量
    const getEcoFlowFun = () => {
        getEcoFlow().then(res => {
            const results = res.data.results
            setEcoFlow(results)
        })
    }
    // 获取发电机组数据
    const getPowerDetailsFun = () => {
        getPowerDetails().then(res => {
            const results = res.data.results
            setPowerDetails(results)
        })
    }
    // huoqu 
    const getRainInfoFun = () => {
        getRainInfo({ ID: configDataReal.config.rsvrCode }).then((res) => {
            setRainInfoData(res.data.realtime);
        })
    }
    const getWaterHeadFun = () => {
        getWaterHead().then((res) => {
            const results = res.data.results
            setWaterHead(results)
        })
    }
    const getSwitchFun = () => {
        getSwitch().then((res2) => {
            // 获取配置
            const response_data = res2.data.results;
            if (!_.isEmpty(response_data)) {
                setZmStatus(response_data.zmStatus)
                if (response_data.switchArr) {
                    showTypeFun(response_data);
                    if (showTypeArr.length) {
                        setShowType(showTypeArr[0]);
                    }
                }
                setTitType(response_data.title);
                if (showTypeArr.length > 1 && response_data.interval) {
                    setIntervalObj = setInterval(() => {
                        showTypeIndex =
                            showTypeIndex < showTypeArr.length - 1 ? showTypeIndex + 1 : 0;
                        setShowType(showTypeArr[showTypeIndex]);
                    }, response_data.interval * 1000);
                }
            }
        });
    }

    const initFun = async () => {
        setLoading(true)
        await getSwitchFun()
        await getRainInfoFun()
        await getWaterHeadFun()
        await getPowerGenerationInfoFun()
        await getPowerGenerationSumFun()
        await getHistoryYearsPowerGenerationSumFun()
        await getEcoFlowFun()
        await getPowerDetailsFun()
        setLoading(false)
        setTimeout(() => {
            initFun()
        }, intervalTime)
    }
    /**
     * 组合渲染
     */
    const showTypeFun = (data) => {
        showTypeArr = [...JSON.parse(data.switchArr)]
        if (!!data.config) {
            const config = JSON.parse(data.config)
            const arr = config.list.filter(item => config.checkList.includes(item.id))
            showTypeArr = [1,...arr, ...showTypeArr]
        }
    }
    useEffect(() => {
        const queryArr = GetRequest();
        if (!(queryArr && queryArr.channelid)) {
            message.warning("通讯连接失败,地址栏请填写通道channelid");
            return;
        }
        let websocketHeartbeatJs = new WebsocketHeartbeatJs({
            url: `wss://${CONFIG.SOCKETHOST}/websocket/${queryArr.channelid}`,
            reconnectTimeout: 2000,
            pongTimeout: 10000,
        });
        websocketHeartbeatJs.onopen = function () {
            setWebscocketStatusType(true);
            // message.success({ content: "远程链接连成功", key, duration: 2 });
        };
        websocketHeartbeatJs.onmessage = function (e) {
            if (e.data && e.data.indexOf("Channel controll connected") != -1) {
                return;
            }
            if (e.data && JSON.parse(e.data).message) {
                // 防止message丢失报错
                const res2 = JSON.parse(e.data).message;
                if (typeof res2 === "string") {
                    return;
                }
                if (res2.showVideo) {
                    // 视频播放指令
                    setShowImage(false);
                    setIsModalVisible(res2.videoShowType);
                    setShowVideoConfig({
                        source: res2.videoSource,
                    });
                } else if (res2.showImage) {
                    // 图片指令
                    setIsModalVisible(false);
                    setShowImage(res2.imageShowType);
                    setShowImageConfig({
                        source: res2.imageSource,
                    });
                } else if (res2.showSetting) {
                    // 水质变化
                    const set = _.cloneDeep(settingDataReal);
                    set[`setting`][`waterLevel`] = res2.waterLevel;
                    set[`setting`][`waterQuality`] = res2.waterQuality;
                    setSettingDataReal(set);
                } else {
                    // 大屏界面设置指令
                    try {
                        window.clearTimeout(setIntervalObj); //取消定时器
                        showTypeIndex = 0;
                        showTypeFun(res2);
                        if (showTypeArr.length) {
                            setShowType(showTypeArr[0]);
                        } else {
                            setShowType([])
                        }
                        setZmStatus(res2.zmStatus); // 切换闸门
                        if (showTypeArr.length > 1 && res2.interval) {
                            setIntervalObj = setInterval(() => {
                                showTypeIndex =
                                    showTypeIndex < showTypeArr.length - 1 ? showTypeIndex + 1 : 0;
                                setShowType(showTypeArr[showTypeIndex]);
                            }, res2.interval * 1000);
                        } else {
                            window.clearTimeout(setIntervalObj); //取消定时器
                        }
                    } catch (err) {
                        console.log(err)
                    }

                }
            }
        };
        websocketHeartbeatJs.onreconnect = function () {
            setWebscocketStatusType(false);
            // message.loading({ content: "远程链接连接中", key });
        };
        initFun()
    }, [])
    return <Spin spinning={loading} wrapperClassName="QcPowerStation">
        {!loading && <div className="QcPowerStation-wrap">
            <div className="QcPowerStation-wrap_left flex flex-colum">
                <div className="qc-left_hd flex">
                    <PowerInfo loading={loading} waterHead={waterHead} />
                    <DayNum powerGenerationInfo={powerGenerationInfo} loading={loading} />
                </div>
                <div className="qc-left_bd flex">
                    <Daba powerDetails={powerDetails} ecoFlow={ecoFlow} rainInfoReal={rainInfoData} zmStatus={zmStatus} />
                </div>
            </div>
            <div className="QcPowerStation-wrap_right">
                <Onduty />
                <PowerAmount powerGenerationSum={powerGenerationSum} />
                <PowerHistory historyYearsPowerGenerationSum={historyYearsPowerGenerationSum} powerGenerationSum={powerGenerationSum} />
            </div>
        </div>}
        {/* 欢迎界面 */}
        <div className={`map-f m-lr-auto  huanying-child-wrap ${showType === 3 ? "show_map" : "hidden_map"}`}></div>
        {/* 用户图片 */}
        <div className={`huanying-child-wrap-img ${![1, 2, 3].includes(showType) && typeof showType === 'string' ? "" : "hidden"}`}>
            <div className="huanying-child-wrap_new-img" style={{
                backgroundImage: `${typeof showType === 'string' ? `url(${encodeURI(showType)})` : ''}`
            }}>
            </div>
        </div>
        {/* 用户图片加背景墙 */}
        <div className={`huanying-child-wrap-img ${![1, 2, 3].includes(showType) && !_.isNull(showType) && typeof showType !== 'string' ? "" : "hidden"}`}>
            {
                !_.isNull(showType) && (typeof showType === 'string' ? <img src={typeof showType === 'string' ? showType : ''} /> : <div className="slider-bg flex flex-a-c flex-j-c" style={{
                    backgroundImage: `${!!showType.bgFile ? `url(${encodeURI(showType.bgFile[0].url)})` : ''}`
                }}>
                    {showType.checked == 1 ? <div className="slider-list-wrap flex flex-a-c flex-j-c" style={{ backgroundImage: `${showType.imgFile.length ? `url(${encodeURI(showType.imgFile[0].url)})` : ''}` }}>
                    </div> : <div className="slider-list-tit">{showType.text}</div>}
                </div>)
            }
        </div>
    </Spin>
}
export default QcPowerStation