import React, { useEffect, useState } from "react";
import Tit from "@/components/tit";
import { settingData } from "@/store/raininfo";
import { useRecoilValue } from "recoil";
// import moment from "moment";
import axios from "axios";
import "./index.less";
function Weather(params) {
    const settingDataReal = useRecoilValue(settingData); // 大屏数据配置信息
    const [list, setList] = useState([]);//未来三日降雨量
    const [weatherData, setWeatherData] = useState({}) // 天气数据
    /**
     * 未来几天
     */
    const getRainForecastFun = () => {
        // getRainForecast().then(res => {
        //     const data = res.data.results;
        //     setList(data)
        // })
        const cityWeatherNumber = settingDataReal.setting.cityWeather.replace(/CN/g, '')
        axios.get(`/weather/${cityWeatherNumber}`).then(res => {
            if(res.data.code=='200'){
                setWeatherData(res.data.now)
            }
        })
    }
    const allFun = async () => {
        await getRainForecastFun()
        // setTimeout(() => {
        //     allFun()
        // }, 300000); //   5分钟接口单独请求一次
    }
    useEffect(() => {
        allFun();
    }, [settingDataReal]);
    return (
        <div className="weather-wrap-min  m-t-16 m-b-15">
            <Tit tit="天气预报" size="min"></Tit>
            <div className="m-t-3 m-b-6">
                <div className="l-page-city-weather__current">
                    <div className="c-city-weather-current ">
                        <div className="current-weather__bg">
                           {/* <p class="current-time">{moment(weatherData.obsTime).format('YYYY-MM-DD  HH:mm')}</p> */}
                           <div className="flex flex-a-c">
                           <div className="current-live flex flex-j-c">
                                <div className="icon fz-54 flex flex-a-c m-r-20">
                                    <i className={`qi-${weatherData.icon}`}></i>
                                </div>
                                <div className="current-live__item">
                                    <p>{weatherData.temp}°</p>
                                    <p>{weatherData.text}</p>
                                </div>
                            </div>
                            {/* <div className="current-abstract">
                                今天白天晴，夜晚多云，温度和昨天差不多，现在23°，空气一般。
                            </div> */}
                            <div className="current-basic d-flex justify-content-between align-items-center">
                                <div className="current-basic___item">
                                    <p>
                                        {weatherData.windDir}级
                                    </p>
                                    <p>风向</p>
                                </div>
                                <div className="current-basic___item">
                                    <p>
                                        {weatherData.windSpeed}km/h
                                    </p>
                                    <p>风速</p>
                                </div>
                                <div className="current-basic___item">
                                    <p>{weatherData.humidity}%</p>
                                    <p>相对湿度</p>
                                </div>
                                <div className="current-basic___item">
                                    <p>{weatherData.pressure}</p>
                                    <p>大气压强</p>
                                </div>

                                {/* <div className="current-basic___item hidden-740">
                                    <p>{weatherData.feelsLike}°</p>
                                    <p>体感温度</p>
                                </div> */}
                                {/* <div className="current-basic___item hidden-740">
                                    <p>{weatherData.vis}km</p>
                                    <p>能见度</p>
                                </div> */}
                                {/* <div className="current-basic___item hidden-740">
                                    <p>{weatherData.precip}mm</p>
                                    <p>累计降水量(h)</p>
                                </div> */}
                            </div>
                           </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Weather;
