import React, { useEffect, useState } from "react";
import Tit from "@/components/tit";
import "./index.less";
function PoweInfo(props) {
    return <div className="qc-PoweInfo-wrap">
        <Tit tit="发电水头"></Tit>
        <div className="info-list flex flex-a-c flex-colum">
            <div className="item-info flex flex-a-c flex-j-c">
                <div className="item-label">当前水头</div>
                <div className="item-num"><i>{props.waterHead.now}</i><span className="unit m-l-5">m</span></div>
            </div>
            <div className="item-info flex flex-a-c flex-j-c">
                <div className="item-label">最大水头</div>
                <div className="item-num"><i>{props.waterHead.max}</i><span className="unit m-l-5">m</span></div>
            </div>
            <div className="item-info flex flex-a-c flex-j-c">
                <div className="item-label">最小水头</div>
                <div className="item-num"><i>{props.waterHead.min}</i><span className="unit m-l-5">m</span></div>
            </div>
        </div>
    </div>
}
export default PoweInfo