import request from "../utils/request";
import qs from "qs";
import CONFIG from "@/config";


// 库中各测点水位信息
export async function getWaterlevels(data) {
    return request(`/tx/waterlevels`, {
        method: "get",
    });
}

// 雨量信息
export async function getDayRainInfo(data) {
    return request(`/tx/rains`, {
        method: "get",
    });
}

// 供水查询，流量--config配置(json的key)里面获取
export async function getFlowByName(data) {
    return request(`/bs/getFlowByName`, {
        method: "get",
        params: data
    });
}


// 发电机信息
export async function getPowerGenerator(data) {
    return request(`/tx/powerGenerator`, {
        method: "get",
        params: data
    });
}

// 发电量统计
export async function getGeneratedEnergy() {
    return request(`/tx/generatedEnergy`, {
        method: "get",
    });
}


// 生态流量
export async function getEcoFlow() {
    return request(`/tx/ecoFlow`, {
        method: "get",
    });
}


// 溢洪道信息
export async function getSpillway() {
    return request(`/tx/spillway`, {
        method: "get",
    });
}


// 泄洪洞放空洞信息
export async function getSpillwayTunnel() {
    return request(`/tx/spillwayTunnel`, {
        method: "get",
    });
}


// 下泄信息，单位是万m³
export async function getDownstreamFlow() {
    return request(`/tx/downstreamFlow`, {
        method: "get",
    });
}