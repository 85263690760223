import Tit from "@/components/tit";
import React from "react";
import { useRecoilValue } from "recoil";
import { settingData } from "@/store/raininfo";
import { convert } from "@/utils/common";
import "./index.less";
function Emergency(props) {
    const settingDataReal = useRecoilValue(settingData);
    return (
        <div className="flex flex-j-b">
            {/* {props.WaterCategory} */}
            <div className="emergency-wrap w100 emergency-wrap-qc">
                <Tit tit="应急响应等级"></Tit>
                <div className="emergency-main-wrap">
                    {settingDataReal.setting.waterLevel > 0 ? (
                        <div className="turntable-wrap">
                            <div
                                className={`turntable-desc flex flex-a-c ic-skyj-${settingDataReal.setting.waterLevel} flex-j-c`}
                            >
                                <div
                                    className={`turntable-desc-img bg-${settingDataReal.setting.waterLevel}`}
                                ></div>
                                <div
                                    className={`turntable-desc-tit c-${settingDataReal.setting.waterLevel} fz-30 m-l-7`}
                                >
                                    {convert(settingDataReal.setting.waterLevel)}级
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="turntable-wrap">
                            <div className="none">无应急响应</div>
                        </div>
                    )}
                </div>
                <div className="six"></div>
            </div>
        </div>
    );
}

export default Emergency;
