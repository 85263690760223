import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { showMenuVisible, isLogin } from "@/store/raininfo";
import { uploadSwitch, getSwitch } from "@/api/rain";
import {
  Button,
  Input,
  Form,
  message,
  Radio,
  Checkbox,
  InputNumber,
} from "antd";
import { mapModel } from "@/store/raininfo";
const interFaceOptions = [
  // 界面参数
  {
    label: "大坝",
    value: 1,
  },
  {
    label: "地图",
    value: 2,
  },
  {
    label: "欢迎",
    value: 3,
  },
];
function Slider(props) {
  // const [showTypeArr, setShowTypeArr] = useState([]); // 2.显示地图 1.显示大坝
  const [silderType, setSilderType] = useState(); // 轮播状态
  const onChangeMap = (checkedValues) => {
    // console.log(checkedValues);
    // 切换地图
    // setShowType(showType == 1 ? 2 : 1);
  };
  const onChangeRadio = (e) => {
    setSilderType(e.target.value);
  };
  const [form] = Form.useForm();
  const onFormLayoutChange = (values) => {
    if (!values.switchArr.length) {
      return message.error("界面设置至少选择1个界面");
    }
    values[`switchNo`] = 1; // 保证线上不出错
    values[`switchArr`] = JSON.stringify(values[`switchArr`]);
    if (silderType == "a") {
      values[`interval`] = 0;
    }
    uploadSwitch(values).then((res) => {
      if (res.data.code == 200) {
        message.success("设置成功");
      } else {
        message.error("设置失败");
      }
    });
  };
  useEffect(() => {
    getSwitch().then((res) => {
      const switchArr = JSON.parse(res.data.switchArr);
      setSilderType(res.data.interval ? "b" : "a");
      // setShowTypeArr(switchArr);
      form.setFieldsValue({ switchArr: switchArr }); // 模式
      form.setFieldsValue({ interval: res.data.interval }); // 间隔
      form.setFieldsValue({ title: res.data.title }); // 头部
    });
  }, []);

  return (
    <Form form={form} onFinish={onFormLayoutChange}>
      <Form.Item label="界面风格" name="title">
        <Radio.Group>
          <Radio.Button value={1}>常规风格</Radio.Button>
          <Radio.Button value={2}>党建风格</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="界面设置(可多选)" name="switchArr">
        <Checkbox.Group
          options={interFaceOptions}
          onChange={onChangeMap}
          // values={showTypeArr}
        ></Checkbox.Group>
      </Form.Item>
      <Form.Item label="设置轮播">
        <Radio.Group onChange={onChangeRadio} value={silderType}>
          <Radio.Button value="a">禁止轮播</Radio.Button>
          <Radio.Button value="b">自动播放</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="轮播时长(秒)" name="interval">
        {silderType == "b" ? (
          <InputNumber placeholder="请输入轮播时长" />
        ) : (
          <InputNumber placeholder="请输入轮播时长" disabled />
        )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  );
}
export default Slider;
