import Tit from "@/components/tit";
import React, { useEffect, useState, useRef } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  showMenuVisible,
  isLogin,
  showVideo,
  videoConfig,
  showImage,
  imageConfig,
} from "@/store/raininfo";
import { getRainForecast } from "@/api/rain";
import md5 from "js-md5";
import Cookies from "js-cookie";
import path from "path";
import {
  setLogin,
  uploadDuty,
  getFileList,
  updateInfo,
  uploadFileLin,
  deleteFile,
} from "@/api/rain";
import {
  Drawer,
  Button,
  Collapse,
  Input,
  Form,
  List,
  Select,
  message,
  Radio,
  Popconfirm,
} from "antd";
import { warnLevel, waterQuality } from "./common";
import "./index.less";
import SliderSeting from "./slider";
const { Panel } = Collapse;

function Menu() {
  const [visible, setVisible] = useRecoilState(showMenuVisible); // 控制显示隐藏
  const [isLoginState, setIsLoginState] = useRecoilState(isLogin); // 控制显示隐藏
  const [fileRef, setFileRef] = useState();
  const [fileRef2, setFileRef2] = useState();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm(); //表单获取
  const [radioValue1, setRadioValue1] = useState(0);
  const [radioValue2, setRadioValue2] = useState(1);
  const [onUpload2Loading, setOnUpload2Loading] = useState(false); //  上传文件状态
  const setShowVideo = useSetRecoilState(showVideo); // 展示视频
  const setShowVideoConfig = useSetRecoilState(videoConfig); // 展示视频
  const setShowImage = useSetRecoilState(showImage); // 展示图片
  const setShowImageConfig = useSetRecoilState(imageConfig); // 展示图片地址
  const onClose = () => {
    setVisible(false);
  };
  const onFinish = (values) => {
    // 提交密码表单
    setLogin({
      password: md5(values.password),
    }).then((res) => {
      const data = res.data;
      if (res.data.code == 200) {
        //登录成功缓存密钥
        Cookies.set("loginMd5", data.results);
        setIsLoginState(true);
      } else {
        message.error(res.data.msg);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  /*上传文件方法 start*/
  const onChangeFile = (Event) => {
    // file文件传值
    const file = Event.target.files[0];
    setFileRef(file);
  };
  const onUpload = () => {
    // 提交表格
    const formdata = new FormData();
    formdata.append("password", Cookies.get("loginMd5"));
    formdata.append("file", fileRef);
    uploadDuty(formdata).then((res) => {
      if (res.data.code == 200) {
        message.success("上传成功");
      } else {
        message.error("上传失败");
      }
    });
  };
  /*上传文件方法 end*/
  /*更新信息*/
  const onUploadInfo = (values) => {
    console.log(values);
    // 更新密码等信息
    for (let v in values) {
      values[v] = Number(values[v]);
    }
    values.password = Cookies.get("loginMd5");
    if (values.newPassword) {
      values.newPassword = md5(values.newPassword);
    } else {
      delete values.newPassword;
    }
    const formdata = new FormData();
    for (const v in values) {
      formdata.append(v, values[v]);
    }
    updateInfo(formdata).then((res) => {
      if (res.data.code == 200) {
        message.success("更新成功");
        if (values.newPassword) {
          Cookies.set("loginMd5", values.newPassword);
        }
      } else {
        message.error("更新失败");
      }
    });
  };

  /*临时文件上传*/
  const onChangeFile2 = (Event) => {
    // 临时file文件传值
    const file = Event.target.files[0];
    setFileRef2(file);
  };
  const onUpload2 = () => {
    // 提交临时文件
    // if (fileRef2.size / (1024 * 1024) > 100) {
    //   return message.error("文件不得超过100M");
    // }
    setOnUpload2Loading(true);
    const formdata = new FormData();
    formdata.append("password", Cookies.get("loginMd5"));
    formdata.append("file", fileRef2);
    uploadFileLin(formdata).then((res) => {
      if (res.data.code == 200) {
        message.success("上传成功");
        getFileList().then((res) => {
          setFileList(res.data);
        });
      } else {
        message.error("上传失败");
      }
      setOnUpload2Loading(false);
    });
  };
  const downFile = (name) => {
    // 下载临时文件
    window.open(`http://rainwater.app.19930322.com/bs/file/${name}`, "_black");
  };
  const showFile = (name) => {
    // 在线预览
    if (name.match(/.mp4$/g)) {
      setShowImage(false);
      setShowVideo(true);
      setShowVideoConfig({
        source: `http://rainwater.app.19930322.com/bs/show/${name}`,
      });
    } else if (name.match(/.(png|jpg|JPEG|jpeg)$/g)) {
      setShowVideo(false);
      setShowImage(true);
      setShowImageConfig({
        source: `http://rainwater.app.19930322.com/bs/show/${name}`,
      });
    } else {
      window.open(
        `http://pdf.19930322.com/v1/?file=http://rainwater.app.19930322.com/bs/file/${name}`,
        "_black"
      );
    }
  };
  const onDeleteFile = (name) => {
    // 删除文件
    deleteFile({ fileName: name }).then((res) => {
      if (res.data.code == 200) {
        message.success("删除成功");
        getFileList().then((res) => {
          setFileList(res.data);
        });
      } else {
        message.error("删除失败");
      }
    });
  };
  useEffect(() => {
    getFileList().then((res) => {
      setFileList(res.data);
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({ warnLevel: 0 });
    form.setFieldsValue({ waterQuality: 1 });
  }, [`isLoginState`]);
  const genExtra = () => {
    const downFileMb = () => {
      window.open(
        `https://qcls.app.19930322.com/%E5%80%BC%E7%8F%AD%E8%A1%A8-%E6%A8%A1%E6%9D%BF.xlsx`,
        "_black"
      );
    };
    // 下载模板
    return (
      <Button onClick={downFileMb} size="small">
        下载模板
      </Button>
    );
  };

  const onGenderChange = (e) => {
    setRadioValue1(e.target.value);
    form.setFieldsValue({ warnLevel: e.target.value });
  };
  const onGenderChange2 = (e) => {
    setRadioValue1(e.target.value);
    form.setFieldsValue({ waterQuality: e.target.value });
  };
  return (
    <Drawer
      className="drawer-wrap"
      title="大屏设置"
      closable
      visible={visible}
      onClose={onClose}
    >
      {isLoginState ? (
        <Collapse>
          {/*播放设置*/}
          <Panel header="播放设置" key="0">
            <SliderSeting close={onClose}></SliderSeting>
          </Panel>
          {/*值班列表表单*/}
          <Panel header="更新值班表" key="1" extra={genExtra()}>
            <Form name="basic" onFinish={onUpload}>
              <Form.Item
                name="file"
                rules={[
                  {
                    required: true,
                    message: "请上传文件",
                  },
                ]}
              >
                <div className="file-wrap">
                  <input type="file" onChange={onChangeFile}></input>
                </div>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Form>
          </Panel>
          {/*值班列表表单End*/}
          {/*更新文件信息*/}
          <Panel header="更新大屏信息" key="2">
            <Form name="basic" onFinish={onUploadInfo} form={form} id="area">
              <Form.Item
                label="水库应急响应"
                name="warnLevel"
                rules={[
                  {
                    required: true,
                    message: "请选择水库应急响应等级",
                  },
                ]}
              >
                <Radio.Group onChange={onGenderChange} value={radioValue1}>
                  {warnLevel.map((item, index) => {
                    return (
                      <Radio key={index} value={item.value}>
                        {item.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="水质等级"
                name="waterQuality"
                rules={[
                  {
                    required: true,
                    message: "请输入水质等级",
                  },
                ]}
              >
                <Radio.Group onChange={onGenderChange2} value={radioValue2}>
                  {waterQuality.map((item, index) => {
                    return (
                      <Radio key={index} value={item.value}>
                        {item.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="修改密码"
                name="newPassword"
                rules={[
                  {
                    message: "请输入新的密码",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Form>
          </Panel>
          {/*更新文件信息End*/}
          {/*临时上传列表End*/}
          <Panel header="临时文件列表" key="3">
            <List
              bordered
              dataSource={fileList}
              locale={{ emptyText: "暂无数据" }}
              renderItem={(item, index) => (
                <List.Item>
                  <div className="flex flex-j-c flex-b">
                    <div className="m-r-10">{index + 1}.</div>
                    <div className="flex1 ellipsis">{item.name}</div>
                    <div className="m-l-10">
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                          downFile(item.name);
                        }}
                      >
                        下载
                      </Button>
                    </div>
                    <div className="m-l-10">
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                          showFile(item.name);
                        }}
                      >
                        在线预览
                      </Button>
                    </div>
                    <div className="m-l-10">
                      <Popconfirm
                        title="您确定要删除么"
                        onConfirm={() => {
                          onDeleteFile(item.name);
                        }}
                        okText="确定"
                        cancelText="取消"
                      >
                        <Button danger size="small">
                          删除
                        </Button>
                      </Popconfirm>
                    </div>
                  </div>
                </List.Item>
              )}
            />
            <Form name="basic" onFinish={onUpload2} className="m-t-20">
              <Form.Item
                name="file"
                rules={[
                  {
                    required: true,
                    message: "请上传文件",
                  },
                ]}
              >
                <div className="file-wrap">
                  <input type="file" onChange={onChangeFile2}></input>
                </div>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={onUpload2Loading}
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
          </Panel>
          {/*临时上传列表End*/}
        </Collapse>
      ) : (
        <div className="login-wrap">
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "请输入密码",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="b-t m-t-10">
              登录
            </Button>
          </Form>
        </div>
      )}
    </Drawer>
  );
}
export default Menu;
