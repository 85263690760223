import Tit from "@/components/tit";
import React, { useState, useEffect } from "react";
import "./index.less";
import { getRainForecast } from "@/api/rain";
import echarts from "echarts/lib/echarts";
import moment from "moment";
// 引入柱状图
import "echarts/lib/chart/bar";
// 引入提示框和标题组件
import "echarts/lib/component/markPoint";
import "echarts/lib/component/markLine";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import { setColor } from "@/utils/setColor";
import { SectionToChinese } from "@/utils/common";
function FwaterLevel(params) {
  const [dataLength, setDataLength] = useState("几");
  useEffect(() => {
    getRainForecast()
      .then((res) => {
        const data = res.data.results;
        const tms2 = [],
          minMaxRain = [];
        data.map((element) => {
          tms2.push(element.date);
          minMaxRain.push(element.max);
        });
        setDataLength(data.length ? SectionToChinese(data.length) : "几");
        const maxLabeY =
          Math.max(...minMaxRain) < 5 ? 5 : Math.max(...minMaxRain) + 5;
        minMaxRain.map((item, index) => {
          // 标记柱形最大值
          if (item == Math.max(...minMaxRain)) {
            minMaxRain[index] = {
              value: Number(item) ? Number(item).toFixed(1) : 0,
            };
            minMaxRain[index][`label`] = {
              show: true, //开启显示
              position: "top", //在上方显示
              textStyle: {
                //数值样式
                color: setColor(item).borderColor,
                fontSize: 12,
                fontWeight: 400,
              },
            };
          }
        });
        const myfChart = echarts.init(document.getElementById("myfChart"));
        myfChart.setOption({
          title: { text: "" },
          grid: {
            left: "7%",
            top: 30,
            bottom: "17%",
            right: "5%",
          },
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              return (
                "时间：" +
                moment(params[0].name).format("MM月DD日") +
                "<br>降雨：" +
                (isNaN(params[0].value)
                  ? "-"
                  : parseFloat(params[0].value).toFixed(1) + "mm")
              );
            },
          },

          xAxis: {
            nameTextStyle: {
              color: "#6FD7E8",
              fontSize: 12,
              align: "middle",
              verticalAlign: "bottom",
              padding: [0, 0, 10, 0],
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#E8E9F1",
              },
            },
            data: tms2,
            position: "bottom",
            boundaryGap: ["20%", "20%"], //设置为true代表离零刻度间隔一段距离
            axisLabel: {
              textStyle: {
                color: "#68A8CC",
                fontSize: 12,
                margin: 10,
              },

              formatter: (val) => {
                return moment(val).format("MM-DD");
              },
              alignWithLabel: true,
            },
            splitLine: {
              show: false,
            },
          },
          yAxis: {
            name: "mm",
            nameLocation: "end",
            nameTextStyle: {
              color: "#68A8CC",
              fontSize: 12,
              align: "middle",
              verticalAlign: "bottom",
              padding: [0, 30, 0, 0],
            },
            axisTick: {
              show: false,
            },
            type: "value",
            inverse: false,
            min: 0,
            max: maxLabeY,
            axisLabel: {
              rotate: 0,
              formatter: function (value, index) {
                return value.toFixed(1);
              },
              textStyle: {
                color: "#6FD7E8", //更改坐标轴文字颜色
                fontSize: 10, //更改坐标轴文字大小
              },
            },
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#E8E9F1",
              },
            },
          },
          series: [
            {
              barWidth: 20, //柱图宽度
              barGap: 30, //柱图间距
              name: "销量",
              type: "bar",
              data: minMaxRain,
              color: "#6AA9FC",
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1, //渐变色在下面修改，这里是透明度
                    [
                      {
                        offset: 0,
                        color: "#67C7E8",
                      },
                      {
                        offset: 1,
                        color: "#051934",
                      },
                    ]
                  ),
                },
              },
            },
          ],
        });

        window.addEventListener("resize", () => {
          myfChart.resize();
        });
      })
      .catch((err) => {
        console.log("fwaterlevel=>err", err);
      });
  }, []);
  return (
    <div className="fwaterlevel-wrap m-t-16">
      <Tit tit={`未来${dataLength}日降雨预测`}></Tit>
      <div className="fwaterlevel-wrap m-t-25 m-b-4">
        <div className="fwaterlevel-main-wrap" id="myfChart"></div>
      </div>
    </div>
  );
}

export default FwaterLevel;
