export function setColor(val) {
  if (val >= 250) {
    //大暴雨
    return {
      borderColor: "#AB024D",
      fillColor:
        "linear-gradient(to top, rgba(171, 2, 77, 1), rgba(171, 2, 77, 0.1))",
      txtFillColor: "linear-gradient(0deg, #FF1A80, #A30049)",
    };
  } else if (100 < val && val < 250) {
    // 大暴雨
    return {
      borderColor: "#D318E6",
      fillColor:
        "linear-gradient(to top, rgba(211, 24, 230, 1), rgba(211, 24, 230, 0.1))",
      txtFillColor: "linear-gradient(0deg, #D318E6, #91069F)",
    };
  } else if (50 < val && val < 100) {
    // 暴雨
    return {
      borderColor: "#2C36FD",
      fillColor:
        "linear-gradient(to top, rgba(44, 54, 253, 1), rgba(44, 54, 253,0.1))",
      txtFillColor: "linear-gradient(0deg, #313CFB, #0A14BC)",
    };
  } else if (25 < val && val <= 50) {
    // 大雨
    return {
      borderColor: "#248CF2",
      fillColor:
        "linear-gradient(to top, rgba(69, 205, 255, 1), rgba(69, 205, 255, 0.1))",
      txtFillColor: "linear-gradient(0deg, #45CDFF, #278DF2)",
    };
  } else if (10 < val && val <= 25) {
    // 中雨
    return {
      borderColor: "#10CB94",
      fillColor:
        "linear-gradient(to top, rgba(16, 203, 148, 1), rgba(16, 203, 148, 0.1))",
      txtFillColor: "linear-gradient(0deg, #1CF8B7, #019166)",
    };
  } else if (0 < val && val <= 10) {
    // 小雨
    return {
      borderColor: "#6BD636",
      fillColor:
        "linear-gradient(to top, rgba(107, 214, 54, 1), rgba(107, 214, 54, 0.1))",
      txtFillColor: "linear-gradient(0deg, #82FE4A, #42B011)",
    };
  } else {
    return {
      borderColor: "#ffdda8",
      fillColor:
        "linear-gradient(to top, rgba(107, 214, 54, 1), rgba(107, 214, 54, 0.1))",
      txtFillColor: "linear-gradient(0deg, #82FE4A, #42B011)",
    };
  }
}

// 水位色值
export function setColorSw(val) {
  if (val >= 105.1) {
    return {
      borderColor: "rgb(142,65,73)",
      labColor: "#fff",
    };
  } else if (102.88 <= val && val < 105.1) {
    return {
      borderColor: "rgb(146,98,70)",
      labColor: "#fff",
    };
  } else if (98 <= val && val < 102.88) {
    return {
      borderColor: "rgb(159,156,73)",
      labColor: "#fff",
    };
  } else if (96 < val && val <= 98) {
    return {
      borderColor: "rgb(50,105,158)",
      labColor: "#fff",
    };
  } else {
    return {
      borderColor: "#EDDC1A",
      labColor: "#c7254e",
    };
  }
}

// 水位色值
export function setColorSw2(val) {
  if (val >= 184.06) {
    return {
      borderColor: "rgb(142,65,73)",
      labColor: "#fff",
    };
  } else if (181.34 <= val && val < 184.06) {
    return {
      borderColor: "rgb(146,98,70)",
      labColor: "#fff",
    };
  } else if (176.04 < val && val < 181.34) {
    return {
      borderColor: "rgb(159,156,73)",
      labColor: "#fff",
    };
  } else {
    return {
      borderColor: "#EDDC1A",
      labColor: "#c7254e",
    };
  }
}


// 南江水位色值
export function setColorSw4(val) {
  if (val >= 204.24) {
    return {
      borderColor: "rgb(142,65,73)",
      labColor: "#fff",
    };
  } else if (200.24 <= val && val < 204.24) {
    return {
      borderColor: "rgb(146,98,70)",
      labColor: "#fff",
    };
  } else if (192.85 <= val && val < 200.24) {
    return {
      borderColor: "rgb(159,156,73)",
      labColor: "#fff",
    };
  } else if (105.17 < val && val < 109.67) {
    return {
      borderColor: "rgb(50,105,158)",
      labColor: "#fff",
    };
  } else {
    return {
      borderColor: "#EDDC1A",
      labColor: "#c7254e",
    };
  }
}



// 水位色值
export function setColorSw3(val) {
  if (val >= 117.77) {
    return {
      borderColor: "rgb(142,65,73)",
      labColor: "#fff",
    };
  } else if (110.97 <= val && val < 117.77) {
    return {
      borderColor: "rgb(146,98,70)",
      labColor: "#fff",
    };
  } else if (109.67 <= val && val < 110.97) {
    return {
      borderColor: "rgb(159,156,73)",
      labColor: "#fff",
    };
  } else if (105.17 < val && val < 109.67) {
    return {
      borderColor: "rgb(50,105,158)",
      labColor: "#fff",
    };
  } else {
    return {
      borderColor: "#EDDC1A",
      labColor: "#c7254e",
    };
  }
}



// 钦寸
export function setColorQc(val) {
  // if (val >= 98) {
  //   return {
  //     borderColor: "rgb(142,65,73)",
  //     labColor: "#fff",
  //   };
  // } else if (102.88 <= val && val < 105.1) {
  //   return {
  //     borderColor: "rgb(146,98,70)",
  //     labColor: "#fff",
  //   };
  // } else if (98 <= val && val < 102.88) {
  //   return {
  //     borderColor: "rgb(159,156,73)",
  //     labColor: "#fff",
  //   };
  // } else if (96 < val && val <= 98) {
  //   return {
  //     borderColor: "rgb(50,105,158)",
  //     labColor: "#fff",
  //   };
  // } else {
  return {
    borderColor: "#6AC0A1",
    labelColor: "#0E2441",
  };
  // }
}