import React, { useState, useEffect } from "react";
import { getXxeco, getXxSpillway, getSpillwayTunnel } from "@/api/rain";
import { keepTwoDecimalFull } from "@/utils/common";
import Tit from "@/components/tit";
import "./index.less";
const _ = require("lodash");
function FloodWater(props) {
  const [settingDataReal, setSettingDataReal] = useState({
    open: 3,
    liuliang: 0,
  });
  const [settingDataReal2, setSettingDataReal2] = useState({
    open: 3,
    liuliang: 0,
  });
  const [zmList, setZmList] = useState([{
    liuliang: 0,
    open: 0
  }, {
    liuliang: 0,
    open: 0
  }, {
    liuliang: 0,
    open: 0
  }])
  const [amount, setAmount] = useState(0);
  /**
   * 调流阀 
   */
  const getXxskFun = () => {
    getXxeco()
      .then((res) => {
        const settingDataRealCopy = _.cloneDeep(settingDataReal);
        if (res.data.results) {
          res.data.results.map((item, index) => {
            if (item.name.indexOf("流量") != -1) {
              settingDataRealCopy[`liuliang`] = Number(item.value);
              settingDataRealCopy[`open`] = item.value == 0 ? 1 : 3;
              settingDataRealCopy[`liuliang`] += amount
            }
          });
          setSettingDataReal(settingDataRealCopy);
        }
        getXxSpillwayFun(amount)
        getSpillwayTunnelFun()
        setTimeout(() => {
          getXxskFun()
        }, 300000)
      })
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
          getXxskFun()
        }, 300000)
      });
  };
  /**
  * 泄洪洞 
  */
  const getSpillwayTunnelFun = () => {
    getSpillwayTunnel().then(res => {
      if (res.data.results) {
        setSettingDataReal2(res.data.results.flow)
      }
    })
  }
  /**
   * 调流阀 
   */
  const getXxSpillwayFun = (amount) => {
    getXxSpillway(amount).then(res => {
      if (!!res.data.results) {
        res.data.results.map((item, index) => {
          zmList[item.gateNo - 1] = {
            liuliang: keepTwoDecimalFull(item.flow || 0, 1),
            open: keepTwoDecimalFull(item.open || 0, 2)
          }
          console.log(typeof item.flow)
          if (item.flow > 0) {
            amount = Number(item.flow) + amount
          }
        })
        setAmount(keepTwoDecimalFull(amount, 2))
        setZmList(zmList)
      }
    })
  }
  useEffect(() => {
    getXxskFun();
  }, []);
  return (
    <div className="xx-floodWater-wrap m-t-10">
      <Tit tit="溢洪道流量">
        <div className="tit">
          <span>
            <i>{amount}</i>
          </span>
          <span className="unit m-l-5">m³/s</span>
        </div>
      </Tit>
      <div className="xx-floodWater-wrap-main m-t-10">
        <div className="xx-floodWater-wrap-main-ll">
          <ul className="flex flex-j-b">
            {zmList.map((item, index) => {
              return <li key={index}>
                <dt>{index + 1}#弧门</dt>
                <dd>
                  <span>
                    <i>{item.liuliang}</i>
                  </span>
                  <span className="unit">m³/s</span>
                </dd>
                <dd>
                  <span>
                    <i>{item.open}</i>
                  </span>
                  <span className="unit">m</span>
                </dd>
              </li>
            })}
          </ul>
        </div>
        <div className="flex flex-j-b flex-a-c">
          <div className="xx-floodWater-wrap-main-rr">
            <div className="xx-floodWater-wrap-main-rr-c">
              <dt style={{ color: '#8DE3F8' }}>泄洪洞</dt>
              <dd>
                <span>
                  <i>
                    {settingDataReal2
                      ? keepTwoDecimalFull(settingDataReal2, 1)
                      : 0}
                  </i>
                </span>
                <span className="unit">m³/s</span>
              </dd>
              {/* <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd> */}
            </div>
          </div>
          <div className="xx-floodWater-wrap-main-rr">
            <div className="xx-floodWater-wrap-main-rr-c">
              <dt>调流阀</dt>
              <dd>
                <span>
                  <i>
                    {settingDataReal && settingDataReal.open == 3
                      ? Number(settingDataReal.liuliang).toFixed(2)
                      : 0}
                  </i>
                </span>
                <span className="unit">m³/s</span>
              </dd>
              {/* <dd>
              <span>
                <i>0</i>
              </span>
              <span className="unit">m</span>
            </dd> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FloodWater;
