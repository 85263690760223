import React from "react";
import "./index.less";
// 加载页面需要加载图片也加载完成
function LoadedImg() {
  return (
    <>
      <div className="preloader"></div>
      <div className="premaploader"></div>
      <div className="prehuangyinloader"></div>
    </>
  );
}
export default LoadedImg;
