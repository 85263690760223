import Tit from "@/components/tit";
import React, { useState, useEffect } from "react";
import "./index.less";
import { getDtu } from "@/api/rain";
import { set } from "js-cookie";
const _ = require("lodash");
const generatorDetails = {
  deviceTotalFlow: 0,
  generatorDetails: [
    {
      dataNo: "000001561109",
      dataTime: "2021-03-09T10:22:23",
      deviceTag: "1#发电机组",
      flowValue: 0,
      power: 0,
      seqNo: 1,
    },
    {
      dataNo: "000001561109",
      dataTime: "2021-03-09T10:22:23",
      deviceTag: "1#发电机组",
      flowValue: 0,
      power: 0,
      seqNo: 2,
    },
    {
      dataNo: "000001561109",
      dataTime: "2021-03-09T10:22:23",
      deviceTag: "1#发电机组",
      flowValue: 0,
      power: 0,
      seqNo: 3,
    },
    {
      dataNo: "000001561109",
      dataTime: "2021-03-09T10:22:23",
      deviceTag: "1#发电机组",
      flowValue: 0,
      power: 0,
      seqNo: 4,
    },
  ],
};
function Power(props) {
  const [liuliang, setLiuliang] = useState({}); // 流量
  const [deviceDetails, setDeviceDetails] = useState(generatorDetails); // 发电机组
  useEffect(() => {
    getDtuFun();
  }, []);
  const getDtuFun = () => {
    // getDtu().then((res) => {
    //   // 获取流量
    //   if (res.data.code == 0) {
    //     if (res.data && res.data.status != 500) {
    //       const data = res.data.results.deviceDetails;
    //       try {
    //         data.generatorDetails = _.sortBy(
    //           data.generatorDetails,
    //           function (item) {
    //             return item.seqNo; //根据code对数据进行升序排序
    //           }
    //         );
    //         data.ecoLDetails.map((item, index) => {
    //           if (item.fqp == 0) {
    //             // 闭合
    //             item[`type`] = 1;
    //           } else if (item.fqp < 80) {
    //             // 半开
    //             item[`type`] = 2;
    //           } else {
    //             item[`type`] = 3;
    //           }
    //         });
    //         data.ecoLDetails = _.sortBy(data.ecoLDetails, function (item) {
    //           return item.seqNo; //根据code对数据进行升序排序
    //         });
    //         res.data.results.powerGenerationDaily = String(
    //           (res.data.results.powerGenerationDaily / 10000).toFixed(2)
    //         ).split(".");
    //         setDeviceDetails(data);
    //         setLiuliang(res.data.results);
    //       } catch (err) {
    //         console.log(err);
    //       }
    //     }
    //   }
    // });
  };
  return (
    <div className="power-wrap">
      <Tit tit="发电机组">
        <div className="tit">
          {deviceDetails.deviceTotalFlow ? (
            <span>
              <i>{(deviceDetails.deviceTotalFlow / 3600).toFixed(2)}</i>
            </span>
          ) : (
            <span>
              <i>0</i>
            </span>
          )}
          <span className="unit m-l-5">m³/s</span>
        </div>
      </Tit>
      <div className="power-main-wrap">
        <ul className="fenche after m-l-19">
          {deviceDetails.generatorDetails &&
            deviceDetails.generatorDetails.map((item, index) => {
              return (
                <li className="fenche-li" key={index}>
                  <div className="fenche-li-hd fz-12 theme-bg-color">
                    {item.power ? item.power.toFixed(1) : 0}
                    <span className="m-l-3">Kw</span>
                  </div>
                  <div
                    className={`fenche-li-bd ${item.flowValue ? "active" : ""}`}
                  >
                    <div className={`op-6 ${item.flowValue ? "active" : ""}`}>
                      <span className="fenche-ani"></span>
                      <span className="fenche-desc fz-14">
                        {item.seqNo}号机组
                      </span>
                    </div>
                    {item.flowValue ? "" : <span className="top-icon"></span>}
                  </div>
                  <div className="fenche-li-ft fz-12 theme-bg-color">
                    {item.flowValue ? (item.flowValue / 3600).toFixed(2) : 0}
                    <span className="m-l-3">m³/s</span>
                  </div>
                </li>
              );
            })}
        </ul>
        <div className="fenche-info-wrap flex flex-j-b m-t-5">
          <div className="fenche-info-main-wrap flex flex-j-b">
            <dl>
              <dt>当日发电量</dt>
              <dd>
                {liuliang.powerGenerationDaily &&
                typeof liuliang.powerGenerationDaily == "object" ? (
                  <span>
                    <i>
                      {liuliang.powerGenerationDaily[0]}.
                      {liuliang.powerGenerationDaily[1]}
                    </i>
                  </span>
                ) : (
                  <span>
                    <i>0</i>
                  </span>
                )}
                <span className="unit">万kW·h</span>
              </dd>
            </dl>
            <dl>
              <dt>当月发电量</dt>
              <dd>
                {liuliang.powerGenerationMonthly ? (
                  <span>
                    <i>
                      {(liuliang.powerGenerationMonthly / 10000).toFixed(2)}
                    </i>
                  </span>
                ) : (
                  <span>
                    <i>0</i>
                  </span>
                )}
                <span className="unit">万kW·h</span>
              </dd>
            </dl>
            <dl>
              <dt>全年累计发电量</dt>
              <dd>
                {liuliang.powerGenerationYearly ? (
                  <span>
                    <i>{(liuliang.powerGenerationYearly / 10000).toFixed(2)}</i>
                  </span>
                ) : (
                  <span>
                    <i>0</i>
                  </span>
                )}
                <span className="unit">万kW·h</span>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Power;
